import Bowser from "bowser";

export default {
  setBowser: ({ commit }) => {
    var _bowser = Bowser.getParser(window.navigator.userAgent);
    commit("setBowser", _bowser);
  },
  setBowserInitial: ({ state, dispatch }) => {
    dispatch("setBowser");
    // check for approval.
    if (!state.isApprovedBrowser) {
      dispatch("toastMessage", {
        variant: "info",
        title: "Browser Alert",
        to: { name: "help" },
        noAutoHide: true,
        message:
          "Your browser is dated. For best results, please use the latest Chrome or Firefox. \
          Click for system requirements."
      });
    }
  },
  toastMessage: function({ state }, message_obj) {
    if (!state.componentInstance.$bvToast) {
      console.log("No toast available.");
      return;
    }
    if (typeof message_obj == "string") {
      // Just a simple message passed.
      state.componentInstance.$bvToast.toast(message_obj, {
        title: "Success",
        variant: "success",
        autoHideDelay: 3000,
        solid: true,
        toaster: "b-toaster-top-center"
      });
      return;
    }
    var autoHideDelayMs = 5000;
    var variant = message_obj.variant || "success";
    if (variant === "danger") {
      // Longer delay for error messages.
      autoHideDelayMs = 7000;
    }
    var toastSetup = {
      title: message_obj.title || "Alert",
      variant: variant,
      autoHideDelay: autoHideDelayMs,
      solid: true
    };
    if (message_obj.noAutoHide) {
      toastSetup.noAutoHide = true;
    }
    if (message_obj.to) {
      toastSetup.to = message_obj.to;
    }
    state.componentInstance.$bvToast.toast(message_obj.message, toastSetup);
  }
};
