<template>
  <div>
    <div id="app">
      <!-- Note, there is no keep-alive here -->
      <router-view v-if="!isMobile && isBrowserSupported" />
      <!-- Incompatible mobile cover -->
      <div v-else>
        <div class="inner">
          <FirstRunInfo />
          <h2 v-show="isMobile">
            ⚠️ A larger screen is needed for this application to function
            properly.
          </h2>
          <h2 v-show="!isBrowserSupported">
            ⚠️ This browser does not meet minimum requirements.
          </h2>
          <h3>Minimum requirements</h3>
          <p>Tablet or larger display.</p>
          <p>Chrome 71 or higher.</p>
          <p>Firefox 65 or higher.</p>
          <p>Safari 12 or higher.</p>
          <p>Microsoft Edge 84 or higher.</p>
          <p>
            If you believe you have reached this warning in error, please
            contact <b>help@tungite.com</b>.
          </p>
          <p>{{}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AppBowserMixin } from "@/AppBowserMixin";
import FirstRunInfo from "@/components/FirstRunInfo";
export default {
  name: "App",
  components: { FirstRunInfo },
  mixins: [AppBowserMixin],
  data() {
    return {};
  },
  computed: {},
  mounted() {
    // Register this for toaster.
    this.$store.commit("set_component_instance", this);
  }
};
</script>

<style lang="scss">
//@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");

.inner {
  max-width: 38rem;
  padding: 2rem;
  margin: auto;
}

.right-border {
  border-right: 1px solid #cfcfcf;
}
// Unscoped, .fa-icon adjustment for use in buttons.
.btn {
  & .fa-icon {
    position: relative;
    width: auto;
    height: 0.8em; /* or any other relative font sizes */

    /* You would have to include the following two lines to make this work in Safari */
    max-width: 100%;
    max-height: 100%;
    bottom: 0.1em;
  }
}
// Unscoped, .fa-icon adjustment for use everywhere else. Uses font size of enclosing tag.
.fa-icon {
  position: relative;
  width: auto;
  height: 1em; /* or any other relative font sizes */

  /* You would have to include the following two lines to make this work in Safari */
  max-width: 100%;
  max-height: 100%;
  bottom: 0.15em;
}

:root {
  --font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

body {
  margin: 0;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  display: block;
}

[data-theme="default"] {
  --button-color: rgb(149, 10, 155);
  --highlight-color: pink;
  --text-color: white;
  --active-button-color: rgb(89, 11, 189);
  --active-button-text-color: white;
  --ui-border-color: white;
  --grid-image-background-color: black;
  --slider-background-color: whitesmoke;
  --slider-background-hover-color: rgb(210, 210, 210);
  --grid-active-border-color: #b202ad;
  --modal-background-color: whitesmoke;
  --modal-text-color: black;
  --modal-danger-background-color: rgba(249, 229, 215, 0.92);
  --progress-bar-foreground-color: var(--active-button-color);
  --progress-bar-background-color: #999;
  --image-alert-background-color: rgb(86, 45, 108);
  --image-alert-shadow: 8px 8px 0px #222;
  --placeholder-image-background-color: #cfcfcf;
  --main-background-image: repeating-linear-gradient(
      45deg,
      rgb(255, 255, 255) 0px,
      rgb(255, 255, 255) 10px,
      transparent 10px,
      transparent 11px
    ),
    repeating-linear-gradient(
      135deg,
      rgb(255, 255, 255) 0px,
      rgb(255, 255, 255) 10px,
      transparent 10px,
      transparent 11px
    ),
    linear-gradient(90deg, hsl(256, 7%, 84%), hsl(256, 7%, 84%));
  --main-background-color: white;
  --top-background-image: linear-gradient(
    45deg,
    rgb(149, 10, 155) 0%,
    rgb(149, 10, 155) 9%,
    rgb(120, 16, 136) 9%,
    rgb(120, 16, 136) 13%,
    rgb(178, 3, 174) 13%,
    rgb(178, 3, 174) 32%,
    rgb(91, 23, 117) 32%,
    rgb(91, 23, 117) 42%,
    rgb(32, 36, 79) 42%,
    rgb(32, 36, 79) 46%,
    rgb(61, 30, 98) 46%,
    rgb(61, 30, 98) 70%,
    rgb(3, 43, 60) 70%,
    rgb(3, 43, 60) 100%
  );
  --bottom-background-image: var(--top-background-image);
}

[data-theme="dark"] {
  /* Variables for dark mode */
  --button-color: rgb(56, 69, 61);
  --highlight-color: white;
  --text-color: white;
  --modal-text-color: white;
  --ui-border-color: rgb(60, 60, 60);
  --grid-active-border-color: #999;
  --grid-image-background-color: #333;
  --active-button-text-color: black;
  --active-button-color: rgb(177, 177, 177);
  --slider-background-color: whitesmoke;
  --slider-background-hover-color: rgb(210, 210, 210);
  --modal-background-color: #333;
  --modal-danger-background-color: #222;
  --progress-bar-foreground-color: var(--active-button-color);
  --progress-bar-background-color: #222;
  --image-alert-background-color: rgb(82, 80, 83);
  --image-alert-shadow: 8px 8px 0px black;
  --placeholder-image-background-color: rgb(33, 35, 37);
  --top-background-image: linear-gradient(
      45deg,
      transparent 0%,
      transparent 46%,
      rgba(114, 114, 114, 0.06) 46%,
      rgba(114, 114, 114, 0.06) 70%,
      transparent 70%,
      transparent 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 59%,
      rgba(114, 114, 114, 0.06) 59%,
      rgba(114, 114, 114, 0.06) 91%,
      transparent 91%,
      transparent 100%
    ),
    linear-gradient(
      0deg,
      transparent 0%,
      transparent 15%,
      rgba(114, 114, 114, 0.06) 15%,
      rgba(114, 114, 114, 0.06) 40%,
      transparent 40%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(0, 0, 0), rgb(0, 0, 0));

  --main-background-image: radial-gradient(
      circle at 16% 83%,
      rgba(148, 148, 148, 0.06) 0%,
      rgba(148, 148, 148, 0.06) 50%,
      rgba(63, 63, 63, 0.06) 50%,
      rgba(63, 63, 63, 0.06) 100%
    ),
    radial-gradient(
      circle at 68% 87%,
      rgba(66, 66, 66, 0.06) 0%,
      rgba(66, 66, 66, 0.06) 50%,
      rgba(105, 105, 105, 0.06) 50%,
      rgba(105, 105, 105, 0.06) 100%
    ),
    radial-gradient(
      circle at 38% 50%,
      rgba(123, 123, 123, 0.06) 0%,
      rgba(123, 123, 123, 0.06) 50%,
      rgba(172, 172, 172, 0.06) 50%,
      rgba(172, 172, 172, 0.06) 100%
    ),
    linear-gradient(90deg, hsl(18, 0%, 1%), hsl(18, 0%, 1%));

  --bottom-background-image: var(--top-background-image);
}

// Overrides for vue-slider
.override .vue-slider-dot-tooltip-inner {
  background-color: var(--button-color) !important;
  border-color: var(--button-color) !important;
}
.override .vue-slider-process {
  background-color: var(--button-color) !important;
}
.override .vue-slider-dot-handle {
  border-color: var(--button-color) !important;
}
.override .vue-slider-rail {
  background-color: var(--slider-background-color);
}
.override .vue-slider-mark-label {
  color: var(--text-color);
}

.override {
  &:hover .vue-slider-rail {
    background-color: var(--slider-background-hover-color) !important;
  }
}
</style>
