import map from "lodash/map";
import isUndefined from "lodash/isUndefined";

export const ensureArrayHasUniqueIds = _array => {
  var existing_ids = [];
  return map(_array, s => {
    if (isUndefined(s.id) || existing_ids.includes(s.id)) {
      s.id = Math.random()
        .toString(36)
        .substring(7);
    }
    existing_ids.push(s.id);
    return s;
  });
};
