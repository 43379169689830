import each from "lodash/each";
import times from "lodash/times";
import cloneDeep from "lodash/cloneDeep";

//const STRAIGHTCUT = "straight cut";
const CROSSFADE = "crossfade";

const VIDEO_1080_ENDPOINT =
  "https://soundslides-media-mp4-1080.s3.amazonaws.com/video";

export const adjustVideoSlidesIfNeeded = (slideArray, audio_duration_sec) => {
  // Check that each video file fits in the available duration.

  slideArray = cloneDeep(slideArray);
  var adjust_missing_frames = true; // Make this so you can remove it later.

  if (adjust_missing_frames) {
    var missing_frames = 4;
    var fps = 25;
    var adjustedSlideArray = [];
    var nextSlideTiming = slideArray[0].timing;

    each(slideArray, (s, idx) => {
      s.timing = nextSlideTiming;

      if (idx < slideArray.length - 1) {
        nextSlideTiming = slideArray[idx + 1].timing;
      } else {
        // No need to worry about next timing of last video.
      }

      if (s.type == "video") {
        // Remove missing frames from the next slide.
        nextSlideTiming = nextSlideTiming - missing_frames * (1000 / fps);
      }

      adjustedSlideArray.push(s);
    });

    //console.log(adjustedSlideArray);
    slideArray = adjustedSlideArray;
  }

  var newSlideArray = [];
  each(slideArray, (s, idx) => {
    if (s.type == "video") {
      // Correct source_url to use the 1080 version.

      s.source_url = `${VIDEO_1080_ENDPOINT}/${s.source_id}/${s.file}`;

      // Check for looping videos.
      var video_duration_ms = Math.round(s.duration * 1000);
      var slide_duration_ms;
      if (idx < slideArray.length - 1) {
        console.log("slide + 1 timing", slideArray[idx + 1].timing);
        slide_duration_ms = slideArray[idx + 1].timing - s.timing;
      } else {
        slide_duration_ms = audio_duration_sec * 1000 - s.timing;
      }

      slide_duration_ms = Math.round(
        slide_duration_ms +
          (s.transition_time_in + s.transition_time_out) * 1000
      );

      // Melt video does not need or use transition_time_in or transition_time_out.

      // Remove it here.
      // Melt video converter only uses transition_duration.

      delete s.transition_time_in;
      delete s.transition_time_out;

      console.log("video_duration_ms", video_duration_ms);
      console.log("slide_duration_ms", slide_duration_ms);

      if (slide_duration_ms > video_duration_ms) {
        console.log("Looping! We need intermeditate slides!");
        // How many do we need?

        var loopsNeeded = Math.ceil(slide_duration_ms / video_duration_ms);
        console.log("loopsNeeded", loopsNeeded);

        var loopSlideClone = cloneDeep(s);

        times(loopsNeeded, n => {
          if (n == 0) {
            // Add initial copy as is.
            newSlideArray.push(cloneDeep(loopSlideClone));
          } else {
            var loopSlide = cloneDeep(loopSlideClone);
            var interslide_transition_duration = 0.4;
            // First interslide needs to account for parent slide's transition.
            var parentSlideTimingMs = loopSlideClone.timing;
            var parentSlideTransitionDurationSec =
              loopSlideClone.transition_duration;
            if (parentSlideTimingMs == 0) {
              // It's the first slide. There's no transition on first slides.
              parentSlideTransitionDurationSec = 0;
            }
            var parentActualTimingMs =
              parentSlideTimingMs - parentSlideTransitionDurationSec * 1000;
            console.log("parentActualTimingMs", parentActualTimingMs);
            var adjustedTiming =
              (loopSlideClone.duration - interslide_transition_duration) *
              1000 *
              n;
            var timing = parentActualTimingMs + adjustedTiming;
            loopSlide.timing = timing;
            loopSlide.transition_duration = interslide_transition_duration;
            loopSlide.transition = CROSSFADE; // All interslides are crossfades.
            newSlideArray.push(loopSlide);
          }
        });
      } else {
        // Normal video slide, no loop, just add it.
        newSlideArray.push(s);
      }
    } else {
      newSlideArray.push(s);
    }
  });

  return newSlideArray;
};
