// Set defaults here.

// plans just handles config, UI is displayed via Plans.vue
export const plans = {
  basic: {
    name: "Basic",
    plan_id: "basic_annual", // Must exist in Stripe TEST and in PRODUCTION.
    stripe_plan: true,
    is_variable_amount: false,
    description: "Annual Plan",
    amount: 1200,
    concurrent_processing_limit: 10,
    show_limit: 15,
    image_limit: 360,
    video_limit: 2,
    video_duration_limit_seconds: 10,
    motion_enabled: false
  },
  standard: {
    name: "Standard",
    plan_id: "standard_annual", // Must exist in Stripe TEST and in PRODUCTION.
    stripe_plan: true,
    is_variable_amount: false,
    description: "Annual Plan",
    amount: 1900,
    concurrent_processing_limit: 10,
    show_limit: 10,
    image_limit: 360,
    video_limit: 3,
    video_duration_limit_seconds: 10,
    motion_enabled: false
  },
  standard_monthly: {
    name: "Standard",
    plan_id: "standard_monthly", // Must exist in Stripe TEST and in PRODUCTION.
    stripe_plan: true,
    is_variable_amount: false,
    description: "Monthly Plan",
    amount: 300,
    concurrent_processing_limit: 10,
    show_limit: 10,
    image_limit: 360,
    video_limit: 3,
    video_duration_limit_seconds: 10,
    motion_enabled: false
  },
  plus: {
    name: "Plus",
    plan_id: "plus_annual", // Must exist in Stripe TEST and in PRODUCTION.
    stripe_plan: true,
    is_variable_amount: false,
    description: "Annual Plan",
    amount: 3900,
    concurrent_processing_limit: 10,
    show_limit: 50,
    image_limit: 360,
    video_limit: 5,
    video_duration_limit_seconds: 10,
    motion_enabled: true
  },
  plus_monthly: {
    name: "Plus",
    plan_id: "plus_monthly", // Must exist in Stripe TEST and in PRODUCTION.
    stripe_plan: true,
    is_variable_amount: false,
    description: "Monthly Plan",
    amount: 500,
    concurrent_processing_limit: 10,
    show_limit: 50,
    image_limit: 360,
    video_limit: 5,
    video_duration_limit_seconds: 10,
    motion_enabled: true
  },
  premium: {
    name: "Premium",
    plan_id: "premium_annual", // Must exist in Stripe TEST and in PRODUCTION.
    stripe_plan: true,
    is_variable_amount: false,
    description: "Annual Plan",
    amount: 6900,
    concurrent_processing_limit: 10,
    show_limit: 10000,
    image_limit: 360,
    video_limit: 8,
    video_duration_limit_seconds: 10,
    motion_enabled: true
  },
  premium_monthly: {
    name: "Premium",
    plan_id: "premium_monthly", // Must exist in Stripe TEST and in PRODUCTION.
    stripe_plan: true,
    is_variable_amount: false,
    description: "Monthly Plan",
    amount: 1000,
    concurrent_processing_limit: 10,
    show_limit: 10000,
    image_limit: 360,
    video_limit: 8,
    video_duration_limit_seconds: 10,
    motion_enabled: true
  },
  trial: {
    name: "Trial",
    plan_id: "trial", // Not important, as is not a subscription.
    stripe_plan: false,
    is_variable_amount: false,
    description: "Limited Trial Plan",
    amount: 0,
    concurrent_processing_limit: 1,
    show_limit: 5,
    image_limit: 50,
    video_limit: 2,
    video_duration_limit_seconds: 10,
    motion_enabled: false
  }
};

export const maxImagesPerShow = 360;

export const approvedBrowsers = {
  chrome: ">=56",
  firefox: ">65",
  safari: ">=11"
};

var today = new Date();
var couponCutoffDate = new Date("08-22-2019");
var isBeforeCutoff = today < couponCutoffDate;

export const global_coupon = {
  percentage_off: 50,
  enabled: isBeforeCutoff
};

var stripe_pk_key;
var payment_endpoint;
var paypal_environment;

if (process.env.NODE_ENV === "production") {
  // Stripe PRODUCTION public key.
  stripe_pk_key = "pk_live_Sg9oikp8n7rsZopFyAtqjwcy";
  payment_endpoint = "https://payments.soundslides.com/live/";
  paypal_environment = "live";
} else {
  // Stripe TEST public key.
  stripe_pk_key = "pk_test_z3eKV08Huqj89yYsnkiQnwGa";
  payment_endpoint = "https://payments.soundslides.com/test/";
  paypal_environment = "sandbox";
}

export const subscription_group_endpoint =
  "https://subscriptions.soundslides.com/";

//export const subscription_group_endpoint = "http://127.0.0.1:5000/";

export const stripe_public_key = stripe_pk_key;
export const stripe_payment_endpoint = payment_endpoint;

export const paypal_env = paypal_environment;

export const untitled_show_headline = "Untitled";

export const numberOfTrialDays = 15;
