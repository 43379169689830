import map from "lodash/map";
import filter from "lodash/filter";
import find from "lodash/find";
import each from "lodash/each";
import sample from "lodash/sample";

import { AutoCropSelection } from "@/models/utils/autocrops.js";

/*

Lots of magic can happen here, namely the conversion of video slides into a
slide array format.

*/

export const returnRandomArray = (sampleArray, n) => {
  var arr = new Array(n);
  arr = map(arr, () => {
    return sample(sampleArray);
  });
  return arr;
};

const returnAutoSpacedSlideArrayImagesOnly = show_object => {
  var images = show_object.data.images;
  var duration = show_object.data.audio.duration;
  var slideArray = [];
  var trimmed_image_count = Math.round(
    duration / show_object.data.autoTimePerImage
  );
  images = images.slice(0, trimmed_image_count);
  slideArray = map(images, (i, idx) => {
    var slide = {
      caption: "",
      file: i.filename,
      id: i.id,
      name: i.name,
      source_id: i.source_id,
      timing: Math.round(idx * show_object.data.autoTimePerImage * 1000)
    };
    if (i.width) {
      slide.width = i.width;
    }
    if (i.height) {
      slide.height = i.height;
    }
    return slide;
  });
  return slideArray;
};

const returnAutoSpacedSlideArrayWithVideo = show_object => {
  var images = show_object.data.images;
  var duration = show_object.data.audio.duration;
  var timePerImage = show_object.data.autoTimePerImage;
  var inpoint = 0;
  var slide_inpoint;
  var timings = map(images, i => {
    if (i.type === "video") {
      var video = find(
        filter(show_object, (s, k) => {
          if (k.startsWith("staticMp4Deployed_")) {
            return s;
          }
        }),
        { filename: i.filename }
      );
      slide_inpoint = inpoint; // do we factor in the transition or not????
      inpoint =
        inpoint +
        video.duration -
        show_object.data.displaySettings.globalTransitionTime; // do we factor in the transition or not????
      return slide_inpoint;
    } else {
      slide_inpoint = inpoint;
      inpoint = inpoint + timePerImage;
      return slide_inpoint;
    }
  });
  //console.log("timings", timings);
  var slideArray = [];
  each(timings, (i, idx) => {
    //console.log(i, duration);
    var slideObj = {};
    if (i < duration) {
      // Valid time for inclusion in the array.
      var slide = images[idx];
      var timing_ms = i * 1000;
      if (slide.type === "video") {
        // Find the extra attributes for the video file and return them.
        // Properties needed for still slide.
        var video = find(
          filter(show_object, (s, k) => {
            if (k.startsWith("staticMp4Deployed_")) {
              return s;
            }
          }),
          { filename: slide.filename }
        );
        //console.log("video details", video);
        slideObj = {
          file: slide.filename,
          source_id: slide.source_id,
          id: slide.id,
          name: slide.name,
          caption: "",
          timing: timing_ms,
          type: "video",
          source_url: `https://cdn3.soundslides.com/video/${video.source_id}/${video.filename}`,
          source_aspect_ratio: video.displayaspectratio,
          duration: video.duration,
          inpoint: timing_ms / 1000,
          keep_sync: true,
          transition_time_in:
            show_object.data.displaySettings.globalTransitionTime / 2,
          transition_time_out:
            show_object.data.displaySettings.globalTransitionTime / 2,
          main_volume: 1,
          slide_volume: 1
        };
      } else {
        // Properties needed for still slide.
        slideObj = {
          caption: "",
          file: slide.filename,
          id: slide.id,
          name: slide.name,
          source_id: slide.source_id,
          timing: timing_ms
        };
      }
      slideArray.push(slideObj);
    }
  });
  //console.log("slideArray", slideArray);
  return slideArray;
};

const returnAutoSpacedSlideArray = show_object => {
  var images = show_object.data.images;
  var imagesWithVideo = filter(images, { type: "video" });
  //console.log("imagesWithVideo", imagesWithVideo);
  if (imagesWithVideo.length === 0) {
    return returnAutoSpacedSlideArrayImagesOnly(show_object);
  } else {
    return returnAutoSpacedSlideArrayWithVideo(show_object);
  }
};

export const returnAutoMotionSlideArray = (show_object, playerAspect) => {
  // This is the main entry point for returning auto slide arrays.

  var slideArray = returnAutoSpacedSlideArray(show_object);

  // If there's ids already associated with slides,
  // let's try to preserve them (to prevent unneeded timeline rebuilds).
  //var id_map = map(show_object.data?.slideArray, s => {
  //  return {'filename': s.filename}
  //})

  // Is there auto motion applied?
  var displaySettings = show_object.data.displaySettings || {};
  var globalMotionPreset = displaySettings.globalMotionPreset || "none";
  console.log("globalMotionPreset", globalMotionPreset);
  if (globalMotionPreset == "none") {
    // None, just return the normal slide array here.
    return slideArray;
  }

  if (!playerAspect) {
    playerAspect = 3 / 2;
  }

  // Metadata image order.
  var imageMetadataItems = map(show_object, (s, k) => {
    if (k.startsWith("metadataImages_")) {
      return s;
    }
  });

  // Metadata bounding boxes.
  var imageBoundingBoxItems = map(show_object, (s, k) => {
    if (k.startsWith("metadataCV_")) {
      return s;
    }
  });

  var speed = show_object.data.displaySettings.globalMotionSpeed;

  var directionOrderArray =
    show_object.data.displaySettings.globalMotionRandomOrder;
  if (show_object.data.displaySettings.globalMotionType == "alternate") {
    directionOrderArray = ["in", "out"];
  }

  function getItemAtArrayIndex(arr, i) {
    // This works in an endless cycle ... if item doesn't exist, it loops.
    if (i === 0) {
      return arr[i];
    } else if (i < 0) {
      return arr[(arr.length + i) % arr.length];
    } else if (i > 0) {
      return arr[i % arr.length];
    }
  }

  slideArray = map(slideArray, (s, idx) => {
    var metadata = find(imageMetadataItems, { filename: s.file });
    var boundingBoxes = find(imageBoundingBoxItems, { filename: s.file });
    if (metadata) {
      // Compute and add movement data based off of the width, height here.
      var motionDirection = getItemAtArrayIndex(directionOrderArray, idx);
      console.log(metadata);
      var width = metadata.width;
      var height = metadata.height;
      var imageAspect = width / height;

      if (boundingBoxes) {
        console.log("We have bounding boxes!!!!");

        var speedPresetString = "slow";
        if (speed == 80) {
          speedPresetString = "medium";
        }
        if (speed == 200) {
          speedPresetString = "fast";
        }

        var tweenSeconds = show_object.data.autoTimePerImage;

        var presetCropSelectionObj = new AutoCropSelection(
          width,
          height,
          playerAspect,
          boundingBoxes.boxes.f,
          boundingBoxes.boxes.l
        ).bestGuessBySpeedPresetAndSeconds(speedPresetString, tweenSeconds);
        console.log(presetCropSelectionObj);

        var start = presetCropSelectionObj.start;
        var end = presetCropSelectionObj.end;

        if (motionDirection === "out") {
          start = presetCropSelectionObj.end;
          end = presetCropSelectionObj.start;
        }

        s.start_percent_x = start.player_percent_x;
        s.start_percent_y = start.player_percent_y;
        s.start_percent_scale = start.player_percent_scale;

        s.end_percent_x = end.player_percent_x;
        s.end_percent_y = end.player_percent_y;
        s.end_percent_scale = end.player_percent_scale;

        s.has_motion = true;
      } else {
        // Use the older algoritm for now.
        //console.log("playerAspect of source image", width / height);
        console.log("No bounding boxes!");
        if (imageAspect < 0.9 || imageAspect > 2) {
          // Unsafe motion.
          // Return without motion.
          console.log("return without motion");
          s.has_motion = false;
          return s;
        }
        // Following has motion.
        s.has_motion = true;
        var duration = show_object.data.autoTimePerImage;

        //console.log("speed", speed);
        //console.log("duration", duration);
        //console.log("motionDirection", motionDirection);
        // Reset both.
        console.log(imageAspect, playerAspect);
        var fillValues = returnFillValues(imageAspect, playerAspect);
        s.start_percent_x = fillValues.scaleDiff;
        s.start_percent_y = fillValues.scaleDiff;
        s.start_percent_scale = fillValues.scale;
        s.end_percent_x = fillValues.scaleDiff;
        s.end_percent_y = fillValues.scaleDiff;
        s.end_percent_scale = fillValues.scale;

        var percent_per_second = speed;

        // Base percent on the duration of the slide.

        var scaleMax = 160; // Value shared with editor!
        var percent = Math.ceil((percent_per_second / 10) * duration);
        // Enforce an upper limit on scale.
        if (fillValues.scale + percent > scaleMax) {
          //console.log("Over the max!");
          //percent = scaleMax - fillValues.scale;
        }

        if (motionDirection === "in") {
          s.end_percent_x = fillValues.scaleDiff - percent / 2;
          s.end_percent_y = fillValues.scaleDiff - percent / 2;
          s.end_percent_scale = fillValues.scale + percent;
        } else {
          s.start_percent_x = fillValues.scaleDiff - percent / 2;
          s.start_percent_y = fillValues.scaleDiff - percent / 2;
          s.start_percent_scale = fillValues.scale + percent;
        }
      }
    }
    return s;
  });
  return slideArray;
};

const returnFillValues = (imageAspect, playerAspect) => {
  //console.log("imageAspect", imageAspect);
  //console.log("playerAspect", playerAspect);

  // START OF SHARED CODE.
  // Note: the following is shared with services. If you change this, change it there too.

  var scale = (playerAspect / imageAspect) * 100;
  if (imageAspect > playerAspect) {
    // Reverse them.
    scale = (imageAspect / playerAspect) * 100;
  }
  //console.log("scale", scale);
  scale = scale + 1; // Give new scale a little bit of padding.
  var scaleDiff = -((scale - 100) / 2);

  // END OF SHARED.
  return {
    scale: scale,
    scaleDiff: scaleDiff
  };
};
