export const returnResponsiveEmbedCode = show_object => {
  var show_key = show_object.key;
  var aspectRatio = show_object.data.displaySettings.aspectRatio || 3 / 2;
  var aspect_padding_bottom = (100 / aspectRatio).toFixed(2) + "%";
  return `<style>.embed-container { position: relative; padding-bottom: ${aspect_padding_bottom}; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }</style><div class='embed-container'><iframe src="https://play.soundslides.com/${show_key}/fill" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>`;
};

export const returnShowStateDescription = show_object => {
  // Note, show_object may not be a full object.
  // It may be a truncated object from the show index.
  if (!show_object.privacySelected) {
    if (!show_object.published) {
      return "This show is private.";
    } else {
      return "Anyone can see this show.";
    }
  } else {
    // We have a privacySelected property.
    if (show_object.privacySelected == "private") {
      return "Only you can see this show.";
    }
    if (show_object.privacySelected == "password") {
      return "Anyone with the password can see this show.";
    }
    if (show_object.privacySelected == "privateLink") {
      return "Anyone with the private link can see this show.";
    }
    return "Anyone can see this show.";
  }
};
