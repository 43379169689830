import { adjustVideoSlidesIfNeeded } from "./utils/exportVideoUtils";
import each from "lodash/each";
import isUndefined from "lodash/isUndefined";
import map from "lodash/map";
import omit from "lodash/omit";

export class MltShow {
  constructor(showModel) {
    this.showModel = showModel;
    this.duration = this.returnDuration();
  }
  get slideArray() {
    var _array = adjustVideoSlidesIfNeeded(
      this.showModel.slideArray,
      this.duration
    );
    // Remove ids, not needed.
    _array = map(_array, i => omit(i, "id"));
    return _array;
  }
  get overlayArray() {
    var overlayArray = this.showModel.overlayArray;
    // If the autoShowCaptions is true, export any captions.
    if (this.showModel.data.displaySettings.autoShowCaptions == true) {
      each(this.slideArray, (s, idx) => {
        if (
          s.caption != "" &&
          s.caption != "undefined" &&
          !isUndefined(s.caption)
        ) {
          var inpoint = s.timing / 1000;
          var duration;
          var outpoint;
          if (idx < this.slideArray.length - 1) {
            outpoint = this.slideArray[idx + 1].timing / 1000;
          } else {
            outpoint = this.duration;
          }
          duration = outpoint - inpoint;
          overlayArray.push({
            text: s.caption,
            inpoint: inpoint,
            duration: duration,
            type: "caption"
          });
        }
      });
    }
    return overlayArray;
  }
  returnDuration() {
    var showDuration;
    if ("audio" in this.showModel.data) {
      showDuration = this.showModel.data.audio.duration;
    } else {
      showDuration =
        this.slideArray.length *
        this.showModel.data.displaySettings.fakeAudioPlaybackSecsPerImage;
    }
    return showDuration;
  }
}
