export default {
  user_plan: state => {
    return state.user_plan;
  },
  is_trial: (state, getters) => {
    // Returns if is non-paid account.
    if (getters.user_plan) {
      if (getters.user_plan.plan_id == "trial") {
        return true;
      }
    }
    return false;
  },

  show_coupon_offer: (state, getters) => {
    return state.global_coupon.enabled && getters.is_trial;
  },
  slideshowTotal(state) {
    return state.user_show_total;
  },
  limitApproaching(state, getters) {
    var limitRemainingThreshold = 2;
    if (!getters.user_plan) {
      return false;
    }
    if (
      getters.slideshowTotal >=
      getters.user_plan.show_limit - limitRemainingThreshold
    ) {
      return true;
    }
    return false;
  },
  paidShowLimitReached(state, getters) {
    if (!getters.user_plan) {
      return false;
    }
    if (getters.slideshowTotal >= getters.user_plan.show_limit) {
      return true;
    }
    return false;
  },
  trialShowLimitReached(state, getters) {
    if (!getters.user_plan) {
      return false;
    }
    if (getters.slideshowTotal >= getters.user_plan.show_limit) {
      return true;
    }
    return false;
  },
  restrictShowCreation(state, getters) {
    if (getters.is_trial) {
      return getters.trialShowLimitReached || state.validTrialPeriod === false;
    } else {
      return getters.paidShowLimitReached;
    }
  }
};
