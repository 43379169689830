export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    updateImageByIdToTime({ dispatch }, obj) {
      console.log("updateImageByIdToTime", obj);
      var update_obj = {
        id: obj.image_id,
        seconds: obj.time
      };
      dispatch("show/updateSlideByIdToSeconds", update_obj, {
        root: true
      });
    }
  }
};
