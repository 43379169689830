import each from "lodash/each";
import { TimelineMax, TweenLite, Linear, CSSPlugin } from "gsap/all";
// Imports MUST come from gsap/all, or it will import everything!
// Also, CSSPlugin is used in everything, so import it even though it isn't directly used.
// See: https://greensock.com/forums/topic/18437-import-from-gsap-vs-from-gsapall/
// Ensure modules don't get dropped by tree-shaking
const activatedGsapModules = [TimelineMax, TweenLite, Linear, CSSPlugin];
console.log(activatedGsapModules);

const INHERIT_TRANSITION_TYPE = "inherit";
const FADEOUTFADEIN = "fade out/fade in";
const STRAIGHTCUT = "straight cut";
const CROSSFADE = "crossfade";

export const createTimeline = function(_v) {
  // console.log("createTimeline");
  if (_v.timeline) {
    // console.log("Kill timeline");
    _v.timeline.remove();
    _v.timeline.kill();
  }
  _v.timeline = new TimelineMax({ paused: true });
  //this.timeline.duration(173);
  //window.timeline = this.timeline;

  var _slideTimelineArray = [];

  var slideElements = _v.$el.getElementsByClassName("images");
  each(slideElements, (el, idx) => {
    // There are the same number of elements as are slides in slideArray.
    // Add background color.
    el.style.backgroundColor = _v.backgroundColor;
    // Add Tween.
    // console.log(_v.slideArray[idx]);

    // In and OutPoint.
    var inPoint = _v.slideArray[idx].timing / 1000;
    if (idx != 0) {
      // Add in the outpoint for the previous slide.
      _slideTimelineArray[idx - 1].outPoint = inPoint;
    }

    var transitionTime = _v.transitionTime;
    if (idx === 0 && inPoint == 0) {
      // First image at inPoint zero will not have a transition.
      transitionTime = 0;
    }

    // console.log("_v.slideArray[idx]", _v.slideArray[idx]);

    var transitionType = _v.transitionType;
    // Look for individual slide's straight cuts and adjust transitionTime accordingly.
    if (
      "transition" in _v.slideArray[idx] &&
      _v.slideArray[idx].transition !== INHERIT_TRANSITION_TYPE
    ) {
      // console.log("per-slide transition!");
      // We have a per-slide transition type.
      transitionType = _v.slideArray[idx].transition;
      transitionTime =
        _v.slideArray[idx].transition_duration || _v.transitionTime;
    }

    if (transitionType === STRAIGHTCUT) {
      transitionTime = 0;
    }
    var slideData = _v.slideArray[idx];

    _slideTimelineArray.push({
      $element: el,
      transitionType: transitionType,
      inPoint: inPoint,
      inTransitionTime: transitionTime,
      outTransitionTime: transitionTime,
      slideData: slideData
    });

    // Adjust in and out points for crossfade.
    if (transitionType === CROSSFADE) {
      _slideTimelineArray[idx].inPoint -= transitionTime / 2;
      if (idx != 0) {
        // Cross fade straddles the in/out point.
        _slideTimelineArray[idx - 1].outPoint += transitionTime / 2;
        // Because Cross fade come in over the other, there's no need for an out
        // transition unless the slide involves transparancy. If you find that crossfades
        // Are problematic, remove the following line and the image will just be hidden
        // underneath the other image.
        //_slideTimelineArray[idx - 1].outPoint = null;
      }
    }

    // Adjust in and out points for fade out fade in.
    if (transitionType === FADEOUTFADEIN) {
      _slideTimelineArray[idx].inTransitionTime = transitionTime / 2;
      if (idx != 0) {
        // Requires adjustment or previous image.
        _slideTimelineArray[idx - 1].outPoint -= transitionTime / 2;
        _slideTimelineArray[idx - 1].outTransitionTime = transitionTime / 2;
      }
    }
  });

  // console.log(_slideTimelineArray);

  // Actually add slides to the timeline.
  each(_slideTimelineArray, (slide, idx) => {
    var slideData = slide.slideData;
    // console.log(slideData);
    if (slideData.has_motion) {
      // Set start position.
      _v.timeline.add(
        TweenLite.to(slide.$element, 0, {
          scale: slideData.start_percent_scale / 100,
          x: slideData.start_percent_x + "%",
          y: slideData.start_percent_y + "%",
          transformOrigin: "left top",
          force3D: true,
          rotation: 0.01,
          ease: Linear.easeNone,
          onComplete: _v.checkSyncAudioAndTimeline
        }),
        slide.inPoint
      );
      var moveDuration = slide.outPoint - slide.inPoint;
      var audio_duration =
        _v.audioDurationOverride > 0
          ? _v.audioDurationOverride
          : _v.audioDuration;
      // console.log("audio_duration", audio_duration);
      if (!slide.outPoint) {
        moveDuration = audio_duration - slide.inPoint;
      }
      // console.log("moveDuration", moveDuration);

      // Find the transition (as FIFO affects movement length)

      var transitionTime = _v.transitionTime;
      var transitionType = _v.transitionType;
      // Look for individual slide's straight cuts and adjust transitionTime accordingly.
      if (
        "transition" in _v.slideArray[idx] &&
        _v.slideArray[idx].transition !== INHERIT_TRANSITION_TYPE
      ) {
        // console.log("per-slide transition!");
        // We have a per-slide transition type.
        transitionType = _v.slideArray[idx].transition;
        transitionTime =
          _v.slideArray[idx].transition_duration || _v.transitionTime;
      }

      // console.log("moveDuration", moveDuration);
      if (transitionType === FADEOUTFADEIN) {
        moveDuration = moveDuration + transitionTime / 2;
      }

      // console.log("transitionType", transitionType);
      // console.log("moveDuration", moveDuration);
      _v.timeline.add(
        TweenLite.to(slide.$element, moveDuration, {
          scale: slideData.end_percent_scale / 100,
          x: slideData.end_percent_x + "%",
          y: slideData.end_percent_y + "%",
          transformOrigin: "left top",
          force3D: true,
          rotation: 0.01,
          ease: Linear.easeNone,
          onComplete: _v.checkSyncAudioAndTimeline
        }),
        slide.inPoint
      );
    }

    // In transition.
    _v.timeline.add(
      TweenLite.to(slide.$element, slide.inTransitionTime, {
        autoAlpha: 1,
        ease: Linear.easeNone,
        onComplete: _v.checkSyncAudioAndTimeline
      }),
      slide.inPoint
    );
    // Out transition.
    if (slide.outPoint) {
      _v.timeline.add(
        TweenLite.to(slide.$element, slide.outTransitionTime, {
          autoAlpha: 0,
          ease: Linear.easeNone,
          onComplete: _v.checkSyncAudioAndTimeline
        }),
        slide.outPoint
      );
    }
  });

  // Lowerthirds are a type of Overlay.
  var overlayElements = _v.$el.getElementsByClassName("overlays");
  each(overlayElements, (el, idx) => {
    // Add Tween.
    var inPoint = _v.overlayArray[idx].inpoint;
    var duration = _v.overlayArray[idx].duration;
    var overlayTransitionTime = 1;
    if ("transition_seconds" in _v.overlayArray[idx]) {
      overlayTransitionTime = _v.overlayArray[idx].transition_seconds;
    }
    var transitionTimeIn = overlayTransitionTime;
    var transitionTimeOut = overlayTransitionTime;
    if (inPoint === 0) {
      transitionTimeIn = 0;
    }
    _v.timeline.add(
      TweenLite.to(el, transitionTimeIn, {
        autoAlpha: 1,
        //visibility: "visible",
        ease: Linear.easeNone
        //"z-index": 10000
        //onComplete: _v.checkSyncAudioAndTimeline
      }),
      inPoint
    );
    _v.timeline.add(
      TweenLite.to(el, transitionTimeOut, {
        autoAlpha: 0,
        ease: Linear.easeNone
        //"z-index": 9999
        //onComplete: _v.checkSyncAudioAndTimeline
      }),
      inPoint + duration
    );
    // Make invisible.
    //_v.timeline.add(
    //  TweenLite.to(el, inPoint + duration, {
    //    visibility: "hidden"
    //  })
    //);
  });

  _v.checkSyncAudioAndTimeline(); // Immediately check, in case it's being redrawn from editing.
  _v.timeUpdate();

  if (_v.timeDeltaWatcherInt) {
    clearInterval(_v.timeDeltaWatcherInt);
  }
  _v.timeDeltaWatcherInt = setInterval(function() {
    _v.checkSyncAudioAndTimeline();
  }, _v.timeDeltaWatcherIntValueMS);
};
