export default {
  data() {
    return {
      windowWidth: 100,
      windowHeight: 100
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.onResize();
    var _v = this;
    this.$nextTick(function() {
      window.addEventListener("resize", _v.onResize);
    });
  },
  methods: {
    onResize() {
      this.$nextTick(function() {
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
      });
    }
  }
};
