<template lang="html">
  <div
    :style="outer_containers_style"
    class="outer-container"
    @click="outerClick"
    @mousedown="outerMouseDown"
    @mousemove="outerMove"
  >
    <div :style="containers_style" class="inner-container">
      <!-- The timeline slides -->
      <div :key="version" class="player" @click="playToggle">
        <!-- Image slides -->
        <div
          v-for="(image, idx) in slideArray"
          :key="idx + image.file"
          :style="slide_area_style"
          :class="{
            willChange:
              idx > currentSlideIndex - 1 && idx < currentSlideIndex + 1
          }"
          class="images slides"
        >
          <ImageDisplay
            v-if="imageDirectoryLargest != null && image.type != 'video'"
            :filepath="returnImagePath(image)"
            :index="idx"
            :preload="preloadImage(idx)"
            @image-loaded="imageLoaded"
          />
          <VideoDisplay
            v-if="imageDirectoryLargest != null && image.type == 'video'"
            ref="video_displays"
            :index="idx"
            :item="image"
            :slide_array="slideArray"
            :audio_duration="audioDuration"
            :muted="videoMuted"
            :preload="preloadImage(idx)"
            @image-loaded="imageLoaded"
          />
        </div>
        <!-- Overlays -->
        <div class="overlays_all">
          <div
            v-for="(item, idx) in overlayArray"
            :key="'overlay_' + idx"
            :style="overlay_style(item)"
            class="overlays slides"
            :class="{
              interactive_overlays: item.type == 'invisible_link',
              non_interactive_overlays: item.type != 'invisible_link'
            }"
          >
            <Lowerthird
              v-if="item.type == 'lowerthird' || item.type == undefined"
              :item="item"
              :controls-auto-hide="controlsAutoHide"
            />
            <TextEditable
              v-if="item.type == 'text' && editable_overlays"
              :key="item.id + '_' + computed_width + 'x' + computed_height"
              :item="item"
              @pauseAudio="pauseAudio"
            />
            <TextNonEditable
              v-if="item.type == 'text' && !editable_overlays"
              :ref="'overlay_' + item.id"
              :key="item.id"
              :item="item"
            />
            <TextColorEditable
              v-if="item.type == 'text_color' && editable_overlays"
              :ref="'overlay_' + item.id"
              :key="item.id + '_' + computed_width + 'x' + computed_height"
              :item="item"
              @pauseAudio="pauseAudio"
            />
            <TextColorNonEditable
              v-if="item.type == 'text_color' && !editable_overlays"
              :key="item.id"
              :item="item"
            />
            <TextColorEditable
              v-if="item.type == 'rect' && editable_overlays"
              :ref="'overlay_' + item.id"
              :key="item.id + '_' + computed_width + 'x' + computed_height"
              :item="item"
              :disable-text-editing="true"
              @pauseAudio="pauseAudio"
            />
            <TextColorNonEditable
              v-if="item.type == 'rect' && !editable_overlays"
              :key="item.id"
              :item="item"
            />
            <TextColorEditable
              v-if="item.type == 'circle' && editable_overlays"
              :ref="'overlay_' + item.id"
              :key="item.id + '_' + computed_width + 'x' + computed_height"
              :item="item"
              :disable-text-editing="true"
              :rounded-background="true"
              @pauseAudio="pauseAudio"
            />
            <TextColorNonEditable
              v-if="item.type == 'circle' && !editable_overlays"
              :key="item.id"
              :rounded-background="true"
              :item="item"
            />
            <ButtonDraggable
              v-if="item.type == 'button' && editable_overlays"
              :key="item.id + '_' + computed_width + 'x' + computed_height"
              :item="item"
              :is-paused="isPaused"
              @pauseAudio="pauseAudio"
              @playAudio="playAudio"
              @playToggle="playToggle"
              @gotoAndPlay="gotoAndPlay"
              @gotoAndPause="gotoAndPause"
              @pauseForLink="goto_and_pause_from_interactive_click"
            />
            <ButtonNonEditable
              v-if="item.type == 'button' && !editable_overlays"
              :key="item.id + '_' + computed_width + 'x' + computed_height"
              :item="item"
              :is-paused="isPaused"
              @pauseAudio="pauseAudio"
              @playAudio="playAudio"
              @playToggle="playToggle"
              @gotoAndPlay="gotoAndPlay"
              @gotoAndPause="gotoAndPause"
              @pauseForLink="goto_and_pause_from_interactive_click"
            />
            <TitleOverlay v-if="item.type == 'title'" :item="item" />
            <StackedOverlay v-if="item.type == 'stacked'" :item="item" />
            <InvisibleLinkOverlay
              v-if="item.type == 'invisible_link'"
              :item="item"
              :current-timeline-time="currentTimelineTime"
              :is-mobile="isMobile"
              @pauseForLink="goto_and_pause_from_interactive_click"
            />
            <TrialWatermark
              v-if="item.type == 'trial_watermark'"
              :item="item"
            />
          </div>
        </div>
      </div>

      <!-- UI's and globals -->
      <BigButton
        v-show="showBigButton && audio_source === null && firstImageLoaded"
        :class="{
          scaling: fillscreen || forceFillscreen || fullscreenFillscreen
        }"
        class="bigbutton"
      />
      <transition name="fade">
        <Buffering v-show="buffering" />
      </transition>
      <Title v-if="title != null && displayTitle" :text="title" class="title" />
      <ImageCaption
        v-if="showImageCaptions"
        :text="active_caption"
        class="captions"
      />
      <!-- Controls -->
      <div v-if="audio_source === null && controlsReady">
        <!-- audio element via plyr -->
        <div v-if="controlsAutoHide" class="controls_background" />
        <Controls
          :ref="'audio-' + _uid"
          :audio_url="audio_src"
          :has_captions="hasCaptions"
          :has_fullscreen="hasFullscreen"
          :captions_active="showImageCaptions"
          :custom_duration="audioDurationOverride"
          class="controls"
          :class="{ controls_always: always_show_controls }"
          @nextImage="nextImage"
          @previousImage="previousImage"
          @fullscreen="toggleFullscreen"
          @toggleCaptions="toggleCaptions"
          @controlsMounted="$emit('playerControlsMounted')"
        />
      </div>
      <!-- Tooltip for captureAndEmitClick -->
      <div
        v-if="captureAndEmitClick && false"
        :style="captureTooltipStyle"
        class="capture-tooltip"
      >
        <span>Add overlay</span>
      </div>
      <div v-if="showPasswordInterface" class="password-ui">
        <div class="center">
          <h3>Password required</h3>
          <input
            ref="privatePassword"
            v-model="privatePassword"
            type="password"
            name="password"
            placeholder="Enter password"
            @keyup.enter="submitPassword"
          />

          <button
            class="button"
            :class="{ disabled: passwordSubmitInProgress }"
            @click="submitPassword"
          >
            Submit
          </button>
          <p v-if="privatePasswordFailed">
            The password you entered is incorrect.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import filter from "lodash/filter";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import findLastIndex from "lodash/findLastIndex";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isUndefined from "lodash/isUndefined";
import map from "lodash/map";
import pick from "lodash/pick";
import round from "lodash/round";

import { createTimeline } from "./timeline";
import {
  returnSlideArrayFromXml,
  returnLowerthirdsArrayFromXml,
  returnShowMetaDataFromXml,
  returnImageDirectoryFromMeta
} from "./soundslidesDataXml";

import {
  returnSlideArrayFromTxt,
  returnShowMetaDataFromTxt,
  returnLowerthirdsArrayFromTxt
} from "./soundslidesDataTxt";

import { returnSlideArrayWithMovementData } from "./motionUtils";

import * as Bowser from "bowser";
import axios from "axios";
import BigButton from "./BigButton";
import Buffering from "./Buffering";
import ButtonDraggable from "./Overlays/ButtonDraggable";
import ButtonNonEditable from "./Overlays/ButtonNonEditable";
import Controls from "./Controls";
import ImageCaption from "./ImageCaption";
import ImageDisplay from "./ImageDisplay";
import InvisibleLinkOverlay from "./Overlays/InvisibleLinkOverlay";
import Lowerthird from "./Overlays/Lowerthird";
import StackedOverlay from "./Overlays/StackedOverlay";
import TextColorEditable from "./Overlays/TextColorEditable";
import TextColorNonEditable from "./Overlays/TextColorNonEditable";
import TextEditable from "./Overlays/TextEditable";
import TextNonEditable from "./Overlays/TextNonEditable";
import Title from "./Title";
import TitleOverlay from "./Overlays/TitleOverlay";
import TrialWatermark from "./Overlays/TrialWatermark";
import VideoDisplay from "./VideoDisplay";

import { returnEmptyWaveBlob } from "./dynamicAudioGeneration";

import { TweenLite, Power2, CSSPlugin } from "gsap/all";
// Imports MUST come from gsap/all, or it will import everything!
// Also, CSSPlugin is used in everything, so import it even though it isn't directly used.
// See: https://greensock.com/forums/topic/18437-import-from-gsap-vs-from-gsapall/
// Ensure modules don't get dropped by tree-shaking
const activatedGsapModules = [TweenLite, Power2, CSSPlugin];
console.log(activatedGsapModules);

export default {
  name: "SoundslidesPlayer",
  components: {
    BigButton,
    Buffering,
    ButtonDraggable,
    ButtonNonEditable,
    Controls,
    ImageCaption,
    ImageDisplay,
    InvisibleLinkOverlay,
    Lowerthird,
    StackedOverlay,
    TextColorEditable,
    TextColorNonEditable,
    TextEditable,
    TextNonEditable,
    Title,
    TitleOverlay,
    TrialWatermark,
    VideoDisplay
  },
  props: {
    width: {
      type: Number,
      default: () => null
    },
    height: {
      type: Number,
      default: () => null
    },
    audio_source: {
      type: HTMLAudioElement,
      default: () => null
    },
    blobUrls: {
      type: Object,
      default: () => {}
    },
    editing_audio_duration: {
      type: Number,
      default: () => null
    },
    editing_audio_source_url: {
      type: String,
      default: () => null
    },
    editing_initial_slide_data: {
      type: Array,
      default: () => null
    },
    editing_initial_overlay_data: {
      type: Array,
      default: () => null
    },
    editable_overlays: {
      type: Boolean,
      default: () => false
    },
    editing_initial_settings_data: {
      type: Object,
      default: () => {}
    },
    fillscreen: {
      type: Boolean,
      default: () => false
    },
    useShowDimensions: {
      type: Boolean,
      default: () => false
    },
    path: {
      type: String,
      default: () => "/shows/01/"
    },
    dataFile: {
      type: String,
      default: () => "soundslide.xml"
    },
    soloPlayer: {
      // soloPlayer is when the player is the only thing on the page.
      type: Boolean,
      default: () => false
    },
    reportData: {
      // reportData when you want views recorded online.
      type: Boolean,
      default: () => false
    },
    reportDataId: {
      // reportData when you want views recorded online.
      type: String,
      default: () => ""
    },
    force_preload: {
      type: Boolean,
      default: () => false
    },
    disable_fullscreen: {
      type: Boolean,
      default: () => false
    },
    always_show_controls: {
      type: Boolean,
      default: () => false
    },
    captureAndEmitClick: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      $bigbutton: null,
      $captions: null,
      $controls_background: null,
      $controls: null,
      $title: null,
      audioDuration: 0,
      audioDurationOverrideFadeOutInProgress: false,
      audioPathFromJson: null,
      backgroundColor: "#000",
      browser: null,
      buffering: false,
      bufferingResumePlay: false,
      bufferingInt: null,
      captionsTween: null,
      captureTooltipPosition: {},
      componentRemoved: false,
      controlFadeDelayMs: 3000,
      controlFadeTimeout: null,
      controlsAutoHide: true,
      controlsBgTween: null,
      controlsReady: false,
      controlsTween: null,
      currentAudioTime: 0,
      currentSlide: {},
      currentSlideIndex: 0,
      currentTimelineTime: 0,
      displayTitle: false,
      displaySettings: {
        aspectRatio: 3 / 2,
        imageBackgroundColor: "#000",
        globalBackgroundColor: "#111",
        globalTransitionTime: 1,
        globalTransitionType: "crossfade",
        audioDurationOverride: 0,
        audioDurationOverrideFadeSeconds: 0,
        customWidth: null,
        customHeight: null,
        title: "",
        showCaptions: false,
        autoShowCaptions: false,
        showTitle: false
      },
      fake_audio_source_url: null,
      firstImageLoaded: false,
      forceFillscreen: false,
      fullscreenFillscreen: false,
      hasCaptions: false,
      hasFullscreen: true,
      imageDirectoryLargest: null,
      imageSize: 1600,
      isMobile: false,
      isPaused: true,
      leaveControlsVisible: false,
      metaDict: {},
      mp3Filename: "audio_hi.mp3",
      overlayArray: [],
      parentHeight: 0,
      parentWidth: 0,
      plyr_height: 50,
      preloadAllImages: false,
      preloadedImagesIndex: [], // Store the index of loaded images.
      preloadImageNumber: 3, // lookahead and preload,
      privatePassword: "",
      privatePasswordFailed: false,
      passwordSubmitInProgress: false,
      showImageCaptions: false,
      showPasswordInterface: false,
      slideArray: [],
      slideArrayHasVideo: false,
      timeDeltaAccuracySecs: 0.125, // Below this threshold, the GSAP timeline is corrected.
      timeDeltaWatcherInt: null,
      timeDeltaWatcherIntValueMS: 100, // Dynamically set based on if has video or not.
      timeline: null,
      timeLinkedComponents: ["video_displays"],
      version_number: 0,
      videoMuted: false,
      waitingForAudio: false,
      windowHeight: 0,
      windowWidth: 0,
      xmlFilename: "soundslide.xml"
    };
  },
  computed: {
    computed_width() {
      if (this.forceFillscreen || this.fullscreenFillscreen) {
        return Math.min(
          this.windowWidth,
          this.displaySettings.customWidth || this.defined_width
        );
      }
      if (this.defined_width) {
        return this.defined_width;
      }
      if (this.fillscreen) {
        return this.windowWidth;
      }
      return this.parentWidth;
    },
    computed_height() {
      if (this.forceFillscreen || this.fullscreenFillscreen) {
        return Math.min(
          this.windowHeight,
          this.displaySettings.customHeight || this.defined_height
        );
      }
      if (this.defined_height) {
        return this.defined_height;
      }
      if (this.fillscreen) {
        return this.windowHeight;
      }
      // Size of parent div.
      return this.parentHeight;
    },
    defined_width() {
      if (this.width) {
        return this.width;
      }
      if (this.useShowDimensions || this.fullscreenFillscreen) {
        if (this.displaySettings.customWidth != null) {
          return this.displaySettings.customWidth;
        }
        // Let's try some new smart sizing.
        var width = this.windowHeight * this.displaySettings.aspectRatio;
        if (width > this.windowWidth) {
          return Math.min(this.windowWidth, 1600);
        }
        return Math.min(Math.floor(width), 1600);
      }
      return null;
    },
    defined_height() {
      if (this.height) {
        return this.height;
      }
      if (this.useShowDimensions || this.fullscreenFillscreen) {
        if (this.displaySettings.customHeight != null) {
          return this.displaySettings.customHeight;
        }
        // Basic widths.
        // Let's try some new smart sizing.
        var height =
          this.computed_width * (1 / this.displaySettings.aspectRatio);
        return Math.floor(height);
      }
      return null;
    },
    containers_style() {
      var _style = {
        width: this.computed_width + "px",
        height: this.computed_height + "px"
      };
      if (this.isFullscreen()) {
        _style.left = (this.windowWidth - this.computed_width) / 2 + "px";
        _style.top = (this.windowHeight - this.computed_height) / 2 + "px";
        _style.backgroundColor = this.displaySettings.globalBackgroundColor;
      }

      //console.log(_style);
      return _style;
    },
    outer_containers_style() {
      var _style = {
        position: "absolute",
        width: this.computed_width + "px",
        height: this.computed_height + "px"
      };
      if (this.isFullscreen()) {
        _style.width = "100%";
        _style.height = "100%";
        _style.left = "0px !important";
        _style.top = "0px !important";
        _style.transform = "none !important";
        _style.backgroundColor = this.displaySettings.globalBackgroundColor;
      }
      if (this.captureAndEmitClick) {
        _style.cursor = "crosshair";
      }

      //console.log(_style);
      return _style;
    },
    captureTooltipStyle() {
      var _style = {
        position: "absolute",
        left: this.captureTooltipPosition.x + "px",
        top: this.captureTooltipPosition.y + "px"
      };
      return _style;
    },
    slide_area_style() {
      var _style = {
        width: this.computed_width + "px",
        height: this.computed_height + "px"
      };
      if (!this.controlsAutoHide) {
        _style.height = this.computed_height - this.plyr_height + "px";
      }
      return _style;
    },
    audio_src() {
      if (this.fake_audio_source_url) {
        return this.fake_audio_source_url;
      }
      if (this.editing_audio_source_url) {
        return this.editing_audio_source_url;
      } else if (this.audioPathFromJson) {
        // Defined only in JSON.
        return this.audioPathFromJson;
      } else {
        return this.path + this.mp3Filename;
      }
    },
    has_external_audio() {
      return this.audio_source !== null;
    },
    audio_ref() {
      if (this.audio_source) {
        return this.audio_source;
      }
      return this.$refs["audio-" + this._uid].player;
    },
    version() {
      // This value will force Vue to redraw the timeline's div
      // when version_number is changed.
      // This only happens when 'setSlideData' is called from
      // the editor.
      return "version" + this.version_number;
    },
    active_caption() {
      if (this.currentSlide) {
        return this.currentSlide.caption;
      } else {
        return "";
      }
    },
    title() {
      return this.displaySettings.title;
    },
    transitionTime() {
      // The actual image tween-fade.
      return this.displaySettings.globalTransitionTime;
    },
    transitionType() {
      // The actual image tween-fade.
      return this.displaySettings.globalTransitionType;
    },
    audioDurationOverride() {
      return this.displaySettings.audioDurationOverride || 0;
    },
    audioDurationOverrideFadeSeconds() {
      return this.displaySettings.audioDurationOverrideFadeSeconds || 0;
    },
    lowerthirdArray() {
      // All overlays of "lowerthirds" or undefined type.
      return this.overlayArray.filter(o => {
        if (!("type" in o)) {
          return true;
        } else {
          return o.type === "lowerthird";
        }
      });
    },
    showBigButton() {
      var start_button_overlay = find(this.overlayArray, {
        type: "start_button"
      });
      if (start_button_overlay) {
        return start_button_overlay.enabled;
      } else {
        return true;
      }
    }
  },
  watch: {
    editable_overlays(value, old_value) {
      if (old_value === true && value === false) {
        // Switch from within Services to non-editing.
        // Update the local overlayArray to editing data.
        this.overlayArray = this.editing_initial_overlay_data;
      }
    },
    currentSlide(value, oldValue) {
      if (value != oldValue) {
        // Find index and emit out to parent.
        // Emit ONLY on change.
        var slideIndex = findIndex(this.slideArray, value);
        this.$emit("onSlideChange", slideIndex);
        if (
          !this.preloadedImagesIndex.includes(slideIndex) &&
          slideIndex != 0
        ) {
          console.info("Image not preloaded!");
          if (!this.waitingForAudio) {
            console.log("Pre-buffering state: ", this.audio_ref.paused);
            this.bufferingResumePlay = !this.audio_ref.paused;
            this.audio_ref.pause();
          }
          clearInterval(this.bufferingInt);
          this.buffering = true;
          console.log("Image not loaded. Buffer!");
          this.bufferingInt = setInterval(() => {
            if (this.preloadedImagesIndex.includes(this.currentSlideIndex)) {
              console.log("Image loaded. Not buffer!");
              if (this.bufferingResumePlay) {
                this.audio_ref.play();
              }
              this.buffering = false;
              clearInterval(this.bufferingInt);
            }
          }, 500);
        }
      }
    },
    always_show_controls(value) {
      if (value) {
        this.controlsAutoHide = false;
        this.leaveControlsVisible = true;
        this.$container.onmousemove = () => {};
        this.$container.onclick = () => {};
        this.mouseReset();
      } else {
        // Reset the entire hiding controls items.
        this.controlsAutoHide = true;
        this.leaveControlsVisible = false;
        this.$container.onmousemove = this.mouseReset;
        this.$container.onclick = this.mouseReset;
      }
    }
  },
  mounted() {
    // Set bowser.
    this.browser = Bowser.getParser(window.navigator.userAgent);
    this.isMobile = this.browser.getPlatform().type == "mobile";
    // Load in XML and parse.
    if (!this.editing_initial_slide_data) {
      // Load external data file.
      this.loadShowData();
    } else {
      // Direct load from editing_* files.
      this.slideArray = returnSlideArrayWithMovementData(
        this.editing_initial_slide_data
      );
      // slideArray could come in blank, if so, render a blank image for player.
      if (this.slideArray.length == 0) {
        // Add empty image.
        this.slideArray = [
          {
            file: "null_image_placeholder",
            name: "none.jpg",
            timing: 0,
            caption: ""
          }
        ];
      }
      if (this.editing_audio_duration) {
        this.audioDuration = this.editing_audio_duration;
      }
      this.overlayArray = this.editing_initial_overlay_data;
      this.mergeDisplaySettings(this.editing_initial_settings_data);
      this.metaDict = {};
      this.imageDirectoryLargest = "";
      this.loadPlayer();
    }
    if (this.force_preload) {
      console.log("Preload all images.");
      this.preloadAllImages = true;
    }
    if (this.always_show_controls) {
      this.controlsAutoHide = false;
    }
    this.$emit("onMounted");
  },
  beforeDestroy() {
    console.log("beforeDestroy");
    this.componentRemoved = true; // Helps with self-cleanup of some subborn intervals.
    // Remove the events and destroy Plyr listeners.
    clearInterval(this.timeDeltaWatcherInt);
    clearInterval(this.bufferingInt);
    if (this.audio_source === null) {
      each(["timeupdate", "pause", "play", "seeking", "seeked"], ev => {
        this.audio_ref.off(ev);
      });
      this.audio_ref.destroy();
    }
  },
  methods: {
    loadShowData() {
      var _v = this;
      console.log("path: ", this.path);
      console.log("dataFile: ", this.dataFile);
      if (this.dataFile === "soundslide.xml") {
        // XML, then TXT if XML is 404.
        // We attempt to load in xml here. If xml is missing, we attempt to load txt.
        axios
          .get(this.path + this.dataFile)
          .then(function(response) {
            _v.initializeDataFromResponseXml(response);
          })
          .catch(function(error) {
            // handle error
            console.log(error.response.status);
            // Try to load the txt file.
            axios
              .get(_v.path + "soundslide.txt")
              .then(function(response) {
                _v.initializeDataFromResponseTxt(response);
              })
              .catch(function(error) {
                // handle error
                console.log(error);
                // Actual error.
              });
          });
      } else {
        // JSON
        // We attempt to load in json here.
        // This is the preferred format for the future.
        //
        // Note: The json may return properly,
        // or you may get a password protected version.
        axios
          .get(this.path + this.dataFile)
          .then(function(response) {
            console.log(response.data);
            if (
              "privacySelected" in response.data &&
              response.data.privacySelected === "password"
            ) {
              console.log("password");
              _v.showPasswordUI();
            } else {
              _v.initializeDataFromResponseJson(response);
            }
          })
          .catch(function(error) {
            // handle error
            console.log(error);
            console.log(error.response.status);
          });
      }
    },
    showPasswordUI() {
      var _v = this;
      _v.setDimensionValues();
      _v.showPasswordInterface = true;
      setTimeout(() => {
        console.log(_v.$refs);
        _v.$refs["privatePassword"].focus();
      }, 400);
    },
    createFakeAudioSource(seconds) {
      console.log("createFakeAudioSource", seconds);
      this.fake_audio_source_url = returnEmptyWaveBlob(seconds);
    },
    submitPassword() {
      console.log("submitPassword");
      var passwordEndpoint = "https://upload.soundslides.org/private/";
      // passwordEndpoint = "http://127.0.0.1:5000/private/";
      // passwordEndpoint =
      //   "https://kkk1fngeo0.execute-api.us-east-1.amazonaws.com/stage/private/";
      var _v = this;
      _v.passwordSubmitInProgress = true;
      axios
        .post(passwordEndpoint, {
          id: this.reportDataId,
          pass: this.privatePassword
        })
        .then(function(response) {
          console.log(response);
          if (response.data.success == false) {
            console.log("Error");
            _v.privatePasswordFailed = true;
            _v.passwordSubmitInProgress = false;
          } else {
            _v.showPasswordInterface = false;
            _v.initializeDataFromResponseJson(response);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    importMetaDictToDisplaySettings() {
      // The metaDict is legacy. The displaySettings is the future.
      // We need to cherry-pick the meta variables for inclusion into displaySettings.
      function toBoolean(v) {
        return v === "true";
      }
      function toInt(v) {
        return parseInt(v, 10);
      }
      function toFloat(v) {
        return parseFloat(v);
      }
      // This is where metaDict items are mapped into the main displaySettings.
      var inclusionMapArray = [
        {
          legacyName: "transition_time",
          newName: "globalTransitionTime",
          transform: toFloat
        },
        { legacyName: "transition_type", newName: "globalTransitionType" },
        {
          legacyName: "body_hex",
          newName: "globalBackgroundColor",
          transform: v => {
            return "#" + v;
          }
        },
        {
          legacyName: "custom_width",
          newName: "customWidth",
          transform: toInt
        },
        {
          legacyName: "custom_height",
          newName: "customHeight",
          transform: toInt
        },
        { legacyName: "header_headline", newName: "title" },
        {
          legacyName: "showCaptions",
          newName: "showCaptions",
          transform: toBoolean
        },
        {
          legacyName: "autoShowCaptions",
          newName: "autoShowCaptions",
          transform: toBoolean
        },
        {
          legacyName: "silent_slide_show",
          newName: "fakeAudioPlayback",
          transform: toBoolean
        },
        {
          legacyName: "seconds_per_image",
          newName: "fakeAudioPlaybackSecsPerImage",
          transform: toFloat
        },
        {
          legacyName: "slide_only",
          newName: "noAudio",
          transform: toBoolean
        },
        {
          legacyName: "display_header",
          newName: "showTitle",
          transform: toBoolean
        }
      ];
      each(inclusionMapArray, i => {
        if (i.legacyName in this.metaDict) {
          if ("transform" in i) {
            this.displaySettings[i.newName] = i.transform(
              this.metaDict[i.legacyName]
            );
          } else {
            this.displaySettings[i.newName] = this.metaDict[i.legacyName];
          }
        }
      });
      // Add customWidth and customHeight if undefined and fullscreen is set.
      if (
        !this.displaySettings.customWidth &&
        this.metaDict.fullscreen == "true"
      ) {
        this.displaySettings.customWidth = 1000;
        this.displaySettings.customHeight = 667;
      }
      console.log("displaySettings", this.displaySettings);
    },
    initializeDataFromResponseXml(response) {
      this.slideArray = returnSlideArrayWithMovementData(
        returnSlideArrayFromXml(response.data)
      );
      this.overlayArray = returnLowerthirdsArrayFromXml(response.data);
      this.metaDict = returnShowMetaDataFromXml(response.data);
      this.importMetaDictToDisplaySettings();
      this.imageDirectoryLargest = returnImageDirectoryFromMeta(this.metaDict);
      this.loadPlayer();
    },
    initializeDataFromResponseTxt(response) {
      this.slideArray = returnSlideArrayWithMovementData(
        returnSlideArrayFromTxt(response.data)
      );
      this.metaDict = returnShowMetaDataFromTxt(response.data);
      // If the project is ancient, it might not have a width and height. Add it here.
      if (!("custom_width" in this.metaDict)) {
        // Note, the original was 600x450, that was an odd aspect ratio.
        // We're going to reset to 600x400 which matches the original aspect ratio (1.5).
        this.metaDict.custom_width = "600";
        this.metaDict.custom_height = "400";
      }
      this.importMetaDictToDisplaySettings();
      this.overlayArray = returnLowerthirdsArrayFromTxt(response.data);
      this.imageDirectoryLargest = returnImageDirectoryFromMeta(this.metaDict);
      this.loadPlayer();
    },
    initializeDataFromResponseJson(response) {
      console.log(response);
      this.slideArray = returnSlideArrayWithMovementData(
        response.data.slideArray
      );
      /*
      // Add a modification for a video slide.
      this.slideArray[1].type = "video";
      this.slideArray[1].source_url =
        "https://cdn3.soundslides.com/video/RFhn9RVP/1572727820211_beantalk.mov.mp4";
      this.slideArray[1].source_aspect_ratio = 1.778;
      this.slideArray[1].duration = 4.318;
      this.slideArray[1].inpoint = this.slideArray[1].timing / 1000;
      this.slideArray[1].main_volume = 0.3;
      this.slideArray[1].slide_volume = 1;
      */
      if ("displaySettings" in response.data) {
        this.mergeDisplaySettings(response.data.displaySettings);
      }
      if ("lowerthirdsArray" in response.data) {
        this.overlayArray = response.data.lowerthirdsArray;
      }
      if ("overlayArray" in response.data) {
        this.overlayArray = response.data.overlayArray;
      }
      this.imageDirectoryLargest = ""; // Has to be set to something.

      // Deal with audio (or lack thereof)
      if (this.displaySettings.noAudio) {
        console.log("noAudio");
      } else {
        var audioRoot = "https://cdn3.soundslides.com/audio";
        var audio = response.data.audioFile;
        this.audioPathFromJson = `${audioRoot}/${audio.source_id}/${audio.filename}`;
        this.audioDuration = response.data.audioFile.duration || 0;
      }

      var imageSize =
        response.data.imageSizes[response.data.imageSizes.length - 1];
      // Use smaller size image for mobile.
      var platform = this.browser.getPlatform().type;

      console.log("platform", this.browser.getPlatform());

      if (platform === "mobile") {
        imageSize = response.data.imageSizes[1]; // Middle size (800px)
      }
      this.imageSize = imageSize;
      var os = this.browser.getOS().name;
      console.log("os", this.browser.getOS());
      if (os === "iOS") {
        this.videoMuted = true;
      }
      this.loadPlayer();
    },
    onResize() {
      console.log("onResize");
    },
    loadPlayer() {
      var _v = this;
      _v.hasCaptions = _v.displaySettings.showCaptions;
      // Check for fullscreen capabilities.
      _v.hasFullscreen =
        document.fullscreenEnabled == true ||
        document.webkitFullscreenEnabled == true;
      // Override fullscreen if user disabled.
      if (this.disable_fullscreen) {
        _v.hasFullscreen = false;
      }
      // Set autoShowCaptions before controlsReady.
      console.log(
        "_v.displaySettings.showCaptions",
        _v.displaySettings.showCaptions
      );
      console.log(
        "_v.displaySettings.autoShowCaptions",
        _v.displaySettings.autoShowCaptions
      );
      _v.checkUserForTrialOverlay();
      if (
        _v.displaySettings.showCaptions &&
        _v.displaySettings.autoShowCaptions
      ) {
        _v.showImageCaptions = true;
      }

      if (_v.displaySettings.globalSlideBackgroundColor) {
        _v.backgroundColor = _v.displaySettings.globalSlideBackgroundColor;
      }

      // Make sure overlayArray has layer data.
      this.overlayArray = map(this.overlayArray, o => {
        if (isUndefined(o.layer)) {
          o.layer = 3;
        }
        return o;
      });
      _v.controlsReady = true;
      _v.displayTitle = this.displaySettings.showTitle;
      _v.currentSlide = _v.slideArray[0];
      // Handle click and silent legacy slide shows by creating fake silent audio file.
      if (_v.displaySettings.noAudio) {
        // No real audio, make fake audio.
        var secsPerImage =
          _v.displaySettings.fakeAudioPlaybackSecsPerImage || 4;
        _v.createFakeAudioSource(secsPerImage * _v.slideArray.length);
        // Update the actual slideArray's timing to the new noAudio setting.
        each(_v.slideArray, (s, idx) => {
          s.timing = idx * secsPerImage * 1000;
        });
      }
      setTimeout(() => {
        _v.renderTimeline();
        _v.uiSetup();
        _v.setPageAttributes();
        if (_v.reportData) {
          _v.reportZeroCount();
        }
        _v.$nextTick(() => {
          window.addEventListener("resize", () => {
            console.log("resize");
            _v.setDimensionValues();
          });
          _v.$el.addEventListener("resize", () => {
            console.log("resize");
            _v.setDimensionValues();
          });
          // Set initial.
          _v.setDimensionValues();
          _v.setupAudioListeners();
        });
      }, 100);
    },
    uiSetup() {
      // Background.
      // Controls, fade, etc.
      this.$controls = this.$el.querySelector(".controls");
      this.$controls_background = this.$el.querySelector(
        ".controls_background"
      );
      this.$captions = this.$el.querySelector(".captions");
      this.$bigbutton = this.$el.querySelector(".bigbutton");
      this.$title = this.$el.querySelector(".title");
      this.$container = this.$el;
      // If controlsAutoHide, add fadein fadeouts.
      if (this.controlsAutoHide && !this.has_external_audio) {
        this.$container.onmousemove = this.mouseReset;
        this.$container.onclick = this.mouseReset;
      }
    },
    imageLoaded(idx) {
      //console.log("imageLoaded: " + idx);
      this.preloadedImagesIndex.push(idx);
      this.preloadedImagesIndex.sort();
      if (idx === 0) {
        this.firstImageLoaded = true;
      }
    },
    checkUserForTrialOverlay() {
      // Registered user check.
      /*
      var _v = this;
      if ("trialUser" in _v.displaySettings) {
        console.log("displaySettings", _v.displaySettings);
        var trial_text = "Made with Soundslides 3 - Trial Evaluation Account";
        if (_v.displaySettings.trialUser) {
          _v.overlayArray.push({
            background_alpha: 0,
            duration: 5,
            editingTemplate: "no_editing_template_provided",
            id: "trail_watermark_id_1",
            inpoint: 1,
            line1: trial_text,
            line1_align: "center",
            line1_font_family: "Lato",
            line1_font_size_em: 1.4,
            line2: "",
            line2_align: "center",
            line2_font_family: "Lato",
            line2_font_size_em: 3,
            line3: "",
            line3_align: "center",
            line3_font_family: "Lato",
            line3_font_size_em: 3,
            text_background_alpha: 0.3,
            transition_seconds: 1,
            type: "trial_watermark"
          });
          if (_v.slideArray.length > 3 && _v.slideArray.slice(-1)[0]) {
            var lastImageInPoint = _v.slideArray.slice(-1)[0].timing / 1000;
            _v.overlayArray.push({
              background_alpha: 0,
              duration: 7,
              editingTemplate: "no_editing_template_provided",
              id: "trail_watermark_id_2",
              inpoint: Math.max(11, lastImageInPoint - 10),
              line1: trial_text,
              line1_align: "center",
              line1_font_family: "Lato",
              line1_font_size_em: 1.4,
              line2: "",
              line2_align: "center",
              line2_font_family: "Lato",
              line2_font_size_em: 1.4,
              line3: "",
              line3_align: "center",
              line3_font_family: "Lato",
              line3_font_size_em: 3,
              text_background_alpha: 0.3,
              transition_seconds: 1,
              type: "trial_watermark"
            });
          }
        }
      }
      */
      // End user check.
    },
    goto_and_pause_from_interactive_click(time) {
      if (!time) {
        time = this.audio_ref.currentTime;
      }
      this.leaveControlsVisible = true;
      this.gotoAndPause(time);
      this.mouseReset();
    },
    mouseReset() {
      // One mouse move, fade in immediately.
      // Then start the clock on fading out.
      // Fade out of controls begins after controlFadeDelayMs delay.
      console.log("mouseReset");
      var fadeSecs = 0.4;
      clearTimeout(this.controlFadeTimeout);
      // Tween in the controls and control BG.
      // Take care to only have the two single tweens running at once.
      var _v = this;
      if (this.controlsTween == null) {
        this.controlsTween = TweenLite.to(this.$controls, fadeSecs, {
          y: 0,
          ease: Power2.easeInOut,
          force3D: true,
          onComplete: () => {
            _v.controlsTween = null;
          },
          callbackScope: this
        });
      }
      // Set captions reference as it may not exist when player is initialized.
      if (!this.$captions) {
        this.$captions = this.$el.querySelector(".captions");
      }
      if (this.captionsTween == null && this.$captions) {
        this.captionsTween = TweenLite.to(this.$captions, fadeSecs, {
          y: 10,
          ease: Power2.easeInOut,
          force3D: true,
          onComplete: () => {
            _v.captionsTween = null;
          },
          callbackScope: this
        });
      }
      if (this.controlsBgTween == null) {
        this.controlsBgTween = TweenLite.to(
          this.$controls_background,
          fadeSecs,
          {
            y: 0,
            ease: Power2.easeInOut,
            force3D: true,
            onComplete: () => {
              _v.controlsBgTween = null;
            },
            callbackScope: this
          }
        );
      }
      // Clocked fade out.
      clearTimeout(this.controlFadeTimeout);
      if (!this.leaveControlsVisible) {
        this.controlFadeTimeout = setTimeout(
          this.controlsTweenOut,
          this.controlFadeDelayMs
        );
      }
    },
    controlsTweenOut() {
      console.log("Tween controls out.");
      var fadeSecs = 0.4;
      // Tween out the controls (down)
      TweenLite.to(this.$controls, fadeSecs, {
        y: 100,
        ease: Power2.easeInOut,
        force3D: true
      });
      // Set captions reference as it may not exist when player is initialized.
      if (!this.$captions) {
        this.$captions = this.$el.querySelector(".captions");
      }
      if (this.$captions) {
        TweenLite.to(this.$captions, fadeSecs, {
          y: 50,
          ease: Power2.easeInOut,
          force3D: true
        });
      }
      TweenLite.to(this.$controls_background, fadeSecs, {
        y: 100,
        ease: Power2.easeInOut,
        force3D: true
      });
    },
    preloadImage(image_index) {
      // This can be WAY more intelligent, but will suffice for now.
      if (this.preloadAllImages) {
        return true;
      }
      // It loads `preloadImageNumber` ahead of the currentSlideIndex.
      var load_ahead_index = this.currentSlideIndex + this.preloadImageNumber;
      var load_back_number = this.currentSlideIndex - 1;
      if (load_back_number < image_index && image_index < load_ahead_index) {
        return true;
      } else {
        return false;
      }
    },
    setDimensionValues() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      if (this.$el.parentElement != null) {
        // parentElement not always available.
        this.parentWidth = this.$el.parentElement.clientWidth;
        this.parentHeight = this.$el.parentElement.clientHeight;
      }
      // If the screen size is smaller than computed_width/computed_height,
      // Force fillscreen.
      //console.log(
      //  "this.parentWidth",
      //  this.parentWidth,
      //  "this.defined_width",
      //  this.defined_width
      //);
      if (
        this.parentWidth < this.defined_width ||
        this.parentHeight < this.defined_height
      ) {
        this.forceFillscreen = true;
      } else {
        this.forceFillscreen = false;
      }
      this.forceFillscreen = false;
    },
    setupAudioListeners() {
      var audioEl = this.audio_ref;
      if (this.audio_source) {
        audioEl.addEventListener("timeupdate", this.timeUpdate, true);
        audioEl.addEventListener("pause", this.pause, true);
        audioEl.addEventListener("play", this.play, true);
        audioEl.addEventListener("seeking", this.seeking, true);
        audioEl.addEventListener("seeked", this.seeked, true);
        audioEl.addEventListener("waiting", this.waiting, true);
        audioEl.addEventListener("canplay", this.canplay, true);
      } else {
        // Listeners for plyr.
        audioEl.on("timeupdate", this.timeUpdate);
        audioEl.on("pause", this.pause);
        audioEl.on("play", this.play);
        audioEl.on("seeking", this.seeking);
        audioEl.on("seeked", this.seeked);
        audioEl.on("waiting", this.waiting);
        audioEl.on("canplay", this.canplay);
      }
      var _v = this;
      audioEl.onloadedmetadata = function() {
        _v.audioDuration = audioEl.duration;
      };
    },
    returnImagePath(image) {
      var filename = image.file;
      if (!isEmpty(this.blobUrls)) {
        // console.log("blobbed image!");
        // This is a blobbed show, use the blob for now.
        // console.log(filename, this.blobUrls[filename]);
        if (filename in this.blobUrls) {
          return this.blobUrls[filename];
        } else {
          console.warn(`${filename} was expected in blobUrls, but is missing.`);
        }
      }
      if (filename == "null_image_placeholder") {
        return "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
      }
      // Look for a blob, and use that if available.

      if (image.source_id != null) {
        // New shows have source_id.
        var imageRoot = "https://cdn3.soundslides.com/images";
        return `${imageRoot}/${image.source_id}/${this.imageSize}/${image.file}`;
      } else {
        // Legacy hosting shows are in subdirectories.
        return this.path + this.imageDirectoryLargest + "/" + filename;
      }
    },
    pauseAudio() {
      // This will bubble to the listeners and take care of all.
      // Dp not use ".pause()" directly.
      var audioEl = this.audio_ref;
      audioEl.pause();
    },
    playAudio() {
      // This will bubble to the listeners and take care of all.
      // Dp not use ".pause()" directly.
      var audioEl = this.audio_ref;
      audioEl.play();
    },
    outerMouseDown($evt) {
      if (this.captureAndEmitClick) {
        if ($evt.target.classList.contains("plyr__control")) {
          console.log("outerMouseDown: controls!");
          return;
        }
        var xOffset = this.$container.getBoundingClientRect().left;
        var yOffset = this.$container.getBoundingClientRect().top;
        this.$emit("mouseDownCaptured", {
          x: round((($evt.clientX - xOffset) / this.computed_width) * 100, 2),
          y: round((($evt.clientY - yOffset) / this.computed_height) * 100, 2),
          evt: $evt
        });
        $evt.stopPropagation();
      }
    },
    outerClick($evt) {
      if (this.captureAndEmitClick) {
        if ($evt.target.classList.contains("plyr__control")) {
          console.log("clickCaptured: controls!");
          return;
        }
        var xOffset = this.$container.getBoundingClientRect().left;
        var yOffset = this.$container.getBoundingClientRect().top;
        this.$emit("clickCaptured", {
          x: round((($evt.clientX - xOffset) / this.computed_width) * 100, 2),
          y: round((($evt.clientY - yOffset) / this.computed_height) * 100, 2),
          evt: $evt
        });
        $evt.stopPropagation();
      }
    },
    outerMove($evt) {
      if (this.captureAndEmitClick) {
        var xOffset = this.$container.getBoundingClientRect().left;
        var yOffset = this.$container.getBoundingClientRect().top;
        this.captureTooltipPosition = {
          x: round($evt.clientX - xOffset + 10, 2),
          y: round($evt.clientY - yOffset - 40, 2)
        };
      }
    },
    playToggle() {
      console.log("playToggle");
      if (this.captureAndEmitClick) {
        return;
      }
      // Look for audio duration cut.
      var audioEl = this.audio_ref;
      if (audioEl.paused) {
        if (this.audioDurationOverride != 0) {
          // Custom audio duration.
          if (this.audio_ref.currentTime == this.audioDurationOverride) {
            // Replay!
            this.gotoAndPlay(0);
            return;
          }
        }
        audioEl.play();
      } else {
        audioEl.pause();
      }
    },
    handleFullscreenChange() {
      // Handles all UI and image sizing issues related to Fullscreen.
      // Triggered by onfullscreenchange listener.
      console.info("handleFullscreenChange");
      if (this.isFullscreen()) {
        this.fullscreenFillscreen = true;
      } else {
        // No fullscreen.
        this.fullscreenFillscreen = false;
      }
    },
    isFullscreen() {
      return (
        document.fullscreenElement || document.webkitCurrentFullScreenElement
      );
    },
    toggleFullscreen() {
      console.log("toggleFullscreen()");
      if (this.isFullscreen()) {
        if (!document.exitFullscreen) {
          // Safari.
          document.webkitExitFullscreen();
        } else {
          // Everything else.
          document.exitFullscreen();
        }
        //document.body.style.background = "green";
      } else {
        var $el = this.$el;
        if (!$el.requestFullscreen) {
          // Safari.
          $el.onwebkitfullscreenchange = this.handleFullscreenChange;
          $el.webkitRequestFullScreen();
        } else {
          // Everything else.
          $el.onfullscreenchange = this.handleFullscreenChange;
          $el.requestFullscreen();
        }
        //document.body.style.background = "pink";
      }
    },
    toggleCaptions() {
      console.log("toggleCaptions");
      this.showImageCaptions = !this.showImageCaptions;
    },
    gotoAndPlay(sec) {
      console.log("gotoAndPlay -->", sec);
      var audioEl = this.audio_ref;
      audioEl.currentTime = sec;
      audioEl.play();
      this.timeline.play(this.audio_ref.currentTime);
    },
    gotoAndPause(sec) {
      console.log("gotoAndPause -->", sec);
      var audioEl = this.audio_ref;
      audioEl.currentTime = sec;
      audioEl.pause();
      this.timeline.pause(this.audio_ref.currentTime);
    },
    nextImage() {
      var nextImageIndex = this.returnCurrentSlideIndex() + 1;
      if (nextImageIndex >= this.slideArray.length) {
        // Don't go past end.
        return;
      }
      var post_transition_sec = 1; // Need to jump past the tweens.
      this.gotoAndPause(
        this.slideArray[nextImageIndex].timing / 1000 + post_transition_sec
      );
    },
    previousImage() {
      var previousImageIndex = this.returnCurrentSlideIndex() - 1;
      console.log("previousImageIndex", previousImageIndex);
      if (previousImageIndex < 0) {
        // Don't go negative.
        return;
      }
      var post_transition_sec = 1; // Need to jump past the tweens.
      this.gotoAndPause(
        this.slideArray[previousImageIndex].timing / 1000 + post_transition_sec
      );
    },
    checkForCustomAudioFades(_currentTime) {
      // We handle custom fade out here.
      // You can add custom fade ins later.
      if (
        this.audioDurationOverride == 0 ||
        this.audioDurationOverrideFadeSeconds == 0
      ) {
        // Check for audioDurationOverrideFadeOutInProgress just in case it was edited.
        if (this.audioDurationOverrideFadeOutInProgress) {
          this.audio_ref.volume = 1;
          this.audioDurationOverrideFadeOutInProgress = false;
        }

        return;
      }
      var limitedDurationFadeOutSeconds = this.audioDurationOverrideFadeSeconds;
      if (
        this.audioDurationOverride - _currentTime <
        limitedDurationFadeOutSeconds
      ) {
        console.log("Fake fade out");
        this.audioDurationOverrideFadeOutInProgress = true;
        this.audio_ref.volume =
          (this.audioDurationOverride - _currentTime) /
          limitedDurationFadeOutSeconds;
      } else {
        // Restore the full volume, but only once!
        if (this.audioDurationOverrideFadeOutInProgress) {
          console.log("Full volume only once!");
          this.audio_ref.volume = 1;
          this.audioDurationOverrideFadeOutInProgress = false;
        }
      }
    },
    checkForLimitedDuration(_currentTime) {
      // Here we handle fading out the duration early and pausing if necessary.
      //console.log("_currentTime", _currentTime);
      if (this.audioDurationOverride == 0) {
        return;
      }
      if (_currentTime > this.audioDurationOverride) {
        this.gotoAndPause(this.audioDurationOverride);
      }
    },
    timeUpdate() {
      this.currentSlide = this.returnCurrentSlide();
      this.currentTimelineTime = this.timeline.time();
      var _currentTime = this.audio_ref.currentTime;
      //console.log("_currentTime", _currentTime);
      this.currentAudioTime = _currentTime;
      this.checkForLimitedDuration(_currentTime);
      this.checkForCustomAudioFades(_currentTime);
      this.$emit("onTimeUpdate", _currentTime);
      //this.timeline.play(this.audio_ref.currentTime);
      this.syncTimeLinkedComponents(_currentTime);
    },
    showInitialUI() {
      if (this.showBigButton) {
        TweenLite.to(this.$bigbutton, 0.5, { opacity: 1 });
        if (this.$title) {
          TweenLite.to(this.$title, 0.5, { opacity: 1 });
        }
      }
    },
    overlay_style(overlay) {
      var maxOverlayZIndex = 999;
      var minOverlayZIndex = maxOverlayZIndex - 20;
      var _style = cloneDeep(this.slide_area_style);
      var layer = overlay.layer;
      if (isUndefined(layer)) {
        layer = 0;
      }
      //console.log("layer", layer);
      _style["z-index"] = minOverlayZIndex + layer + " !important";
      return _style;
    },
    setPageAttributes() {
      // Title.
      if (this.title && this.title !== "undefined") {
        document.title = this.title;
      }
      // BG color.
      console.log("this.soloPlayer", this.soloPlayer);
      if (this.soloPlayer) {
        console.warn("backgroundColor");
        console.log(
          "this.displaySettings.globalBackgroundColor",
          this.displaySettings.globalBackgroundColor
        );
        document.body.style.backgroundColor = this.displaySettings.globalBackgroundColor;
      }
    },
    hideInitialUI() {
      TweenLite.to(this.$bigbutton, 0.5, { opacity: 0 });
      if (this.$title) {
        TweenLite.to(this.$title, 0.5, { opacity: 0 });
      }
    },
    pause() {
      // Called from audio event listeners.
      var _currentTime = this.audio_ref.currentTime;
      if (_currentTime < 0.1) {
        this.showInitialUI();
      }
      this.timeline.pause(_currentTime);
      this.syncTimeLinkedComponents();
      this.isPaused = true;
    },
    play() {
      console.log("play");
      // Called from audio event listeners.
      if (this.audioDurationOverride != 0) {
        // Custom audio duration.
        if (this.audio_ref.currentTime == this.audioDurationOverride) {
          // Replay!
          this.gotoAndPlay(0);
          return;
        }
      }
      this.leaveControlsVisible = false;
      this.hideInitialUI();
      this.timeline.play(this.audio_ref.currentTime);
      this.syncTimeLinkedComponents();
      this.isPaused = false;
    },
    waiting() {
      // Currently we're letting the plyr handle audio buffering.
      console.log("waiting for audio");
      this.waitingForAudio = true;
    },
    canplay() {
      // Currently we're letting the plyr handle audio buffering.
      console.log("canplay for audio");
      this.audio_ref.volume = 1; // Always start with full volume!
      this.waitingForAudio = false;
    },
    seeked() {
      console.log("seeked");
      // Check for initial UI.
      if (this.audio_ref.paused) {
        var _currentTime = this.audio_ref.currentTime;
        if (_currentTime < 0.1 && this.audio_ref.paused) {
          this.showInitialUI();
        } else {
          this.hideInitialUI();
        }
      }
      // Update timeline.
      this.timeline.play(this.audio_ref.currentTime);
      this.timeline.pause(this.audio_ref.currentTime);
    },
    seeking() {
      this.timeline.pause(this.audio_ref.currentTime);
    },
    syncTimeLinkedComponents(player_time) {
      // console.log("syncTimeLinkedComponents");
      if (!this.slideArrayHasVideo) {
        // No need to check if there's no video in the slideArray.
        // You'll need to add a check for video in overlays as well.
        return;
      }
      this.timeLinkedComponents.forEach(refName => {
        if (refName in this.$refs) {
          // Loop through all of the video_displays
          this.$refs[refName].forEach(comp => {
            comp.syncTime(player_time, this.audio_ref);
          });
        }
      });
    },
    checkSyncAudioAndTimeline() {
      // FYI, this is called from 'SoundslidesPlayer/timeline.js'.
      // console.log("checkSyncAudioAndTimeline");
      if (this.componentRemoved) {
        console.warn(this.timeDeltaWatcherInt);
        clearInterval(this.timeDeltaWatcherInt);
        return;
      }
      var player_time = this.audio_ref.currentTime;
      var timeline_time = this.timeline.time();
      var time_delta = timeline_time - player_time;
      var time_delta_abs = Math.abs(timeline_time - player_time);
      this.syncTimeLinkedComponents(player_time);
      //console.log("time_delta", time_delta);
      if (time_delta_abs > this.timeDeltaAccuracySecs) {
        // Correction required.
        // console.log("Time correction", time_delta_abs);
        var audioEl = this.audio_ref;
        if (audioEl.paused) {
          this.timeline.pause(this.audio_ref.currentTime);
        } else {
          var beginning_of_show_seconds = 10;
          var max_delta_for_partial_correction = 1;
          if (
            time_delta_abs > 0 &&
            time_delta_abs < max_delta_for_partial_correction &&
            timeline_time < beginning_of_show_seconds
          ) {
            // If within first 10 seconds, slowly resync if the timeline is off.
            // Slowly get it back in sync at the beginning of a show.
            if (time_delta > 0) {
              //console.log("ahead");
              this.timeline.play(timeline_time - time_delta_abs / 15);
            } else {
              //console.log("behind");
              this.timeline.play(timeline_time + time_delta_abs / 15);
            }
          } else {
            this.timeline.play(this.audio_ref.currentTime);
          }
        }
      }
      // Preload all check.
      if (player_time > this.preloadAllImagesTimeThresholdSec) {
        this.preloadAllImages = true;
      }
    },
    renderTimeline() {
      // Before each createTimeline, check to see if there are videos.
      var numVideosInSlideArray = filter(this.slideArray, { type: "video" })
        .length;

      var numMovementsInSlideArray = filter(this.slideArray, {
        has_motion: true
      }).length;

      // console.log(
      //   "createTimeline: numVideosInSlideArray",
      //   numVideosInSlideArray
      // );

      // console.log(
      //   "createTimeline: numMovementsInSlideArray",
      //   numMovementsInSlideArray
      // );

      // If just slides, check every 1000 ms.
      // If video, check every 100 ms.

      if (numVideosInSlideArray > 0) {
        this.slideArrayHasVideo = true;
        this.timeDeltaWatcherIntValueMS = 100;
      } else {
        this.slideArrayHasVideo = false;
        if (numMovementsInSlideArray == 0) {
          this.timeDeltaWatcherIntValueMS = 300;
        } else {
          // Has movement, use more timing checks.
          this.timeDeltaWatcherIntValueMS = 150;
        }
      }

      createTimeline(this);
    },
    returnCurrentSlideIndex() {
      // This is the source of this.currentSlideIndex.
      var currentTime = this.audio_ref.currentTime;
      var currentSlideIndex = findLastIndex(this.slideArray, s => {
        return s.timing / 1000 <= currentTime;
      });
      this.currentSlideIndex = currentSlideIndex;
      return currentSlideIndex;
    },
    returnCurrentSlide() {
      return this.slideArray[this.returnCurrentSlideIndex()];
    },
    returnSlideData() {
      // Send clone, not actual observable.
      return cloneDeep(this.slideArray);
    },
    mergeDisplaySettings(newSettings) {
      // Used to overwrite default settings with user-defined values.
      each(newSettings, (v, k) => {
        this.displaySettings[k] = v;
      });
    },
    setSlideData(_slideArray, _overlays, _settings) {
      console.log("setSlideData");
      var timelineUpdateNeeded = this.isTimelineUpdateNeeded(
        _slideArray,
        _overlays,
        _settings
      );

      // Always update the globalSlideBackgroundColor as it is special.
      if (_settings.globalSlideBackgroundColor) {
        this.backgroundColor = _settings.globalSlideBackgroundColor;
      }

      this.setSlideDataWithoutTimelineUpdate(_slideArray, _overlays, _settings);
      if (timelineUpdateNeeded) {
        // Need timeline rebuild!
        console.log("timeline update needed!!!!!");

        this.version_number++;
        var _v = this;
        this.$nextTick(() => {
          /*
          Hide all overlays as rebuilding at time > 0 will
          cause all overlays to briefly appear as visible.
          */
          var $overlays_div = _v.$el.querySelector(".overlays_all");
          $overlays_div.style.visibility = "hidden";
          this.renderTimeline();
          // After timeline is made, it is necessary to play it, THEN sync it.
          // console.log("timeline play!");
          setTimeout(function() {
            _v.timeline.play(_v.audio_ref.currentTime);
            _v.timeline.pause(_v.audio_ref.currentTime);
            _v.checkSyncAudioAndTimeline();
            /*
            Restore all overlays as post rebuilding.
            */
            $overlays_div.style.visibility = "visible";
          }, 50);
        });
      }
    },
    setSlideDataWithoutTimelineUpdate(_slideArray, _overlays, _settings) {
      this.slideArray = returnSlideArrayWithMovementData(
        cloneDeep(_slideArray)
      );
      this.overlayArray = cloneDeep(_overlays);
      var newSettings = cloneDeep(_settings);
      this.mergeDisplaySettings(newSettings);
      this.checkUserForTrialOverlay();
    },
    isTimelineUpdateNeeded(_slideArray, _overlays, _settings) {
      // Check everything in the slide's info.
      // console.log("isTimelineUpdateNeeded");
      // TODO: the caption can be updated without needing a timeline update!
      // Refactor to ignore need for caption update.
      var existing_slides = map(this.slideArray, s => {
        return s;
      });
      var new_slides = map(_slideArray, s => {
        return s;
      });
      if (!isEqual(existing_slides, new_slides)) {
        console.log("Slide array change, need timeline update!");
        console.log(existing_slides, new_slides);
        return true;
      }
      // Check overlay id/timings/duration/transition_seconds
      var existing_overlays = map(this.overlayArray, o => {
        return [o.id, o.inpoint, o.duration, o.transition_seconds];
      });
      var new_overlays = map(_overlays, o => {
        return [o.id, o.inpoint, o.duration, o.transition_seconds];
      });
      if (!isEqual(existing_overlays, new_overlays)) {
        console.log("Overlay change, need timeline update!");
        return true;
      }
      // In _settings, check for:
      //  globalTransitionTime, globalTransitionType, globalBackgroundColor, globalSlideBackgroundColor

      var existing_settings = pick(this.displaySettings, [
        "globalTransitionTime",
        "globalTransitionType",
        "globalBackgroundColor",
        "globalSlideBackgroundColor"
      ]);
      var new_settings = pick(_settings, [
        "globalTransitionTime",
        "globalTransitionType",
        "globalBackgroundColor",
        "globalSlideBackgroundColor"
      ]);
      if (!isEqual(existing_settings, new_settings)) {
        console.log("Settings change, need timeline update!");
        return true;
      }

      return false;
    },
    reportZeroCount() {
      // Report the basic load of the player.
      axios.get(`https://data3.soundslides.org/${this.reportDataId}/0/`);
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
.outer-container {
  position: relative;
  overflow: hidden;
}

.inner-container {
  position: relative;
  padding: 0;
  background-color: black;
  overflow: hidden;
}
.player {
  position: relative;
  height: 100%;
  width: 100%;
}
.controls {
  position: relative;
  z-index: 10000;
  left: 0;
  bottom: 50px;
  width: 100%;
  height: 100px;
  -webkit-transform: translate3d(0, 0, 0);
}
.controls_always {
  background-color: #111;
}
.controls_background {
  position: absolute;
  z-index: 999;
  background-color: none;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 1)
  ) !important;
  left: 0;
  bottom: -20px;
  width: 100%;
  height: 100px;
  pointer-events: none;
}

.scaling {
  @media only screen and (max-width: 420px) {
    @include transform(scale(0.8, 0.8));
  }
  @media only screen and (min-width: 420px) {
    @include transform(scale(1, 1));
  }
  @media only screen and (min-width: 800px) {
    @include transform(scale(1.6, 1.6));
  }
}
.password-ui {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: auto;
    width: 200px;
    color: black;
    font-family: Arial, sans-serif;
    background-color: #999;
    border-radius: 3px;
    padding: 1em;
    padding-top: 0.5em;
    text-align: center;
    input {
      font-size: 1em;
      padding: 0.4em;
      width: 180px;
    }
    .button {
      margin-top: 1em;
      background-color: #e95420; /* Fire red */
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      cursor: pointer;
    }
    .disabled {
      background-color: #888;
      color: black;
      cursor: unset;
    }
  }
}
.willChange {
  will-change: transform !important;
}
.slides {
  position: absolute;
  opacity: 0;
  overflow: hidden;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.interactive_overlays {
  z-index: 999; // One level below the controls.
  visibility: hidden;
}
.non_interactive_overlays {
  pointer-events: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.overlays {
  visibility: hidden;
}
.capture-tooltip {
  position: absolute;
  width: 100px;
  height: 30px;
  background-color: pink;
}
</style>
