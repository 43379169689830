<template lang="html">
  <select v-model="optionSelected" class="select">
    <option v-for="option in options" :key="option.value" :value="option.value">
      {{ option.text }}
    </option>
  </select>
</template>

<script>
export default {
  name: "ExportDropdown",
  props: {
    selected: {
      type: String,
      default: () => "full-size"
    }
  },
  data() {
    return {
      options: [
        {
          value: "full-size",
          text: "Full-size images"
        },
        {
          value: "1600",
          text: "Resized - 1600px"
        },
        {
          value: "1200",
          text: "Resized - 1200px"
        },
        {
          value: "800",
          text: "Resized - 800px"
        }
      ]
    };
  },
  computed: {
    optionSelected: {
      get() {
        return this.selected;
      },
      set(value) {
        console.log("set", value);
        this.$emit("changed", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
