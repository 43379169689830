import each from "lodash/each";

export const returnBlobbedFileObject = (showModel, forage) => {
  var blobUrls = {};
  var promises = [];
  // Images.
  each(showModel.slideArray, s => {
    var promise = forage.getItem(s.file).then(i => {
      var blob = URL.createObjectURL(i);
      blobUrls[s.file] = blob;
    });
    promises.push(promise);
  });
  // Audio.
  var audioFilename = showModel.data.audio.filename;
  var promise = forage.getItem(audioFilename).then(i => {
    var blob = URL.createObjectURL(i);
    blobUrls[audioFilename] = blob;
  });
  promises.push(promise);
  return Promise.all(promises).then(values => {
    console.log(values);
    console.log("all blobbed up");
    return blobUrls;
  });
};
