<template lang="html">
  <div class="info">
    <FirstRunInfo />
    <p class="confirm">
      <center>
        <button @click="$store.dispatch('offlineShow/hideFirstRunInfo')">
          Understood, let's go!
        </button>
      </center>
    </p>
    <div class="danger">
      🚨This application saves your edit to your browser’s local storage.
    </div>
    <p class="never">
      <a
        href="#"
        @click.prevent="
          $store.dispatch('offlineShow/hideFirstRunInfoForDays', 2)
        "
      >
        Don't show this info for a few days.
      </a>
    </p>
  </div>
</template>

<script>
import FirstRunInfo from "@/components/FirstRunInfo";
export default {
  components: {
    FirstRunInfo
  }
};
</script>

<style lang="scss" scoped>
.info {
  max-width: 580px;
  margin: 10px 18px;
  font-size: 1em;
  color: var(--modal-text-color);
}
.confirm {
  margin-top: 28px;
  margin-bottom: 28px;
}
.danger {
  margin-top: 18px;
  padding: 10px 20px;
  background-color: var(--modal-danger-background-color);
}
button {
  box-sizing: border-box;
  border: 2px solid var(--ui-border-color);
  text-align: center;
  padding: 16px 36px;
  font-size: 1.3em;
  cursor: pointer;
  color: var(--active-button-text-color);
  background-color: var(--active-button-color);
  margin-right: 10px;
}
.never {
  float: right;
  text-decoration: underline;
  font-size: 0.83em;
  a {
    color: var(--modal-text-color);
  }
  margin-bottom: 0px;
}
</style>
