var padding = 18;
export default {
  computed: {
    playerAreaStyle() {
      var width = this.windowWidth / 2 - padding;
      var height = width / this.aspectRatio;
      return {
        width: width + "px",
        height: height + "px"
      };
    },
    topStyle() {
      return {
        height: this.topHeight + "px"
      };
    },
    bottomStyle() {
      return {
        height: this.bottomHeight + "px"
      };
    },
    mainLeftStyle() {
      return {
        top: this.topHeight + "px",
        left: "18px",
        width: this.windowWidth / 2 + "px",
        height: this.windowHeight - this.topHeight - this.bottomHeight + "px"
      };
    },
    mainRightStyle() {
      var gutter = padding;
      return {
        top: this.topHeight + "px",
        left: this.windowWidth / 2 + gutter + "px",
        width: this.windowWidth / 2 - gutter * 1.5 + "px",
        height: this.windowHeight - this.topHeight - this.bottomHeight + "px"
      };
    },
    gridItemWidth() {
      var spacing = 20;
      var thumbAreaHeight =
        this.windowHeight - this.topHeight - this.bottomHeight;

      var width = this.windowWidth / 2 / 3 - spacing;

      if ((width + 20) * 7 > thumbAreaHeight) {
        width = width = this.windowWidth / 2 / 4 - spacing;
      }
      if ((width + 20) * 5 > thumbAreaHeight) {
        width = this.windowWidth / 2 / 5 - spacing;
      }

      if ((width + 20) * 4 > thumbAreaHeight) {
        width = this.windowWidth / 2 / 6 - spacing;
      }

      return width;
    },
    gridImageStyle() {
      var width = this.gridItemWidth;
      return {
        width: width + "px",
        height: width + "px"
      };
    },
    gridStyle() {
      var width = this.gridItemWidth;
      return {
        "grid-template-columns": `repeat(auto-fill, minmax(${width}px, 1fr))`
      };
    }
  }
};
