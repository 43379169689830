<template lang="html">
  <img :src="source" :alt="filepath" @load="loaded" />
</template>

<script>
export default {
  name: "ImageDisplay",
  props: {
    filepath: {
      type: String,
      required: true,
      default: () => ""
    },
    index: {
      type: Number,
      required: true,
      default: () => 0
    },
    preload: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      preloadTriggered: false
    };
  },
  computed: {
    source() {
      // Add some kind of conditional preloading here if you'd like later.
      if (this.preloadTriggered) {
        return this.filepath;
      }
      // Return the smallest blank image.
      return "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";
    }
  },
  watch: {
    preload: {
      immediate: true,
      handler: function(value) {
        if (value && !this.preloadTriggered) {
          // Only set preloadTriggered to true once, and never back to false.
          this.preloadTriggered = true;
        }
      }
    }
  },
  methods: {
    loaded() {
      if (this.preloadTriggered) {
        this.$emit("image-loaded", this.index);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 100%;
  max-height: 100%;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>
