<template lang="html">
  <div
    :ref="'invisible_link' + item.id"
    class="invisible_link"
    @click="linkClicked"
  />
</template>

<script>
export default {
  name: "InvisibleLinkOverlay",
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true
    },
    currentTimelineTime: {
      type: Number,
      default: () => 0
    },
    isMobile: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      justClicked: false
    };
  },
  computed: {},
  methods: {
    linkClicked(evt) {
      console.log("clicked!");
      console.log("this.isMobile", this.isMobile);
      if (this.justClicked && this.isMobile) {
        // We want the "return" click (after opening a new window),
        // to continue to propugate rather than get stuck in a loop opening
        // the same window again and again.
        // This is only on mobile.
        this.justClicked = false;
        return;
      }
      var time = this.currentTimelineTime;
      if (
        time > this.item.inpoint &&
        time < this.item.inpoint + this.item.duration
      ) {
        evt.stopPropagation();
        if (this.item.new_window) {
          this.justClicked = true;
          this.$emit("pauseForLink", time);
          window.open(this.item.link);
        } else {
          window.location.href = this.item.link;
        }
        console.log("launch link");
      }
      console.log("currentTimelineTime", this.currentTimelineTime);
    }
  }
};
</script>

<style lang="scss" scoped>
// All details need to use em's for sharing with .mlt
.invisible_link {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0%;
  //background-color: pink;
}
</style>
