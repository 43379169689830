export const areUploadCredentialsValid = show_obj => {
  //console.log("areUploadCredentialsValid");
  // Reminder, credentials are issued for 24 hours.
  var current_dt = new Date().getTime();
  if ("audioCredentialsV4" in show_obj) {
    var msUntilExpiration = show_obj.audioCredentialsV4.expiration - current_dt;
    var hoursUntilRefresh = 2; // Reminder, credentials are issued for 24 hours.
    var msRefreshLimit = 1000 * 60 * 60 * hoursUntilRefresh;
    // console.log("msUntilExpiration", msUntilExpiration);
    // console.log("msRefreshLimit", msRefreshLimit);
    if (msUntilExpiration > msRefreshLimit) {
      console.log(
        `Credentials don't expire for at least ${hoursUntilRefresh} hours. All good.`
      );
      return true;
    }
  }
  //console.log("Credentials expired or don't exist.");
  return false;
};
