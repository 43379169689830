import { Show } from "@/models/showModel";

export default {
  namespaced: true,
  state: {
    overlay_font_options: ["Lato", "Lora", "Allura", "Rancho", "Roboto"],
    overlay_tool_options: [
      { text: "Move", value: "move", icon: "mouse-pointer" },
      { text: "Text", value: "text", icon: "i-cursor" },
      { text: "Rectangle", value: "rect", icon: "square" },
      { text: "Circle", value: "circle", icon: "circle" }
    ],
    overlay_tool_value: "move",
    activeEditOverlayId: null
  },
  getters: {},
  mutations: {
    setOverlayToolValue(state, value) {
      state.overlay_tool_value = value;
    }
  },
  actions: {
    updateOverlayByIdToSeconds({ state }, obj) {
      // This is now moved to the show module, where it can be debounced.
      console.log(state.user.uid, obj);
      throw "updateOverlayByIdToSeconds called from wrong location.";
    },
    updateOverlayByIdToLayer({ rootState, dispatch }, obj) {
      console.log("updateOverlayByIdToLayer");

      var showModelObject = new Show(rootState.show.currentShow);
      showModelObject.updateOverlayByIdToLayer(obj);

      dispatch("show/updateCurrentDataFromModel", showModelObject, {
        root: true
      });
    },
    updateActiveEditOverlayId({ commit, state }, overlay_id) {
      console.log("updateActiveEditOverlayId", overlay_id);
      if (overlay_id != state.activeEditOverlayId) {
        state.activeEditOverlayId = overlay_id;
        commit("show/setActiveItemFromEditableOverlay", overlay_id, {
          root: true
        });
      }
    },
    addNewTextOverlay({ dispatch, rootState }, overlayObject) {
      // Add the filename.
      var currentSlideFilename =
        rootState.show.currentShow.data.slideArray[rootState.show.slideIndex]
          .file;

      overlayObject.slideFilename = currentSlideFilename;

      var showModelObject = new Show(rootState.show.currentShow);
      var newOverlay = showModelObject.addTextOverlay(overlayObject);

      dispatch("show/updateCurrentDataFromModel", showModelObject, {
        root: true
      });
      dispatch("updateActiveEditOverlayId", newOverlay.id);
    },
    addNewRectOverlay({ dispatch, rootState }, overlayObject) {
      // Add the filename.
      var currentSlideFilename =
        rootState.show.currentShow.data.slideArray[rootState.show.slideIndex]
          .file;

      overlayObject.slideFilename = currentSlideFilename;
      overlayObject.type = "rect";

      var showModelObject = new Show(rootState.show.currentShow);
      var newOverlay = showModelObject.addShapeOverlay(overlayObject);
      dispatch("show/updateCurrentDataFromModel", showModelObject, {
        root: true
      });
      dispatch("updateActiveEditOverlayId", newOverlay.id);
    },
    addNewCircleOverlay({ dispatch, rootState }, overlayObject) {
      // Add the filename.
      var currentSlideFilename =
        rootState.show.currentShow.data.slideArray[rootState.show.slideIndex]
          .file;

      overlayObject.slideFilename = currentSlideFilename;
      overlayObject.type = "circle";

      var showModelObject = new Show(rootState.show.currentShow);
      var newOverlay = showModelObject.addShapeOverlay(overlayObject);

      dispatch("show/updateCurrentDataFromModel", showModelObject, {
        root: true
      });
      dispatch("updateActiveEditOverlayId", newOverlay.id);
    },
    removeOverlayById({ dispatch, rootState }, overlay_id) {
      console.log("removeOverlayById", overlay_id);

      var showModelObject = new Show(rootState.show.currentShow);
      showModelObject.removeOverlayById(overlay_id);

      dispatch("show/updateCurrentDataFromModel", showModelObject, {
        root: true
      });
    },
    updateOverlay({ dispatch, rootState }, overlayObject) {
      console.log("updateOverlay", overlayObject);

      // Load the model at this moment, then make the change.
      var showModelObject = new Show(rootState.show.currentShow);
      showModelObject.updateOverlayById(overlayObject);

      dispatch("show/updateCurrentDataFromModel", showModelObject, {
        root: true
      });
    }
  }
};
