import each from "lodash/each";
import map from "lodash/map";
import { Show } from "@/models/showModel";

export class TMAZipShow {
  constructor(zip, appId) {
    this.zip = zip;
    this.appId = appId;
    this.zipFileList = [];
    this.jsonFilePath = null;
    this.jsonString = null;
    this.error = false;
    this.errorMessage = null;
    this.errorRedirectUrl = null;
    this.slideArray = [];
    this.overlayArray = [];
    this.imageDirectory = "";
    this.displaySettings = {};
    this.imageSelectList = [];
    this.audioSelection = null;
    this.selectionListPopulated = false;
    this.info = null;
    this.parsedJson = null;
    this.ERROR_MISSING_CONTROL_FILE = "Missing control file (json)";
    this.ERROR_NON_CREATOR = "This file was not created with this application.";
    this.ERROR_IS_SOUNDSLIDES_ZIP =
      "This file can be edited in the full edition of Soundslides.";
  }

  populateFromJson() {
    // Create and return a show object based on the json file.
    var _json = JSON.parse(this.jsonString);
    this.parsedJson = _json;
    // First, check that the appId matches, if not, error.
    if (this.parsedJson.creatorId != this.appId) {
      this.error = true;
      this.errorMessage = this.ERROR_NON_CREATOR;
      this.errorRedirectUrl = this.parsedJson.creatorUrl;
    }
    this.slideArray = _json.slideArray;
    this.overlayArray = _json.overlayArray;
    this.metaDict = {};
    this.imageDirectory = "assets";
    this.displaySettings = _json.displaySettings;
    this.info = {
      duration: _json.audioFile.duration,
      slide_count: this.slideArray.length,
      title: _json.title,
      parsedJson: _json,
      creator: _json.creator
    };
  }

  async createSelectionFiles() {
    // This is the filelist that mimics the version from a "form selection";
    var _this = this;
    var promises = [];
    var _zip = this.zip;
    // Add the images to the imageSelectList.
    each(_this.slideArray, s => {
      console.log(`${_this.imageDirectory}/${s.file}`);
      var imagePromise = _zip
        .file(`${_this.imageDirectory}/${s.file}`)
        .async("blob")
        .then(blob => {
          // Convert to File from blob.
          // https://stackoverflow.com/questions/27159179/how-to-convert-blob-to-file-in-javascript
          var file = new File([blob], s.name, {
            type: "image/jpeg",
            lastModified: 1534584790000
          });
          _this.imageSelectList.push(file);
          return;
        });
      promises.push(imagePromise);
    });

    // Add the audio to the audioSelection.
    var audioPromise = _zip
      .file(`${_this.imageDirectory}/audio_hi.mp3`)
      .async("blob")
      .then(blob => {
        // Convert to File from blob.
        // https://stackoverflow.com/questions/27159179/how-to-convert-blob-to-file-in-javascript
        var file = new File([blob], "audio_hi.mp3", {
          type: "audio/mpeg",
          lastModified: 1534584790000
        });
        _this.audioSelection = file;
        return;
      });
    promises.push(audioPromise);

    return await Promise.all(promises)
      .then(values => {
        console.log(values);
        return;
      })
      .then(() => {
        console.log("then!");
        console.log(_this.imageSelectList.concat(_this.audioSelection));
        _this.selectionListPopulated = true;
        return _this;
      });
  }

  async parseZip() {
    var _this = this;
    var _zip = this.zip;
    var hasSoundslidesControlFile = false;
    // Get all the file entries.
    _zip.forEach(function(relativePath, zipEntry) {
      // 2) print entries
      console.log(relativePath);
      if (!zipEntry.name.startsWith("__MACOSX")) {
        // Do not add odd __MACOSX files.
        _this.zipFileList.push(zipEntry.name);
      }

      // Find the data.json control file.
      if (
        zipEntry.name.endsWith("data.json") &&
        !zipEntry.name.startsWith("__MACOSX")
      ) {
        _this.jsonFilePath = zipEntry.name;
      }

      // Look for a soundslide.xml control file.
      if (
        zipEntry.name.endsWith("soundslide.xml") &&
        !zipEntry.name.startsWith("__MACOSX")
      ) {
        hasSoundslidesControlFile = true;
      }

      // Look for a soundslide.txt control file.
      if (
        zipEntry.name.endsWith("soundslide.txt") &&
        !zipEntry.name.startsWith("__MACOSX")
      ) {
        hasSoundslidesControlFile = true;
      }
    });

    // Read the json.

    if (_this.jsonFilePath) {
      return await new Promise((resolve, reject) => {
        _zip
          .file(_this.jsonFilePath)
          .async("string")
          .then(text => {
            _this.jsonString = text;
            _this.populateFromJson();
            resolve(_this);
          })
          .catch(e => {
            console.log(e);
            reject(_this);
          });
      });
    } else {
      // Missing json.
      _this.error = true;
      _this.errorMessage = _this.ERROR_MISSING_CONTROL_FILE;

      if (hasSoundslidesControlFile) {
        _this.errorMessage = _this.ERROR_IS_SOUNDSLIDES_ZIP;
        _this.errorRedirectUrl = "https://app.soundslides.com";
      }

      return _this;
    }
  }
}

export const returnShowFromDataJson = data => {
  var showData = {};
  showData.data = data;
  showData.data.audio = data.audioFile;
  showData.data.images = map(data.slideArray, i => {
    return {
      filename: i.file,
      id: i.id,
      name: i.name,
      source_id: i.source_id
    };
  });
  showData.title = data.title;
  showData.autoTimeEnabled = data.autoTimeEnabled;
  showData.autoTimePerImage = data.autoTimePerImage;
  showData.formatVersion = data.formatVersion;
  showData.publishedAt = data.publishedAt;

  var showModel = new Show(showData);
  return showModel;
};
