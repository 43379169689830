export const returnEmptyWaveBlob = seconds => {
  console.time("returnEmptyWaveBlob");
  var waveBuffer = encodeEmptyWAV(seconds * 44100, 1, 44100, 1, 16);
  var audioBlob = new Blob([waveBuffer], { type: "audio/wav" });
  var new_file = URL.createObjectURL(audioBlob);
  console.timeEnd("returnEmptyWaveBlob");
  return new_file;
};

function encodeEmptyWAV(
  samples_length,
  format,
  sampleRate,
  numChannels,
  bitDepth
) {
  console.log(samples_length, format, sampleRate, numChannels, bitDepth);
  console.time("encodeEmptyWAV");
  var bytesPerSample = bitDepth / 8;
  var blockAlign = numChannels * bytesPerSample;
  console.time("ArrayBuffer");
  var buffer = new ArrayBuffer(44 + samples_length * bytesPerSample);
  var view = new DataView(buffer);
  console.timeEnd("ArrayBuffer");
  /* RIFF identifier */
  writeString(view, 0, "RIFF");
  /* RIFF chunk length */
  view.setUint32(4, 36 + samples_length * bytesPerSample, true);
  /* RIFF type */
  writeString(view, 8, "WAVE");
  /* format chunk identifier */
  writeString(view, 12, "fmt ");
  /* format chunk length */
  view.setUint32(16, 16, true);
  /* sample format (raw) */
  view.setUint16(20, format, true);
  /* channel count */
  view.setUint16(22, numChannels, true);
  /* sample rate */
  view.setUint32(24, sampleRate, true);
  /* byte rate (sample rate * block align) */
  view.setUint32(28, sampleRate * blockAlign, true);
  /* block align (channel count * bytes per sample) */
  view.setUint16(32, blockAlign, true);
  /* bits per sample */
  view.setUint16(34, bitDepth, true);
  /* data chunk identifier */
  writeString(view, 36, "data");
  /* data chunk length */
  view.setUint32(40, samples_length * bytesPerSample, true);

  console.timeEnd("encodeEmptyWAV");
  return buffer;
}

function writeString(view, offset, string) {
  for (var i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}
