<template lang="html">
  <div :ref="'frame' + overlay.id" class="frame" :style="frameStyle">
    <div
      v-if="displayWidth > 0 && displayHeight > 0"
      :key="'text_edit_' + overlay.id + displayWidth + displayHeight"
      class="title-overlay"
      :style="fontStyleComputed"
    >
      <div class="line-container" :style="positionComputed">
        <div :style="nonEditableStyle">
          <div
            ref="textContainer"
            :style="textContainerStyle"
            class="text-container"
          >
            <h1
              :style="lineStyle"
              class="flex-center-vertically"
              v-text="editing_text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isUndefined from "lodash/isUndefined";
import WebFont from "webfontloader";
export default {
  name: "TextNonEditable",
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true
    },
    roundedBackground: {
      type: Boolean,
      default: () => false
    }
  },
  data: function() {
    return {
      width: null,
      height: null,
      x: 0,
      y: 0,
      editing: false,
      editing_text: "",
      initialX: 0,
      initialY: 0
    };
  },
  computed: {
    overlay() {
      return this.item;
    },
    text_vertical_align() {
      if (!this.overlay.text_vertical_align) {
        return "top";
      } else {
        return this.overlay.text_vertical_align;
      }
    },
    textContainerStyle() {
      var _style = {
        height: "100%"
      };
      if (this.overlay.text_background) {
        _style.backgroundColor = this.overlay.text_background_color_rgba;
        if (!isUndefined(this.overlay.text_background_radius_em)) {
          _style.borderRadius = this.overlay.text_background_radius_em + "em";
        }
        if (!isUndefined(this.overlay.text_background_border_em)) {
          _style.boxShadow = `0px 0px 0px ${this.overlay.text_background_border_em}em ${this.overlay.text_background_border_rgba} inset`;
        }
        if (this.roundedBackground) {
          _style.borderRadius = "50%";
        }
      }
      return _style;
    },
    nonEditableStyle() {
      return {
        position: "absolute",
        top: "0px",
        left: "0px",
        width: this.initialWidth + "px",
        height: this.initialHeight + "px"
      };
    },
    frameStyle() {
      var _frameStyle = {
        backgroundColor: `rgba(0, 0, 0, ${this.overlay.background_alpha})`,
        "pointer-events": "none"
      };
      _frameStyle["pointer-events"] = "auto !important";

      return _frameStyle;
    },
    lineStyle() {
      var _style = {
        position: "relative",
        color: this.overlay.text_color_rgba,
        "font-family": this.overlay.font_family,
        "font-size": this.overlay.font_size_em + "em",
        "text-align": this.overlay.text_align,
        //"justify-content": this.text_vertical_align,
        "text-shadow": `0.02em 0.02em ${this.overlay.text_shadow_color_rgba},
          0 0 0.25em ${this.overlay.text_shadow_color_rgba},
          0 0 0.5em ${this.overlay.text_shadow_color_rgba},
          0 0 0.75em ${this.overlay.text_shadow_color_rgba},
          0 0 1em ${this.overlay.text_shadow_color_rgba}`
      };
      if (this.text_vertical_align == "top") {
        _style["justify-content"] = "start";
        _style["top"] = "0.3em";
      }
      if (this.text_vertical_align == "middle") {
        _style["justify-content"] = "center";
        _style["top"] = "0em";
      }
      if (this.text_vertical_align == "bottom") {
        _style["justify-content"] = "flex-end";
        _style["bottom"] = "0.5em";
      }
      return _style;
    },
    fontStyleComputed() {
      // This should exist on every overlay, as it enables
      // us to share css with the mlt-based overlays.
      var displayWidth = this.$parent.computed_width;
      var displayHeight = this.$parent.computed_height;
      if (displayHeight > displayWidth) {
        // Vertical (likely mobile).
        console.log("vertical", displayHeight, displayWidth);
      }
      if (displayWidth > 1920) {
        // Practical size maximum, also the max in MLT for now.
        displayWidth = 1920;
      }
      // Removing 'practical' size minimums, as I'm not exactly sure why they are there.
      //if (displayWidth < 480) {
      //  // Practical size minimum, MLT minimum is 720 FYI.
      //  displayWidth = 480;
      //}
      var defaultSize = displayWidth * 0.018 + "px";
      return {
        fontSize: defaultSize
      };
    },
    positionComputed() {
      return {
        left: this.initialX + "%",
        top: this.initialY + "%"
      };
    },
    displayWidth() {
      if (this.$parent.computed_width) {
        //console.log(this.$parent.computed_width);
        return this.$parent.computed_width;
      }
      return 100;
    },
    displayHeight() {
      if (this.$parent.computed_height) {
        if (this.$parent.controlsAutoHide) {
          return this.$parent.computed_height;
        } else {
          return this.$parent.computed_height - this.$parent.plyr_height;
        }
      }
      return 100;
    },
    initialWidth() {
      return (this.displayWidth * this.overlay.width) / 100;
    },
    initialHeight() {
      return (this.displayHeight * this.overlay.height) / 100;
    },
    fontFamily() {
      return this.overlay.font_family;
    }
  },
  watch: {
    fontFamily(value) {
      WebFont.load({
        google: {
          families: [value]
        }
      });
    }
  },
  mounted() {
    WebFont.load({
      google: {
        families: [this.overlay.font_family]
      }
    });
    this.editing_text = this.overlay.text;
    this.initialX = this.overlay.x || 20;
    this.initialY = this.overlay.y || 20;
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.frame {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  //background-color: rgba(0, 0, 255, 0.1) !important;
  pointer-events: none !important;
}
.text-container {
  padding-left: 1.5em;
  padding-right: 1.5em;
  overflow: hidden;
}
.flex-center-vertically {
  display: flex;
  justify-content: top;
  flex-direction: column;
}
.title-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: "Lora", cursive;
  color: white;
  width: 100%;
  height: 100%;
  z-index: 20000;
  pointer-events: none;
  //background-color: pink;
  // Make lowerthird text unselectable.
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  [contenteditable] {
    outline: 0px solid transparent;
    -webkit-user-select: text;
    user-select: text;
  }

  h1,
  h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    color: white;
    //background-color: pink;
    //overflow: hidden;
    line-height: 120%;
    font-weight: normal;
    -webkit-transform: translateZ(0);
    white-space: pre-wrap;
  }

  .line-marker {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: pink;
  }

  .line-container {
    position: absolute;
    margin: 0;
    //top: 0;
    //left: 0;
    // background-color: rgba(0, 0, 255, 0.7);
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}
</style>
