import convert from "xml-js";
import map from "lodash/map";
import each from "lodash/each";

export const returnSlideArrayFromXml = xml_string => {
  console.log("returnSlideArrayFromXml");
  var data = convert.xml2js(xml_string, { compact: true, spaces: 4 });
  //console.log(data);
  var slideArray = map(data.edit.slides.slide, s => {
    console.log(s);
    return {
      caption: s.caption._text === undefined ? "" : s.caption._text,
      end_percent_scale:
        s.end_percent_scale === undefined ? 100 : s.end_percent_scale._text,
      end_percent_x: s.end_percent_x === undefined ? 0 : s.end_percent_x._text,
      end_percent_y: s.end_percent_y === undefined ? 0 : s.end_percent_y._text,
      file: s.file._text,
      start_percent_scale:
        s.start_percent_scale === undefined ? 100 : s.start_percent_scale._text,
      start_percent_x:
        s.start_percent_x === undefined ? 0 : s.start_percent_x._text,
      start_percent_y:
        s.start_percent_y === undefined ? 0 : s.start_percent_y._text,
      timing: parseInt(s.timing._text, 10),
      transition: s.transition === undefined ? "inherit" : s.transition._text,
      transition_duration:
        s.transition_duration === undefined
          ? "inherit"
          : s.transition_duration._text
    };
  });
  return slideArray;
};

export const returnLowerthirdsArrayFromXml = xml_string => {
  console.log("returnLowerthirdsArrayFromXml");
  var data = convert.xml2js(xml_string, { compact: true, spaces: 4 });
  // xml2js-compact is weird. If an array is single, it returns the item rather than the array.
  var ltArray;
  if (Array.isArray(data.edit.lower_thirds.item)) {
    ltArray = map(data.edit.lower_thirds.item, s => {
      return {
        name: s.name._text || "",
        title: s.title._text || "",
        inpoint: parseInt(s.inpoint._text, 10),
        duration: parseInt(s.duration._text, 10)
      };
    });
  } else {
    ltArray = map(data.edit.lower_thirds, s => {
      return {
        name: s.name._text || "",
        title: s.title._text || "",
        inpoint: parseInt(s.inpoint._text, 10),
        duration: parseInt(s.duration._text, 10)
      };
    });
  }
  return ltArray;
};

export const returnShowMetaDataFromXml = xml_string => {
  console.log("returnShowMetaDataFromXml");
  var data = convert.xml2js(xml_string, { compact: true, spaces: 4 });
  //console.log(data);
  var meta = {};
  each(data.edit.meta.item, i => {
    meta[i.parameter._text] = i.value._text;
  });
  return meta;
};

export const returnTimestampFromXml = xml_string => {
  console.log("returnTimestampFromXml");
  var data = convert.xml2js(xml_string, { compact: true, spaces: 4 });
  console.log(data);
  if (data.edit.timestamp) {
    var timestamp = data.edit.timestamp._text || null;
    if (timestamp) {
      timestamp = parseInt(timestamp, 10);
    }
    return timestamp;
  }
  return null;
};

export const returnImageDirectoryFromMeta = metaDict => {
  // Note: If custom_width and custom_height are larger than fullscreen, use custom.
  if (
    "custom_size" in metaDict &&
    metaDict.custom_size == "true" &&
    parseInt(metaDict.custom_width) > 1000
  ) {
    return "custom";
  }

  if ("fullscreen" in metaDict && metaDict.fullscreen == "true") {
    return "fullscreen";
  }
  if ("custom_size" in metaDict && metaDict.custom_size == "true") {
    return "custom";
  }
  return "600_450";
};
