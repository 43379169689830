<template lang="html">
  <div class="plyr plyr--full-ui plyr--video big-button">
    <button type="button" class="plyr__control plyr__control--overlaid" data-plyr="play" aria-label="Play">
      <svg role="presentation" focusable="false"><use xlink:href="#plyr-play" /></svg><span class="plyr__sr-only">Play</span>
    </button>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.big-button{
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background: 0;
  left: 0;
  top: 0;
  transform: translateX(-50%), translateY(-50%);
  z-index: 10000;
}
</style>
