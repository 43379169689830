<template lang="html">
  <div
    :ref="'frame' + overlay.id"
    class="frame"
    :style="frameStyle"
    @click="outerClick"
  >
    <div
      v-if="displayWidth > 0 && displayHeight > 0"
      :key="'text_edit_' + overlay.id + displayWidth + displayHeight"
      class="title-overlay"
      :style="fontStyleComputed"
    >
      <div class="line-container" :style="positionComputed" @click="clickDrag">
        <vue-draggable-resizable
          :ref="'dragText' + overlay.id"
          :key="overlay.id"
          :w="initialWidth"
          :h="initialHeight"
          :active.sync="editing"
          :prevent-deactivation="false"
          drag-handle=".drag-handle"
          class="draggable-pointer-on"
          :class="{ 'draggable-override-border': !editing }"
          @dragging="onDrag"
          @resizing="onResize"
          @dragstop="clickDrag"
        >
          <h1
            :contenteditable="true"
            spellcheck="false"
            :style="lineStyle"
            class="avoid-shortkey-class"
            @focus="editing = true"
            @blur="onEdit"
            @keyup="onKeyUp"
            v-text="editing_text"
          />
          <v-icon
            v-show="editing"
            name="arrows-alt"
            class="drag-handle drag-handle-top"
          />
          <v-icon
            v-show="editing"
            name="arrows-alt"
            class="drag-handle drag-handle-bottom"
          />
        </vue-draggable-resizable>
      </div>
    </div>
    <div v-if="false" class="debug">
      {{ editing }}
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import WebFont from "webfontloader";
export default {
  name: "TextEditable",
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data: function() {
    return {
      width: null,
      height: null,
      x: 0,
      y: 0,
      editing: false,
      outsideClickActive: false,
      editing_text: "",
      editing_text_pre_save: "",
      initialX: 0,
      initialY: 0
    };
  },
  computed: {
    overlay() {
      return this.item;
    },
    activeEditOverlayId() {
      return this.$store.state.overlays.activeEditOverlayId;
    },
    frameStyle() {
      var _frameStyle = {
        backgroundColor: `rgba(0, 0, 0, ${this.overlay.background_alpha})`,
        "pointer-events": "none"
      };
      if (this.outsideClickActive) {
        _frameStyle["pointer-events"] = "auto !important";
      }
      return _frameStyle;
    },
    lineStyle() {
      return {
        "font-family": this.overlay.font_family,
        "font-size": this.overlay.font_size_em + "em",
        "text-align": this.overlay.text_align,
        "text-shadow": `0.02em 0.02em rgba(0, 0, 0, 1),
          0 0 0.25em rgba(0, 0, 0, ${this.overlay.text_background_alpha}),
          0 0 0.5em rgba(0, 0, 0, ${this.overlay.text_background_alpha}),
          0 0 0.75em rgba(0, 0, 0, ${this.overlay.text_background_alpha}),
          0 0 1em rgba(0, 0, 0, ${this.overlay.text_background_alpha})`
      };
    },
    fontStyleComputed() {
      // This should exist on every overlay, as it enables
      // us to share css with the mlt-based overlays.
      var displayWidth = this.$parent.computed_width;
      var displayHeight = this.$parent.computed_height;
      if (displayHeight > displayWidth) {
        // Vertical (likely mobile).
        console.log("vertical", displayHeight, displayWidth);
      }
      if (displayWidth > 1920) {
        // Practical size maximum, also the max in MLT for now.
        displayWidth = 1920;
      }
      // Removing 'practical' size minimums, as I'm not exactly sure why they are there.
      //if (displayWidth < 480) {
      //  // Practical size minimum, MLT minimum is 720 FYI.
      //  displayWidth = 480;
      //}
      var defaultSize = displayWidth * 0.018 + "px";
      return {
        fontSize: defaultSize
      };
    },
    positionComputed() {
      return {
        left: this.initialX + "%",
        top: this.initialY + "%"
      };
    },
    displayWidth() {
      if (this.$parent.computed_width) {
        console.log(this.$parent.computed_width);
        return this.$parent.computed_width;
      }
      return 100;
    },
    displayHeight() {
      if (this.$parent.computed_height) {
        if (this.$parent.controlsAutoHide) {
          return this.$parent.computed_height;
        } else {
          return this.$parent.computed_height - this.$parent.plyr_height;
        }
      }
      return 100;
    },
    percentWidth() {
      var width = this.width;
      if (!width) {
        width = this.initialWidth;
      }
      return Math.round((width / this.displayWidth) * 10000) / 100;
    },
    percentHeight() {
      var height = this.height;
      if (!height) {
        height = this.initialHeight;
      }
      return Math.round((height / this.displayHeight) * 10000) / 100;
    },
    percentX() {
      return (this.x / this.displayWidth) * 100;
    },
    percentY() {
      return (this.y / this.displayHeight) * 100;
    },
    initialWidth() {
      return Math.max((this.displayWidth * this.overlay.width) / 100, 50);
    },
    initialHeight() {
      return Math.max((this.displayHeight * this.overlay.height) / 100, 50);
    },
    dragSafe() {
      return (
        this.newX > 0 && this.leftX < 100 && this.newY > 0 && this.bottomY < 100
      );
    },
    leftX() {
      return this.newX + this.percentWidth;
    },
    bottomY() {
      return this.newY + this.percentHeight;
    },
    newX() {
      return Math.round((this.percentX + this.initialX) * 100) / 100;
    },
    newY() {
      return Math.round((this.percentY + this.initialY) * 100) / 100;
    },
    fontFamily() {
      return this.overlay.font_family;
    }
  },
  watch: {
    fontFamily(value) {
      WebFont.load({
        google: {
          families: [value]
        }
      });
    },
    activeEditOverlayId(value) {
      console.log("TextEditable: activeEditOverlayId", value);
      if (value != this.overlay.id) {
        this.editing = false;
      } else {
        this.editing = true;
      }
    }
  },
  mounted() {
    WebFont.load({
      google: {
        families: [this.overlay.font_family]
      }
    });
    this.editing_text = this.overlay.text;
    this.editing_text_pre_save = this.overlay.text;
    this.initialX = this.overlay.x || 20;
    this.initialY = this.overlay.y || 20;
    if (this.activeEditOverlayId == this.overlay.id) {
      this.editing = true;
    }
  },
  methods: {
    onResize: function(x, y, width, height) {
      console.log("onResize", x, y);
      this.x = x;
      this.y = y;
      this.width = width;
      this.height = height;
    },
    onDrag: function(x, y) {
      console.log("onDrag", x, y);
      this.x = x;
      this.y = y;
    },
    clickDrag($evt) {
      console.log("clickDrag", this.editing, this.overlay.id, $evt);
      if ($evt.preventDefault) {
        $evt.preventDefault();
        $evt.stopPropagation();
      }
      this.editing = true;
      this.outsideClickActive = true;
      this.$emit("pauseAudio"); // Emit out to player.
      this.$store.dispatch(
        "overlays/updateActiveEditOverlayId",
        this.overlay.id
      );
      this.updateOverlay();
    },
    outerClick($evt) {
      console.log("outerClick", this.editing, this.overlay.text);
      if (this.outsideClickActive) {
        this.editing = false;
        this.outsideClickActive = false;
        $evt.preventDefault();
        $evt.stopPropagation();
        this.$store.dispatch("overlays/updateActiveEditOverlayId", null);
        //this.$nextTick(this.updateOverlay);
      }
    },
    onEdit() {
      console.log("onEdit");
      this.editing_text = this.editing_text_pre_save;
      this.$nextTick(this.updateOverlay);
    },
    onKeyUp(evt) {
      var src = evt.target.innerText;
      //console.log("src", src);
      this.editing_text_pre_save = src;
    },
    updateOverlay() {
      var overlayObject = cloneDeep(this.overlay);
      //overlayObject.width = this.percentWidth;
      //overlayObject.height = this.percentHeight;
      if (this.width != null && this.height != null) {
        // Only update if edited.
        overlayObject.width = this.percentWidth;
        overlayObject.height = this.percentHeight;
      }
      overlayObject.x = Math.round((this.percentX + this.initialX) * 100) / 100;
      overlayObject.y = Math.round((this.percentY + this.initialY) * 100) / 100;
      overlayObject.text = this.editing_text;
      console.log("editing_text", this.editing_text);

      var overlayUnchanged = isEqual(overlayObject, cloneDeep(this.overlay));
      if (!overlayUnchanged) {
        this.$store.dispatch("overlays/updateOverlay", overlayObject);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.frame {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  //background-color: rgba(0, 0, 255, 0.1) !important;
  pointer-events: none !important;
}
.debug {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  width: auto;
  font-size: 1em;
}
.draggable-override-border {
  border: 1px solid rgba(0, 0, 0, 0) !important;
}
.draggable-pointer-on {
  pointer-events: auto;
}
.title-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: "Lora", cursive;
  color: white;
  width: 100%;
  height: 100%;
  z-index: 20000;
  pointer-events: none;
  //background-color: pink;
  // Make lowerthird text unselectable.
  //-webkit-user-select: none; /* Safari */
  //-moz-user-select: none; /* Firefox */
  //-ms-user-select: none; /* IE10+/Edge */
  //user-select: none; /* Standard */

  [contenteditable] {
    outline: 0px solid transparent;
    -webkit-user-select: text;
    user-select: text;
  }

  h1,
  h2 {
    margin-top: 0px;
    height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    color: white;
    //background-color: pink;
    //overflow: hidden;
    line-height: 120%;
    font-weight: normal;
    -webkit-transform: translateZ(0);
    white-space: pre-wrap;
  }

  .line-marker {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: pink;
  }

  .line-container {
    position: absolute;
    margin: 0;
    //top: 0;
    //left: 0;
    // background-color: rgba(0, 0, 255, 0.7);
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .drag-handle {
    position: absolute;
    right: 0px;
    bottom: -27px;
    width: 14px;
    height: 14px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 3px;
  }
  .drag-handle-bottom {
    position: absolute;
    right: -20px;
    bottom: 0px;
  }
  .drag-handle-top {
    position: absolute;
    left: -20px;
    top: 0px;
  }
}
</style>
