import axios from "axios";
import WaveformData from "waveform-data";
export default {
  namespaced: true,
  state: {
    waveformShowId: null,
    waveformData: null,
    waveformDataLoaded: false,
    waveformDataCalled: false,
    waveformDataError: false
  },
  getters: {},
  mutations: {
    setWaveformData(state, waveform) {
      state.waveformDataCalled = true;
      state.waveformData = waveform;
      state.waveformDataLoaded = true;
    }
  },
  actions: {
    loadWaveformDataIfNeeded({ rootState, state }) {
      if (state.waveformDataCalled) {
        return;
      }
      var show = rootState.show.currentShow;
      var audio_source_id = show.data.audio.source_id;
      var audio_filename = show.data.audio.filename;
      var data_url = `https://soundslides-media-mp3-waveform-dat.s3.amazonaws.com/${audio_source_id}/${audio_filename}.dat`;
      state.waveformDataCalled = true;
      axios
        .get(data_url, {
          responseType: "arraybuffer"
        })
        .then(response => {
          console.log(response);
          return response.data;
        })
        .then(buffer => WaveformData.create(buffer))
        .then(waveform => {
          console.log(`Waveform has ${waveform.channels} channels`);
          console.log(`Waveform has length ${waveform.length} points`);
          state.waveformData = waveform;
          state.waveformDataLoaded = true;
        })
        .catch(error => {
          console.log(error);
          state.waveformDataError = true;
        });
    }
  }
};
