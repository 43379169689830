import { render, staticRenderFns } from "./TextNonEditable.vue?vue&type=template&id=4f317674&scoped=true&lang=html&"
import script from "./TextNonEditable.vue?vue&type=script&lang=js&"
export * from "./TextNonEditable.vue?vue&type=script&lang=js&"
import style0 from "./TextNonEditable.vue?vue&type=style&index=0&id=4f317674&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f317674",
  null
  
)

export default component.exports