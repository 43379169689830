<template lang="html">
  <div class="modal-progress" @click="e => e.stopPropagation()">
    <div class="modal-inner">
      <div class="modal-content">
        <h3>{{ text }}</h3>
        <Progress :progress-value="percent" />
      </div>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/Progress";
export default {
  name: "ModalProgress",
  components: {
    Progress
  },
  props: {
    text: {
      type: String,
      default: () => ""
    },
    percent: {
      type: Number,
      default: () => 0
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.7);
  display: block;

  .modal-inner {
    position: relative;
    width: 100%;
    height: 100%;

    .modal-content {
      color: var(--modal-text-color);
      background-color: var(--modal-background-color);
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 20px;
      padding-top: 0px;
      min-width: 200px;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
