import map from "lodash/map";
import sumBy from "lodash/sumBy";
import filter from "lodash/filter";
import cloneDeep from "lodash/cloneDeep";

export const isPublishable = show_object => {
  show_object = cloneDeep(show_object);
  // Some shows do have displaySettings.
  var displaySettings = (show_object.data || {}).displaySettings || {};
  var motionPreset = displaySettings.globalMotionPreset || "none";
  var hasMotionPreset = motionPreset != "none";
  var _slideArray = (show_object.data || {}).slideArray || [];
  var slideArray = map(_slideArray, s => {
    s.end_percent_scale = parseInt(s.end_percent_scale || 100, 10);
    s.end_percent_x = parseInt(s.end_percent_x || 0, 10);
    s.end_percent_y = parseInt(s.end_percent_y || 0, 10);
    s.start_percent_scale = parseInt(s.start_percent_scale || 100, 10);
    s.start_percent_x = parseInt(s.start_percent_x || 0, 10);
    s.start_percent_y = parseInt(s.start_percent_y || 0, 10);
    return s;
  });
  var slidesWithoutMotion = filter(slideArray, {
    end_percent_scale: 100,
    end_percent_x: 0,
    end_percent_y: 0,
    start_percent_scale: 100,
    start_percent_x: 0,
    start_percent_y: 0
  });
  var slideArrayHasMotion = slidesWithoutMotion.length != slideArray.length;
  var slideArrayHasVideo = sumBy(slideArray, { type: "video" }) > 0;
  //console.log("hasMotionPreset", hasMotionPreset);
  //console.log("slideArrayHasVideo", slideArrayHasVideo);
  //console.log("slideArrayHasMotion", slideArrayHasMotion);
  return (
    hasMotionPreset == false &&
    slideArrayHasMotion == false &&
    slideArrayHasVideo == false
  );
};
