import each from "lodash/each";
import filter from "lodash/filter";
import reject from "lodash/reject";
import { returnNewId } from "@/models/overlays";

export const returnMigratedOverlays = overlayArray => {
  //console.log(overlayArray);
  var lowerthirdOverlays = filter(overlayArray, { type: "lowerthird" });
  //console.log("lowerthirdOverlays", lowerthirdOverlays);

  var titleOverlays = filter(overlayArray, { type: "title" });
  //console.log("titleOverlays", titleOverlays);

  var _migratedOverlayArray = reject(overlayArray, o => {
    return o.type == "lowerthird" || o.type == "title";
  });

  each(lowerthirdOverlays, o => {
    // Add the top lowerthird item.
    _migratedOverlayArray.push({
      background_alpha: 0,
      duration: o.duration,
      font_family: "Lato",
      font_size_em: 2,
      height: 40,
      id: returnNewId(),
      inpoint: o.inpoint,
      layer: 1,
      text: o.name,
      text_align: "left",
      text_background: false,
      text_background_alpha: 0.3,
      text_background_border_em: 0,
      text_background_border_rgba: "rgba(0,0,0,1)",
      text_background_color_rgba: "rgba(0,0,0,1)",
      text_background_radius_em: 0,
      text_color_rgba: "rgba(255,255,255,1)",
      text_shadow_color_rgba: "rgba(0, 0, 0, 0.4)",
      text_vertical_align: "bottom",
      timing_mode: 0,
      transition_seconds: 0.5,
      type: "text_color",
      width: 90,
      x: 5,
      y: 47
    });

    // Add the top lowerthird item.
    _migratedOverlayArray.push({
      background_alpha: 0,
      duration: o.duration,
      font_family: "Lato",
      font_size_em: 1.5,
      height: 14,
      id: returnNewId(),
      inpoint: o.inpoint,
      layer: 2,
      text: o.title,
      text_align: "left",
      text_background: false,
      text_background_alpha: 0.3,
      text_background_border_em: 0,
      text_background_border_rgba: "rgba(0,0,0,1)",
      text_background_color_rgba: "rgba(0,0,0,1)",
      text_background_radius_em: 0,
      text_color_rgba: "rgba(255,255,255,1)",
      text_shadow_color_rgba: "rgba(0, 0, 0, 0.4)",
      text_vertical_align: "middle",
      timing_mode: 1,
      timing_mode_slide_filename: "1596737553494_DSCF0352.jpg",
      transition_seconds: 0.5,
      type: "text_color",
      width: 90,
      x: 5,
      y: 81
    });
  });

  each(titleOverlays, o => {
    // Add the title (there are 3 positions (line1, line2 or line3))

    if (o.line1 != "") {
      // Top line.
      _migratedOverlayArray.push({
        background_alpha: 0,
        duration: o.duration,
        font_family: o.line1_font_family,
        font_size_em: o.line1_font_size_em,
        height: 50,
        id: returnNewId(),
        inpoint: o.inpoint,
        layer: 1,
        text: o.line1,
        text_align: o.line1_align,
        text_background: false,
        text_background_alpha: 0.3,
        text_background_border_em: 0,
        text_background_border_rgba: "rgba(0,0,0,1)",
        text_background_color_rgba: "rgba(0,0,0,1)",
        text_background_radius_em: 0,
        text_color_rgba: "rgba(255,255,255,1)",
        text_shadow_color_rgba: "rgba(0, 0, 0, 0.3)",
        text_vertical_align: "top",
        timing_mode: 0,
        transition_seconds: o.transition_seconds,
        type: "text_color",
        width: 80,
        x: 10,
        y: 8
      });
    }

    // Middle line.
    if (o.line2 != "") {
      // Top line.
      _migratedOverlayArray.push({
        background_alpha: 0,
        duration: o.duration,
        font_family: o.line2_font_family,
        font_size_em: o.line2_font_size_em,
        height: 50,
        id: returnNewId(),
        inpoint: o.inpoint,
        layer: 1,
        text: o.line2,
        text_align: o.line2_align,
        text_background: false,
        text_background_alpha: 0.3,
        text_background_border_em: 0,
        text_background_border_rgba: "rgba(0,0,0,1)",
        text_background_color_rgba: "rgba(0,0,0,1)",
        text_background_radius_em: 0,
        text_color_rgba: "rgba(255,255,255,1)",
        text_shadow_color_rgba: "rgba(0, 0, 0, 0.3)",
        text_vertical_align: "middle",
        timing_mode: 0,
        transition_seconds: o.transition_seconds,
        type: "text_color",
        width: 80,
        x: 10,
        y: 25
      });
    }

    // Middle line.
    if (o.line3 != "") {
      // Top line.
      _migratedOverlayArray.push({
        background_alpha: 0,
        duration: o.duration,
        font_family: o.line3_font_family,
        font_size_em: o.line3_font_size_em,
        height: 50,
        id: returnNewId(),
        inpoint: o.inpoint,
        layer: 1,
        text: o.line3,
        text_align: o.line3_align,
        text_background: false,
        text_background_alpha: 0.3,
        text_background_border_em: 0,
        text_background_border_rgba: "rgba(0,0,0,1)",
        text_background_color_rgba: "rgba(0,0,0,1)",
        text_background_radius_em: 0,
        text_color_rgba: "rgba(255,255,255,1)",
        text_shadow_color_rgba: "rgba(0, 0, 0, 0.3)",
        text_vertical_align: "bottom",
        timing_mode: 0,
        transition_seconds: o.transition_seconds,
        type: "text_color",
        width: 80,
        x: 10,
        y: 40
      });
    }
  });

  return _migratedOverlayArray;
};
