<template lang="html">
  <div ref="frame" class="frame" :style="frameStyle">
    <div class="title-overlay" :style="fontStyleComputed">
      <div class="line1-container">
        <h1 class="line1-line" :style="line1Style">{{ config.line1 }}</h1>
      </div>
      <div class="line2-container">
        <h1 class="line2-line" :style="line2Style">{{ config.line2 }}</h1>
      </div>
      <div class="line3-container">
        <h1 class="line3-line" :style="line3Style">{{ config.line3 }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import WebFont from "webfontloader";
export default {
  name: "TrialWatermark",
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  computed: {
    config() {
      return this.item;
    },
    frameStyle() {
      return {
        backgroundColor: `rgba(0, 0, 0, ${this.config.background_alpha})`
      };
    },
    line1Style() {
      return {
        "font-family": this.config.line1_font_family,
        "font-size": this.config.line1_font_size_em + "em",
        "text-align": this.config.line1_align,
        "text-shadow": `0.02em 0.02em rgba(0, 0, 0, 1),
          0 0 0.25em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 0.5em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 0.75em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 1em rgba(0, 0, 0, ${this.config.text_background_alpha})`
      };
    },
    line2Style() {
      return {
        "font-family": this.config.line2_font_family,
        "font-size": this.config.line2_font_size_em + "em",
        "text-align": this.config.line2_align,
        "text-shadow": `0.02em 0.02em rgba(0, 0, 0, 1),
          0 0 0.25em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 0.5em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 0.75em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 1em rgba(0, 0, 0, ${this.config.text_background_alpha})`
      };
    },
    line3Style() {
      return {
        "font-family": this.config.line3_font_family,
        "font-size": this.config.line3_font_size_em + "em",
        "text-align": this.config.line3_align,
        "text-shadow": `0.02em 0.02em rgba(0, 0, 0, 1),
          0 0 0.25em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 0.5em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 0.75em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 1em rgba(0, 0, 0, ${this.config.text_background_alpha})`
      };
    },
    fontStyleComputed() {
      // This should exist on every overlay, as it enables
      // us to share css with the mlt-based overlays.
      var displayWidth = this.$parent.computed_width;
      var displayHeight = this.$parent.computed_height;
      if (displayHeight > displayWidth) {
        // Vertical (likely mobile).
        console.log("vertical", displayHeight, displayWidth);
      }
      if (displayWidth > 1920) {
        // Practical size maximum, also the max in MLT for now.
        displayWidth = 1920;
      }
      // Removing 'practical' size minimums, as I'm not exactly sure why they are there.
      //if (displayWidth < 480) {
      //  // Practical size minimum, MLT minimum is 720 FYI.
      //  displayWidth = 480;
      //}
      var defaultSize = displayWidth * 0.018 + "px";
      return {
        fontSize: defaultSize
      };
    }
  },
  mounted() {
    WebFont.load({
      google: {
        families: [
          this.config.line1_font_family,
          this.config.line2_font_family,
          this.config.line3_font_family
        ]
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.frame {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: lightblue;
}
.title-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: "Lora", cursive;
  color: white;
  width: 100%;
  height: 100%;
  z-index: 2;
  //background-color: pink;
  // Make lowerthird text unselectable.
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  h1,
  h2 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    color: white;
    overflow: visible;
    font-weight: normal;
    -webkit-transform: translateZ(0);
  }
  .line1-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .line1-line {
      position: absolute;
      margin: auto;
      text-align: center;
      font-size: 1.2em;
      line-height: 1.5em;
      text-shadow: 0.02em 0.02em rgba(0, 0, 0, 0.6);
      white-space: pre-line;
      padding-left: 10%;
      padding-right: 10%;
      width: 80%;
      top: 8%;
      height: auto;
    }
  }

  .line2-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .line2-line {
      position: absolute;
      margin: auto;
      text-align: center;
      font-size: 1.2em;
      line-height: 1.5em;
      text-shadow: 0.02em 0.02em rgba(0, 0, 0, 0.6);
      white-space: pre-line;
      padding-left: 10%;
      padding-right: 10%;
      width: 80%;
      top: 50%;
      height: auto;
      transform: translateY(-50%);
    }
  }

  .line3-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .line3-line {
      position: absolute;
      margin: auto;
      text-align: center;
      font-size: 1.2em;
      line-height: 1.5em;
      text-shadow: 0.02em 0.02em rgba(0, 0, 0, 0.6);
      white-space: pre-line;
      padding-left: 10%;
      padding-right: 10%;
      width: 80%;
      bottom: 10%;
      height: auto;
    }
  }
}
</style>
