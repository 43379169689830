<template lang="html">
  <div>
    <h2>ForageImporter</h2>
    <p>
      ForageImporter loads a show from forage (if available) and emits
      "onLoaded" with the blobUrls and show object.
    </p>
    <p>
      <button @click="loadShow">Load</button>
    </p>
    <ObjectPrint :data="showModel" title="showModel" />
    <ObjectPrint :data="blobUrls" title="blobUrls" />
  </div>
</template>

<script>
import localForage from "localforage";
import { Show } from "@/models/showModel";
import { returnBlobbedFileObject } from "@/utils/blobFiles";
import each from "lodash/each";
export default {
  name: "ForageImporter",
  props: {
    id: {
      type: String,
      default: () => "forageId"
    }
  },
  data() {
    return {
      forage: null,
      showModel: {},
      blobUrls: {}
    };
  },
  mounted() {
    this.forage = localForage.createInstance({
      name: this.$store.state.forageDatabaseId
    });
  },
  methods: {
    loadShow() {
      console.log("loadShow");
      return this.forage
        .getItem(this.id)
        .then(v => {
          console.log(v);
          if (!v) {
            this.$emit("onNullLoaded");
            return Promise.reject("No show in forage.");
          }
          var show = v.show;
          each(show.data.images, i => {
            var key = "metadataImages_" + i.filename;
            if (i.width && i.height) {
              show[key] = {
                caption: "",
                datetime_original: "2016:09:10 22:42:15",
                filename: i.filename,
                height: i.height,
                metadata_available: true,
                width: i.height
              };
            }
            console.log(key);
          });

          this.showModel = new Show(show);
        })
        .then(() => {
          return this.blobShow();
        })
        .then(() => {
          this.callback();
        })
        .catch(e => {
          console.warn(e);
        });
    },
    blobShow() {
      // Returns a promise.
      return returnBlobbedFileObject(this.showModel, this.forage).then(
        blobUrls => {
          this.blobUrls = blobUrls;
          this.blobsLoaded = true;
          return;
        }
      );
    },
    callback() {
      console.log("callback, onLoaded");
      this.$emit("onLoaded", {
        blobUrls: this.blobUrls,
        show: this.showModel.show
      });
    }
  }
};
</script>

<style lang="css" scoped></style>
