export const soundslides_html = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width,initial-scale=1" />
    <link rel=icon href=/favicon.ico>
    <title>{{ data.title }}</title>
    <link
      href="https://d2t0i63awygu06.cloudfront.net/{{ playerVersion }}/app.js"
      rel="preload"
      as="script"
    />
    {{{ scriptTag }}}
    {{{ audioTag }}}

  </head>

  <body>
    <noscript>
      <strong>We're sorry but this web presentation doesn't work properly without
        JavaScript enabled. Please enable it to continue.</strong>

        <div>
          <p>
            <audio controls>
              <source src="assets/audio_hi.mp3" type="audio/mpeg">
              Your browser does not support the audio element.
            </audio>
          </p>
        {{#each data.slideArray}}
          <p>
            <img src="assets/{{this.file}}" />
          </p>
        {{/each }}
        </div>

      </noscript>
    <div id="app"></div>
    <script src="https://d2t0i63awygu06.cloudfront.net/{{ playerVersion }}/app.js"></script>
  </body>
</html>
`;
