var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:'frame' + _vm.overlay.id,staticClass:"frame",style:(_vm.frameStyle),on:{"click":_vm.outerClick}},[(_vm.displayWidth > 0 && _vm.displayHeight > 0)?_c('div',{key:'text_edit_' + _vm.overlay.id + _vm.displayWidth + _vm.displayHeight,staticClass:"title-overlay",style:(_vm.fontStyleComputed)},[_c('div',{staticClass:"line-container",style:(_vm.positionComputed),on:{"click":_vm.clickDrag}},[_c('vue-draggable-resizable',{key:_vm.overlay.id,ref:'dragText' + _vm.overlay.id,staticClass:"draggable-pointer-on",class:{
          'draggable-override-border': !_vm.editing,
          'draggable-text-drag-in-progress': _vm.dragging,
          'draggable-inner-border': _vm.editing,
          moveTool: _vm.toolValue == 'move',
          textTool: _vm.toolValue == 'text' || _vm.textEditing,
          otherTools: _vm.toolValue != 'text' && _vm.toolValue != 'move'
        },attrs:{"w":_vm.initialWidth,"h":_vm.initialHeight,"active":_vm.editing,"prevent-deactivation":false},on:{"update:active":function($event){_vm.editing=$event},"dragging":_vm.onDrag,"resizing":_vm.onResize,"dragstop":_vm.clickDrag}},[_c('div',{ref:"textContainer",staticClass:"text-container",style:(_vm.textContainerStyle)},[_c('h1',{staticClass:"avoid-shortkey-class flex-center-vertically",class:{ unselectable: !_vm.textEditing },style:(_vm.lineStyle),attrs:{"contenteditable":_vm.textEditing,"spellcheck":"false"},domProps:{"textContent":_vm._s(_vm.editing_text)},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"keyup":_vm.onKeyUp,"dblclick":_vm.onDblClick}})])])],1)]):_vm._e(),(false)?_c('div',{staticClass:"debug"},[_vm._v(" "+_vm._s(_vm.editing)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }