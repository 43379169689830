<template lang="html">
  <vue-slider
    v-model="time_per_image_input"
    :min="min_time_per_image"
    :max="max_time_per_image"
    :interval="0.1"
    tooltip="always"
    :tooltip-formatter="time_per_formatter"
    :lazy="true"
    :height="8"
    :dot-size="20"
    class="mr-3 mb-4 override"
    :adsorb="true"
    :marks="sliderMarks"
    :tooltip-style="tooltipStyle"
  />
</template>

<script>
//
//var toolTipColor = "#495057";
//toolTipColor = "rgba(149, 10, 155)";
import isFinite from "lodash/isFinite";
import max from "lodash/max";

export default {
  name: "AutoTimeSlider",
  data() {
    return {
      time_per_image_input: 3,
      min_time_per_image: 1,
      //max_time_per_image: 30,
      tooltipStyle: {
        //"border-color": toolTipColor,
        //"background-color": toolTipColor,
        color: "white",
        "font-size": "12px",
        padding: "3px 8px",
        "border-radius": "0px"
      }
    };
  },
  computed: {
    show() {
      return this.$store.state.show.currentShow;
    },
    show_audio() {
      if (this.show.data) {
        return this.show.data.audio;
      }
      return null;
    },
    max_time_per_image() {
      console.log(this.all_images_spread_value);
      return max([Math.ceil(this.all_images_spread_value), 3]);
    },
    all_images_spread_value() {
      var numImages = this.$store.state.show.currentShow.data.images.length;
      var all_images_spread_value = this.show_audio.duration / numImages;
      if (isFinite(all_images_spread_value)) {
        // In most cases, this value can be calculated.
        return all_images_spread_value;
      } else {
        // Use a reasonable default when all of the user's files failed to upload
        // (or user deleted them all)
        return 3;
      }
    },
    sliderMarks() {
      var value = this.all_images_spread_value;
      if (value > this.min_time_per_image && value < this.max_time_per_image) {
        return {
          [this.all_images_spread_value]: {
            label: `Use full audio length`,
            labelStyle: {
              "font-size": "10px"
            }
          }
        };
      }

      return {};
    }
  },
  watch: {
    time_per_image_input(value) {
      if (value == this.$store.state.show.currentShow.data.autoTimePerImage) {
        return;
      }
      this.$store.dispatch("show/updateAutoTime", value);
    }
  },
  mounted() {
    this.time_per_image_input =
      this.$store.state.show.currentShow.data.autoTimePerImage ||
      this.all_images_spread_value;
  },
  methods: {
    time_per_formatter(value) {
      return `${value.toFixed(1)} secs per image`;
    }
  }
};
</script>

<style lang="css" scoped></style>
