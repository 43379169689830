<template lang="html">
  <div>
    <h2>Soundslides Lite - Auto Times</h2>
    <hr />
    <h1>Hi there! 😀</h1>

    <p>This web application ...</p>
    <ul>
      <li>imports up to 20 jpeg images and a single mp3 audio file.</li>
      <li>
        spreads all images evenly according to a “seconds per image” setting.
      </li>
      <li>
        exports to a zip file, containing a
        <strong>locally-playable HTML file</strong>, which you can
        <strong>host yourself</strong>
        on a static web server or bucket.
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped></style>
