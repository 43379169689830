import each from "lodash/each";
import map from "lodash/map";
import filter from "lodash/filter";
import { Show } from "@/models/showModel";
import { createManifest } from "@/store/modules/export/manifest";
import imageReducer from "image-blob-reduce";

import localForage from "localforage";
const STORENAME = "001";
const FORAGE = localForage.createInstance({
  name: STORENAME
});
//const SHOWLISTKEY = "__shows";
const FIRST_RUN_BYPASS_KEY = "bypassInitialInfoUntil";

import JSZip from "jszip";
import { saveAs } from "file-saver";

const reducer = imageReducer();
import router from "@/router";

/*
reducer._create_blob = function(env) {
  return this.pica
    .toBlob(env.out_canvas, "image/jpeg", 0.9)
    .then(function(blob) {
      env.out_blob = blob;
      return env;
    });
};
*/

const EXPORT_ZIP_TEXT = "Writing zip archive ...";
const EXPORT_RESIZE_IMAGES = "Resizing images ...";
const EXPORT_MANIFEST_TEXT = "Creating manifest ...";

const CREATOR_ID = "001";
const CREATOR_URL = "https://apps.tungite.com/001";

const returnZipName = title => {
  return `${CREATOR_ID}_${title.replace(" ", "_")}.zip`;
};

export default {
  namespaced: true,
  state: {
    offlineShowBlobbed: false,
    offlineEditLoaded: false,
    forage: FORAGE,
    blobUrls: {},
    zipBlob: null,
    exportInProgress: false,
    exportProgressText: EXPORT_RESIZE_IMAGES,
    exportProgress: 50,
    displayFirstRunInfo: false,
    audioBase64: null,
    includeAudioAsDataUri: false,
    playerVersion: "4.2.0"
  },

  getters: {},
  mutations: {
    setOfflineShowBlobbed(state, value) {
      state.offlineShowBlobbed = value;
    },
    setBlobs(state, blobs) {
      state.blobUrls = blobs;
    }
  },
  actions: {
    resizeImagesToForage({ state, rootState }, maxImagePx) {
      // Get everything together and save to a zip.
      console.log("resizeImagesToForage");
      console.log(state);
      state.exportInProgress = true;
      state.exportProgress = 0;
      state.exportProgressText = EXPORT_RESIZE_IMAGES;

      var slideArray = rootState.show.currentShow.data.slideArray;
      var itemsResized = 1;

      // Check to see which keys are available.
      return state.forage.keys().then(keys => {
        console.log(keys);

        var promises = map(slideArray, s => {
          var sizedFilename = `${maxImagePx}/${s.file}`;
          console.log("sizedFilename", sizedFilename);
          if (keys.includes(sizedFilename)) {
            console.log(sizedFilename, "already created.");
            return;
          }

          return state.forage
            .getItem(s.file)
            .then(i => {
              return reducer.toBlob(i, {
                max: maxImagePx
              });
            })
            .then(i => {
              itemsResized++;
              state.exportProgress =
                (itemsResized / (slideArray.length + 1)) * 100;
              console.log("Foraging ", s.file);
              return state.forage.setItem(`${maxImagePx}/${s.file}`, i);
            });
        });

        return Promise.all(promises);
      });
    },
    exportToManifest({ state, rootState }, maxImagePx) {
      // Get everything together and save to a zip.
      console.log("exportToManifest");
      state.exportInProgress = true;
      state.exportProgress = 0;
      state.exportProgressText = EXPORT_MANIFEST_TEXT;
      console.log(state);
      if (maxImagePx) {
        // Pass the size to the manifest.
        return createManifest(state, rootState, maxImagePx); // It's a promise.
      } else {
        // Use fullsize images.
        return createManifest(state, rootState); // It's a promise.
      }
    },
    exportToZipFromManifest({ state }) {
      console.log("exportToZipFromManifest");
      state.exportProgressText = EXPORT_ZIP_TEXT;
      var zipName;
      var zip = new JSZip();
      return state.forage
        .getItem("manifest")
        .then(manifest => {
          console.log(manifest);
          zipName = returnZipName(manifest.title);
          // Make the asset folder.
          var dirname = manifest.asset_dir;
          zip.folder(dirname);
          // Add all the items from the manifest.
          var promises = map(manifest.exportItems, item => {
            return state.forage.getItem(item.foragePath).then(i => {
              console.log("Adding", item.outputPath);
              if (item.convertToJson) {
                zip.file(item.outputPath, JSON.stringify(i));
              } else {
                zip.file(item.outputPath, i);
              }
            });
          });
          return Promise.all(promises);
        })
        .then(() => {
          zip
            .generateAsync({ type: "blob", streamFiles: true }, metadata => {
              //console.log(metadata);
              //console.log(metadata.percent);
              state.exportProgress = metadata.percent;
            })
            .then(function(blob) {
              console.log("saveAs!!!!");
              saveAs(blob, zipName);
              state.exportInProgress = false;
            });
        });
    },
    cleanUpForageMedia({ rootState }) {
      console.log("cleanUpForageMedia");
      var validFilenames = map(
        rootState.show.currentShow.data.images,
        "filename"
      );
      validFilenames.push(rootState.show.currentShow.data.audio.filename);
      validFilenames.push("theme");
      validFilenames.push(FIRST_RUN_BYPASS_KEY);
      validFilenames.push(rootState.forageDataShowId);
      FORAGE.keys()
        .then(function(keys) {
          // An array of all the key names.
          var itemsToRemove = filter(keys, k => {
            if (validFilenames.includes(k)) {
              return false;
            }
            if (k.includes("/")) {
              var unsizedFilename = k.split("/")[1];
              if (validFilenames.includes(unsizedFilename)) {
                return false;
              }
            }
            return true;
          });

          console.log("itemsToRemove", itemsToRemove);
          var promises = map(itemsToRemove, filename => {
            return FORAGE.removeItem(filename);
          });
          Promise.all(promises).then(values => {
            console.log(`${values.length} cleaned up.`);
          });
        })
        .catch(function(err) {
          // This code runs if there were any errors
          console.log(err);
        });
    },
    saveOfflineShowEdit({ state, rootState }) {
      var showId = rootState.forageDataShowId;
      console.log("saveOfflineShowEdit", showId);
      var show = {
        show: rootState.show.currentShow,
        creatorId: CREATOR_ID,
        creatorUrl: CREATOR_URL
      };
      return state.forage.setItem(showId, show).then(v => {
        console.log("saved!");
        console.log(v);
        return v;
      });
    },
    loadFirstRunBypassCheck({ state, dispatch }) {
      FORAGE.getItem(FIRST_RUN_BYPASS_KEY).then(v => {
        if (!v) {
          v = 0;
        }
        var today = new Date();
        if (v < today.getTime()) {
          state.displayFirstRunInfo = true;
        }
        if ("info" in router.currentRoute.query) {
          dispatch("hideFirstRunInfoForDays", 0).then(() => {
            state.displayFirstRunInfo = true;
          });
        }
      });
      console.log(window.location.hostname);
    },
    hideFirstRunInfo({ state }) {
      console.log("hideFirstRunInfo");
      state.displayFirstRunInfo = false;
    },
    hideFirstRunInfoForDays({ state }, numDays) {
      state.displayFirstRunInfo = false;
      var date = new Date();
      date.setDate(date.getDate() + numDays);
      FORAGE.setItem(FIRST_RUN_BYPASS_KEY, date.getTime());
    },
    makeInitialEdit({ commit, dispatch, state }) {
      var images = [];
      var mockShowData = {
        title: "Untitled Show",
        key: "local___",
        data: {
          images: images,
          overlayArray: [],
          slideArray: [],
          audio: {
            deployed: true,
            duration: 216.8514739229025,
            filename: "1604584707592_audio.mp3",
            name: "1603375849176_audio.mp3",
            source_id: "GhH6gVZ5",
            waveformData: true
          },
          autoTimeEnabled: true,
          autoTimePerImage: 3,
          displaySettings: {
            audioDurationOverride: 60,
            audioDurationOverrideEnabled: true,
            globalSlideSortOrder: "custom",
            globalTransitionTime: 1,
            globalTransitionType: "crossfade",
            aspectRatio: 3 / 2
          }
        }
      };

      // Write all the metadata to "metadataImages_"
      each(mockShowData.data.images, i => {
        mockShowData["metadataImages_" + i.id] = {
          filename: i.filename,
          width: i.width,
          height: i.height
        };
      });

      // Blob the example audio file.
      var audioFile = mockShowData.data.audio;
      var audioUrl = `https://cdn3.soundslides.com/audio/${audioFile.source_id}/${audioFile.filename}`;
      return fetch(audioUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then(i => {
          return state.forage.setItem(audioFile.filename, i);
        })
        .then(() => {
          var showModel = new Show(mockShowData);
          showModel.updateTimingArrays();
          commit("show/setCurrentShow", showModel.show, { root: true });
          state.offlineEditLoaded = true;
          return dispatch("saveOfflineShowEdit");
        })
        .catch(error => {
          console.log("Error with the Fetch and Zip operation", error);
        });
    }
  }
};
