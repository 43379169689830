import localForage from "localforage";
const STORENAME = "001";
const FORAGE = localForage.createInstance({
  name: STORENAME
});
const SHOWLISTKEY = "__shows";

import { returnRandomKey } from "@/utils/randomKey";

export default {
  namespaced: true,
  state: {
    shows: []
  },
  getters: {},
  mutations: {
    setShows(state, shows) {
      console.log("setShows", shows);
      state.shows = shows;
    }
  },
  actions: {
    newShow({ state }) {
      console.log("New show");
      var createdAt = new Date().getTime();
      var id = returnRandomKey(11);
      var newShow = {
        id: id,
        title: "Untitled Show",
        createdAt: createdAt,
        updatedAt: createdAt,
        deleted: false,
        model: {},
        modelCreated: false,
        library: []
      };
      FORAGE.setItem(id, newShow)
        .then(v => {
          console.log(v);
          state.shows.push(v);
          return FORAGE.setItem(SHOWLISTKEY, state.shows);
        })
        .then(v => {
          console.log(v);
        });
    },
    getShows({ commit }) {
      // Return the saved listed of shows.
      console.log("Get show list here.");
      FORAGE.getItem("__shows").then(v => {
        if (v) {
          commit("setShows", v);
        } else {
          commit("setShows", []);
        }
      });
    }
  }
};
