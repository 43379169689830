import map from "lodash/map";
import each from "lodash/each";

function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

export const returnSlideArrayFromTxt = txt_string => {
  console.log("returnSlideArrayFromTxt");
  var data = parseQuery(txt_string);
  console.log(data);
  var slideArray = map(data.t.split("^"), s => {
    var image_data = s.split("|");
    return {
      caption: image_data[2] === undefined ? "" : image_data[2],
      end_percent_scale: 100,
      end_percent_x: 0,
      end_percent_y: 0,
      file: image_data[1],
      start_percent_scale: 100,
      start_percent_x: 0,
      start_percent_y: 0,
      timing: parseInt(image_data[0], 10),
      transition:
        image_data[3] === undefined || image_data[3] === ""
          ? "inherit"
          : image_data[3],
      transition_duration:
        image_data[4] === undefined || image_data[4] === ""
          ? "inherit"
          : image_data[4]
    };
  });
  return slideArray;
};

export const returnLowerthirdsArrayFromTxt = txt_string => {
  console.log("returnLowerthirdsArrayFromXml");
  var data = parseQuery(txt_string);
  var ltArray;
  if (!("lta" in data)) {
    return [];
  }
  var lowerthirds = data.lta.split("^");
  ltArray = map(lowerthirds, lt => {
    var item = lt.split("|");
    return {
      name: item[0] || "",
      title: item[1] || "",
      inpoint: parseInt(item[2], 10),
      duration: parseInt(item[3], 10)
    };
  });
  return ltArray;
};

export const returnShowMetaDataFromTxt = txt_string => {
  console.log("returnShowMetaData");
  var data = parseQuery(txt_string);
  console.log(data);
  var meta = {};
  each(data.n.split("^"), i => {
    var item = i.split("|");
    meta[item[0]] = item[1];
  });
  return meta;
};
