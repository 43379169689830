import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import findIndex from "lodash/findIndex";
import isUndefined from "lodash/isUndefined";
import round from "lodash/round";

const DEFAULT_X = 20;
const DEFAULT_Y = 20;
const DEFAULT_WIDTH = 55;
const DEFAULT_HEIGHT = 14;
const DEFAULT_TEXT = "Double-click to edit";
const DEFAULT_RECT_BACKGROUND_COLOR = "rgba(0,0,0,1)";

export const returnNewId = () => {
  return Math.random()
    .toString(36)
    .substring(7);
};

export const returnNewTextOverlay = obj => {
  // Return new text overlay at x, y position on highest available layer.
  if (isUndefined(obj.inpoint)) {
    alert("Inpoint required");
    return;
  }
  var inpoint = obj.inpoint;
  var x = obj.x;
  var y = obj.y;
  if (isUndefined(x)) {
    x = DEFAULT_X;
  }
  if (isUndefined(y)) {
    y = DEFAULT_Y;
  }
  var layer = obj.layer;
  var newId = returnNewId();

  return {
    background_alpha: 0,
    duration: 4,
    font_family: "Lato",
    font_size_em: 3,
    height: DEFAULT_HEIGHT,
    id: newId,
    inpoint: inpoint,
    layer: layer,
    text_align: "center",
    text_background_alpha: 0.3,
    text_background_border_em: 0,
    text_background_border_rgba: "rgba(0,0,0,1)",
    text_background_color_rgba: "rgba(0,0,0,1)",
    text_background_radius_em: 0,
    text_background: false,
    text_color_rgba: "rgba(255,255,255,1)",
    text_shadow_color_rgba: "rgba(0,0,0,0.3)",
    text_vertical_align: "middle",
    text: DEFAULT_TEXT,
    timing_mode: 1,
    timing_mode_slide_filename: obj.slideFilename,
    transition_seconds: 0.5,
    type: "text_color",
    width: DEFAULT_WIDTH,
    x: x,
    y: y
  };
};

export const returnNewShapeOverlay = obj => {
  // Return new text overlay at x, y position on highest available layer.
  if (isUndefined(obj.inpoint)) {
    alert("Inpoint required");
    return;
  }
  var inpoint = obj.inpoint;
  var x = obj.x;
  var y = obj.y;
  var width = obj.width;
  var height = obj.height;
  if (isUndefined(x)) {
    x = DEFAULT_X;
  }
  if (isUndefined(y)) {
    y = DEFAULT_Y;
  }
  if (isUndefined(width)) {
    width = DEFAULT_WIDTH;
  }
  if (isUndefined(height)) {
    height = DEFAULT_HEIGHT;
  }
  var layer = obj.layer;
  var newId = returnNewId();

  return {
    background_alpha: 0,
    duration: 4,
    font_family: "Lato",
    font_size_em: 3,
    height: height,
    id: newId,
    inpoint: inpoint,
    layer: layer,
    text_align: "center",
    text_background_alpha: 0.3,
    text_background_border_em: 0,
    text_background_border_rgba: "rgba(0,0,0,1)",
    text_background_color_rgba: DEFAULT_RECT_BACKGROUND_COLOR,
    text_background_radius_em: 0,
    text_background: true,
    text_color_rgba: "rgba(255,255,255,1)",
    text_shadow_color_rgba: "rgba(0,0,0,0.3)",
    text_vertical_align: "middle",
    text: "",
    timing_mode: 1,
    timing_mode_slide_filename: obj.slideFilename,
    transition_seconds: 0.5,
    type: "rect",
    width: width,
    x: x,
    y: y
  };
};

export const returnOverlaysSyncedToSlides = (_overlayArray, show_data) => {
  var overlayArray = cloneDeep(_overlayArray);
  /*
  Look through it and find overlays that are timing_mode == "slide"
  and update them their inpoints to match overlay.timing_mode_slide_filename,
  and duration is for how many overlay.timing_mode.

  timing_mode:
    -1: Show overlay on all slides
    0: Use exact inpoint/durations
    > 0: Use on N many slides

  */
  var audio_duration = show_data.audio.duration;
  if (show_data.displaySettings.audioDurationOverrideEnabled) {
    audio_duration = show_data.displaySettings.audioDurationOverride;
  }
  var last_overlay_padding_seconds = 1;
  each(overlayArray, o => {
    // console.log("overlay", o);
    if (o.timing_mode > 0) {
      var number_slides = o.timing_mode;
      // Sync the overlay to a specific number of slides.
      var indexInShow = findIndex(show_data.slideArray, {
        file: o.timing_mode_slide_filename
      });
      // console.log("indexInShow", indexInShow);
      if (indexInShow >= 0) {
        var inpoint = round(
          show_data.slideArray[indexInShow].timing / 1000 -
            o.transition_seconds,
          1
        );
        o.inpoint = inpoint < 0 ? 0 : inpoint;
        var lastSlideForOverlay = indexInShow + number_slides;
        if (lastSlideForOverlay < show_data.slideArray.length) {
          o.duration = round(
            show_data.slideArray[lastSlideForOverlay].timing / 1000 -
              o.inpoint -
              o.transition_seconds,
            1
          );
        } else {
          o.duration = round(
            audio_duration - o.inpoint + last_overlay_padding_seconds,
            1
          );
        }
      }
    }
    if (o.timing_mode == -1) {
      // Sync the overlay to all slides.
      o.inpoint = 0;
      o.duration = audio_duration + last_overlay_padding_seconds;
    }
  });
  return overlayArray;
};
