<template lang="html">
  <select v-model="selected" class="select">
    <option v-for="option in options" :key="option.value" :value="option.value">
      {{ option.text }}
    </option>
  </select>
</template>

<script>
import find from "lodash/find";
export default {
  name: "GlobalTransitionsDropdown",
  data() {
    return {
      options: [
        {
          value: "straight-cut",
          text: "No Transitions",
          name: "straight cut",
          time_ms: 0
        },
        {
          value: "cf-slow",
          text: "Crossfade - Slow",
          name: "crossfade",
          time_ms: 1500
        },
        {
          value: "cf-medium",
          text: "Crossfade - Medium",
          name: "crossfade",
          time_ms: 750
        },
        {
          value: "cf-fast",
          text: "Crossfade - Fast",
          name: "crossfade",
          time_ms: 300
        },

        {
          value: "fofi-slow",
          text: "Fade out/Fade in - Slow",
          name: "fade out/fade in",
          time_ms: 1500
        },
        {
          value: "fofi-medium",
          text: "Fade out/Fade in - Medium",
          name: "fade out/fade in",
          time_ms: 750
        },
        {
          value: "fofi-fast",
          text: "Fade out/Fade in - Fast",
          name: "fade out/fade in",
          time_ms: 300
        }
      ]
    };
  },
  computed: {
    selected: {
      get() {
        var displaySettings =
          this.$store.state.show.currentShow.data.displaySettings || {};
        return displaySettings.globalTransitionPreset || "cf-medium";
      },
      set(value) {
        console.log("set", value);
        var newPreset = find(this.options, { value: value });
        var update = {
          globalTransitionTime: newPreset.time_ms / 1000,
          globalTransitionType: newPreset.name,
          globalTransitionPreset: newPreset.value
        };
        this.$store.dispatch("show/updateGlobalTransitions", update);
        console.log("newPreset", newPreset);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
select {
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  color: var(--text-color);
  margin-right: 10px;
  padding: 4px 9px;
  padding-left: 5px;
  font-size: 0.8em;
  background: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  border: 1px solid var(--ui-border-color);
}
select:focus {
  outline: 0;
}
</style>
