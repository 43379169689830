<template lang="html">
  <div>
    <h1>Error.</h1>
  </div>
</template>

<script>
export default {
  name: "Error",
  mounted() {
    throw new Error("Sentry syntheticException");
  }
};
</script>

<style lang="css" scoped></style>
