<template lang="html">
  <div class="lowerthird" :style="fontStyleComputed">
    <div :class="text_container_style_name">
      <h1>{{ name }}</h1>
      <h2 v-if="title.length > 0">{{ title }}</h2>
      <div class="background" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Lowerthird",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    controlsAutoHide: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    name() {
      return this.item.name;
    },
    title() {
      return this.item.title;
    },
    text_container_style_name() {
      if (this.controlsAutoHide) {
        return "text_container text_container_overlaid";
      } else {
        return "text_container";
      }
    },
    fontStyleComputed() {
      // This should exist on every overlay, as it enables
      // us to share css with the mlt-based overlays.
      var displayWidth = this.$parent.computed_width;
      if (displayWidth > 1920) {
        // Practical size maximum, also the max in MLT for now.
        displayWidth = 1920;
      }
      if (displayWidth < 640) {
        // Practical size minimum, MLT minimum is 720 FYI.
        displayWidth = 640;
      }
      var defaultSize = displayWidth * 0.018 + "px";
      return {
        fontSize: defaultSize
      };
    }
  }
};
</script>

<style lang="scss" scoped>
// All details need to use em's for sharing with .mlt
.lowerthird {
  position: absolute;
  font-family: Arial, Helvetica, Sans-Serif;
  color: white;
  width: 100%;
  height: 100%;
  bottom: 8%;

  //background-color: pink;
  // Make lowerthird text unselectable.
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  .text_container {
    position: absolute;
    bottom: 0px;
    left: 8%;
    margin-right: 16%;
    width: auto;
    font-size: 1em;
    -webkit-font-smoothing: subpixel-antialiased;
    font-smooth: auto;
    -moz-osx-font-smoothing: auto;
    color: white;
    text-shadow: 0.07em 0.07em rgba(0, 0, 0, 0.6);
    h1,
    h2 {
      position: relative;
      z-index: 1;
      line-height: 1.2em;
    }
    h2 {
      margin-top: -0.5em;
    }
    .background {
      position: absolute;
      z-index: 0;
      top: 0.3em;
      left: -5%;
      width: 114%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      //background-color: pink;
      -webkit-filter: blur(15px);
      -moz-filter: blur(15px);
      -o-filter: blur(15px);
      -ms-filter: blur(15px);
      filter: blur(15px);
    }
  }
}
// This lower part is not included in the standard overlay,
// and must remain here.
.lowerthird {
  .text_container_overlaid {
    // Bottom magic, not used in MLT overlay (because there's no command bar)
    // Very small bump, which is tied to the font-size.
    bottom: 0.4em !important;
  }
}
</style>
