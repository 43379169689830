export const getBlobDuration = blob => {
  const tempVideoEl = document.createElement("video");

  const durationP = new Promise(resolve =>
    tempVideoEl.addEventListener("loadedmetadata", () => {
      // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
      if (tempVideoEl.duration === Infinity) {
        tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER;
        tempVideoEl.ontimeupdate = () => {
          tempVideoEl.ontimeupdate = null;
          resolve(tempVideoEl.duration);
          tempVideoEl.currentTime = 0;
        };
      }
      // Normal behavior
      else resolve(tempVideoEl.duration);
    })
  );

  if (typeof blob === "string" || blob instanceof String) {
    tempVideoEl.src = blob;
  } else {
    tempVideoEl.src = window.URL.createObjectURL(blob);
    console.warn("Made a new blob!!!!");
  }

  return durationP;
};

export const getBlobDimensions = blob => {
  const tempImageEl = new Image();
  console.log("getBlobDimensions");
  const dimensions = new Promise(resolve =>
    tempImageEl.addEventListener("load", () => {
      console.log("load");
      var dims = {
        height: tempImageEl.naturalHeight,
        width: tempImageEl.naturalWidth
      };
      // Normal behavior
      resolve(dims);
    })
  );

  if (typeof blob === "string" || blob instanceof String) {
    tempImageEl.src = blob;
  } else {
    tempImageEl.src = window.URL.createObjectURL(blob);
    console.warn("Made a new blob!!!!");
  }

  return dimensions;
};
