import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store/index";

/*
// Set BootstrapVue globally.
import BootstrapVue from "bootstrap-vue";
import "./css/bootstrap-overrides.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
*/

/* import theme style */
import "./css/overrides.scss";

// Global components.
import ObjectPrint from "@/components/ObjectPrint.vue";
Vue.component("ObjectPrint", ObjectPrint);

// vue-slider-component
import VueSlider from "vue-slider-component";
// Slider style is in bootstrap-overrides.scss

Vue.component("VueSlider", VueSlider);

// vue-draggable-resizable
import VueDraggableResizable from "vue-draggable-resizable";
// optionally import default styles
import "vue-draggable-resizable/dist/VueDraggableResizable.css";
Vue.component("vue-draggable-resizable", VueDraggableResizable);

//shortkey
import shortkey from "vue-shortkey";
Vue.use(shortkey, { prevent: ["input", "textarea", ".avoid-shortkey-class"] });

import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://7a6fffff842c4aa5b860b1eed6c08c80@o228083.ingest.sentry.io/5532440",
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true
      })
    ],
    release: process.env.VUE_APP_GIT_REV
  });
}

Vue.filter("toHHMMSS", function(sec) {
  var sec_num = parseInt(sec, 10); // don't forget the second parm
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  var Ominutes = minutes + "";
  var Oseconds = seconds + "";
  if (minutes < 10) {
    Ominutes = "0" + minutes;
  }
  if (seconds < 10) {
    Oseconds = "0" + seconds;
  }
  if (hours == 0) {
    if (minutes < 10) {
      return minutes + ":" + Oseconds;
    }
    return Ominutes + ":" + Oseconds;
  }
  return hours + ":" + Ominutes + ":" + Oseconds;
});

Vue.filter("truncate", function(text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});

Vue.filter("pluralize", function(word, amount) {
  if (amount > 1) {
    return `${word}s`;
  }
  return word;
});

// font-awesome via vue-awesome
// Import only the icons you need.
/*
import "vue-awesome/icons/align-center";
import "vue-awesome/icons/align-left";
import "vue-awesome/icons/align-right";
import "vue-awesome/icons/arrow-down";
import "vue-awesome/icons/arrow-up";
import "vue-awesome/icons/arrows-alt";
import "vue-awesome/icons/check-circle";
import "vue-awesome/icons/check";
import "vue-awesome/icons/circle-notch";
import "vue-awesome/icons/circle";
import "vue-awesome/icons/cloud-upload-alt";
import "vue-awesome/icons/code";
import "vue-awesome/icons/cog";
import "vue-awesome/icons/copy";
import "vue-awesome/icons/download";
import "vue-awesome/icons/edit";
import "vue-awesome/icons/exclamation-triangle";
import "vue-awesome/icons/external-link-alt";
import "vue-awesome/icons/file-audio";
import "vue-awesome/icons/file-import";
import "vue-awesome/icons/file-video";
import "vue-awesome/icons/flask";
import "vue-awesome/icons/globe-africa";
import "vue-awesome/icons/globe-americas";
import "vue-awesome/icons/globe-asia";
import "vue-awesome/icons/headphones";
import "vue-awesome/icons/heart";
import "vue-awesome/icons/home";
import "vue-awesome/icons/i-cursor";
import "vue-awesome/icons/images";
import "vue-awesome/icons/info-circle";
import "vue-awesome/icons/info";
import "vue-awesome/icons/link";
import "vue-awesome/icons/lock";
import "vue-awesome/icons/long-arrow-alt-down";
import "vue-awesome/icons/long-arrow-alt-up";
import "vue-awesome/icons/magic";
import "vue-awesome/icons/mouse-pointer";
import "vue-awesome/icons/music";
import "vue-awesome/icons/pause";
import "vue-awesome/icons/play";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/regular/heart";
import "vue-awesome/icons/share";
import "vue-awesome/icons/square";
import "vue-awesome/icons/stopwatch";
import "vue-awesome/icons/stream";
import "vue-awesome/icons/times-circle";
import "vue-awesome/icons/tools";
import "vue-awesome/icons/trash-alt";
import "vue-awesome/icons/tv";
import "vue-awesome/icons/upload";
import "vue-awesome/icons/video";
import "vue-awesome/icons/volume-up";
import "vue-awesome/icons/window-close";
import "vue-awesome/icons/window-close";
*/

import "vue-awesome/icons/times-circle";

import Icon from "vue-awesome/components/Icon";
Vue.component("v-icon", Icon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
