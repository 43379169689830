<template lang="html">
  <vue-plyr v-if="ready" ref="thePlayer" :options="plyrOptions">
    <audio id="playerAudioElement">
      <source :src="audio_url" type="audio/mp3" />
    </audio>
    <PlyrCSSOverride />
  </vue-plyr>
</template>

<script>
import { returnControlTemplate } from "./custom_control_template";
import PlyrCSSOverride from "./PlyrCSSOverride";
import without from "lodash/without";
import VuePlyr from "@/components/VuePlyr";
export default {
  name: "Controls",
  components: {
    VuePlyr,
    PlyrCSSOverride
  },
  props: {
    audio_url: {
      type: String,
      default: () => ""
    },
    has_captions: {
      type: Boolean,
      default: () => false
    },
    has_fullscreen: {
      type: Boolean,
      default: () => true
    },
    captions_active: {
      type: Boolean,
      default: () => false
    },
    custom_duration: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      controlsTemplateOptions: [
        "restart",
        "image_captions",
        "mute",
        "click_navigation",
        "fullscreen"
      ],
      plyrOptions: {
        settings: ["captions", "quality", "loop"],
        autopause: true,
        resetOnEnd: true,
        volume: 1,
        iconUrl: "https://cdn3.soundslides.com/svg/plyr.svg",
        controls: "" // set after data loads
      },
      ready: false
    };
  },
  computed: {
    player() {
      return this.$refs["thePlayer"].player;
    },
    isFullscreen() {
      return (
        document.fullscreenElement || document.webkitCurrentFullScreenElement
      );
    }
  },
  watch: {
    isFullscreen() {
      console.log("fullscreen change!");
    },
    captions_active(value) {
      console.log("toggleCaptions");
      this.updateCaptionUI(value);
    }
  },
  mounted() {
    // Remove captions if !has_captions
    if (!this.has_captions) {
      this.controlsTemplateOptions = without(
        this.controlsTemplateOptions,
        "image_captions"
      );
    }
    // Remove fullscreen if !has_fullscreen
    if (!this.has_fullscreen) {
      this.controlsTemplateOptions = without(
        this.controlsTemplateOptions,
        "fullscreen"
      );
    }
    // Custom duration.
    if (this.custom_duration != 0) {
      this.plyrOptions.duration = this.custom_duration;
    }
    this.plyrOptions.controls = returnControlTemplate(
      this.controlsTemplateOptions
    );
    this.ready = true;
    this.$nextTick(this.controlSetup);
    // Watch for fullscreen change.
    if (document.onfullscreenchange === null)
      document.onfullscreenchange = this.handleFullscreenChange;
    else if (document.onmsfullscreenchange === null)
      document.onmsfullscreenchange = this.handleFullscreenChange;
    else if (document.onmozfullscreenchange === null)
      document.onmozfullscreenchange = this.handleFullscreenChange;
    else if (document.onwebkitfullscreenchange === null)
      document.onwebkitfullscreenchange = this.handleFullscreenChange;
    // Emit that player has mounted in the DOM.
    this.$nextTick(() => {
      this.updateCaptionUI(this.captions_active);
      this.$emit("controlsMounted");
    });
  },
  methods: {
    updateCaptionUI(value) {
      console.log("updateCaptionUI");
      if (!this.has_captions) {
        return false;
      }

      // If there's an element in fullscreen, exit otherwise, enter it.
      var $btn_el = this.$el.querySelector("#image_captions");
      var $exit = $btn_el.querySelector(".exit_captions");
      var $enter = $btn_el.querySelector(".enter_captions");
      var $tooltip = $btn_el.querySelector(".caption_tooltip");
      if (!value) {
        this.showImageCaptions = false;
        $enter.style.visibility = "hidden";
        $enter.style.position = "absolute";
        $exit.style.visibility = "visible";
        $exit.style.position = "relative";
        $tooltip.innerHTML = "Show captions";
      } else {
        this.showImageCaptions = true;
        $exit.style.visibility = "hidden";
        $exit.style.position = "absolute";
        $enter.style.visibility = "visible";
        $enter.style.position = "relative";
        $tooltip.innerHTML = "Hide captions";
      }
    },
    controlSetup() {
      // Plyr buttons.
      var $next = this.$el.querySelector("#next_image");
      if ($next) {
        $next.onclick = () => {
          this.$emit("nextImage");
        };
      }
      var $prev = this.$el.querySelector("#previous_image");
      if ($prev) {
        $prev.onclick = () => {
          this.$emit("previousImage");
        };
      }
      // Special controls, buttons.
      // fullscreen
      var $fullscreen = this.$el.querySelector("#fullscreen");
      if ($fullscreen) {
        var $enter_fullscreen = $fullscreen.querySelector(".enter_fullscreen");
        $enter_fullscreen.style.visibility = "hidden";
        $enter_fullscreen.style.position = "absolute";
        $fullscreen.onclick = this.toggleFullscreen;
      }

      // image_captions
      var $image_captions = this.$el.querySelector("#image_captions");
      if ($image_captions) {
        var $enter_captions = $image_captions.querySelector(".enter_captions");
        $enter_captions.style.visibility = "hidden";
        $enter_captions.style.position = "absolute";
        $image_captions.onclick = this.toggleCaptions;
      }
    },
    handleFullscreenChange() {
      // Handles all UI and image sizing issues related to Fullscreen.
      // Triggered by onfullscreenchange listener.
      var $fullscreen = this.$el.querySelector("#fullscreen");
      if (!$fullscreen) {
        return false;
      }
      var $exit_fullscreen = $fullscreen.querySelector(".exit_fullscreen");
      var $enter_fullscreen = $fullscreen.querySelector(".enter_fullscreen");
      var $tooltip = $fullscreen.querySelector(".fullscreen_tooltip");
      if (document.fullscreenElement) {
        //
        $exit_fullscreen.style.visibility = "hidden";
        $exit_fullscreen.style.position = "absolute";
        $enter_fullscreen.style.visibility = "visible";
        $enter_fullscreen.style.position = "relative";
        $tooltip.innerHTML = "Exit fullscreen";
      } else {
        // No fullscreen.
        $enter_fullscreen.style.visibility = "hidden";
        $enter_fullscreen.style.position = "absolute";
        $exit_fullscreen.style.visibility = "visible";
        $exit_fullscreen.style.position = "relative";
        $tooltip.innerHTML = "Enter fullscreen";
      }
    },
    toggleFullscreen() {
      this.$emit("fullscreen");
    },
    toggleCaptions() {
      this.$emit("toggleCaptions");
    }
  }
};
</script>

<style lang="css" scoped></style>
