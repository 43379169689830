import map from "lodash/map";

export const returnSlideArrayWithMovementData = slideArray => {
  return map(slideArray, slide => {
    var start_percent_scale = parseFloat(slide.start_percent_scale) || 100;
    var start_percent_x = parseFloat(slide.start_percent_x) || 0;
    var start_percent_y = parseFloat(slide.start_percent_y) || 0;
    var end_percent_scale = parseFloat(slide.end_percent_scale) || 100;
    var end_percent_x = parseFloat(slide.end_percent_x) || 0;
    var end_percent_y = parseFloat(slide.end_percent_y) || 0;

    var has_motion = true;
    if (
      start_percent_scale === 100 &&
      start_percent_x === 0 &&
      start_percent_y === 0 &&
      end_percent_scale === 100 &&
      end_percent_x === 0 &&
      end_percent_y === 0
    ) {
      has_motion = false;
    }

    slide.start_percent_scale = start_percent_scale;
    slide.start_percent_x = start_percent_x;
    slide.start_percent_y = start_percent_y;
    slide.end_percent_scale = end_percent_scale;
    slide.end_percent_x = end_percent_x;
    slide.end_percent_y = end_percent_y;
    slide.has_motion = has_motion;

    return slide;
  });
};
