<template lang="html">
  <div class="main main-background">
    <div class="info">
      <h1>Re-import a zip</h1>
      <p>You can re-import any saved Tungite Apps' zip file.</p>
      <p>
        Importing a zip file will replace your current edit in that application.
      </p>
      <ZipToForageImporter
        :id="$store.state.forageDataShowId"
        @onError="error"
        @onLoaded="loaded"
      />
    </div>
  </div>
</template>

<script>
import ZipToForageImporter from "@/components/TMA/ZipToForageImporter";
export default {
  name: "ReImportZip",
  components: {
    ZipToForageImporter
  },
  methods: {
    loaded() {
      this.$router.push({ name: "editor" });
    },
    error(item) {
      console.log("we got an error", item);
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.main-background {
  background-image: repeating-linear-gradient(
      90deg,
      hsla(253, 0%, 96%, 0.05) 0px,
      hsla(253, 0%, 96%, 0.05) 1px,
      transparent 1px,
      transparent 104px
    ),
    repeating-linear-gradient(
      0deg,
      hsla(253, 0%, 96%, 0.05) 0px,
      hsla(253, 0%, 96%, 0.05) 1px,
      transparent 1px,
      transparent 104px
    ),
    repeating-linear-gradient(
      0deg,
      hsla(253, 0%, 96%, 0.07) 0px,
      hsla(253, 0%, 96%, 0.07) 1px,
      transparent 1px,
      transparent 26px
    ),
    repeating-linear-gradient(
      90deg,
      hsla(253, 0%, 96%, 0.07) 0px,
      hsla(253, 0%, 96%, 0.07) 1px,
      transparent 1px,
      transparent 26px
    ),
    linear-gradient(45deg, hsl(213, 62%, 51%), hsl(213, 62%, 51%));
  background-color: rgb(249, 66, 6);
}

.info {
  max-width: 38rem;
  padding: 2rem;
  margin: auto;
  color: white;
}
</style>
