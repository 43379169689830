
export const returnControlTemplate = (options) => {
  // Based on metaDict values, return the control bar custom template.
  var _template = '<div class="plyr__controls">';

  if(options.includes("restart")){
    _template = _template + restartTemplate;
  }

  _template = _template + standardTemplate;

  if(options.includes("mute")){
    _template = _template + muteTemplate;
  }

  if(options.includes("volume")){
    _template = _template + volumeTemplate;
  }

  if(options.includes("click_navigation")){
    _template = _template + clickNavigation;
  }

  if(options.includes("image_captions")){
    _template = _template + captionTemplate;
  }
  if(options.includes("fullscreen")){
    _template = _template + fullscreenTemplate;
  }
  return _template + '</div>';
}

const restartTemplate = `
<button type="button" class="plyr__control" data-plyr="restart">
  <svg role="presentation"><use xlink:href="#plyr-restart"></use></svg>
  <span class="plyr__tooltip" role="tooltip">Restart</span>
</button>
`;

const clickNavigation = `
<button id="previous_image" type="button" class="plyr__control">
  <svg role="presentation"><use xlink:href="#plyr-rewind"></use></svg>
  <span class="plyr__tooltip" role="tooltip">Previous image</span>
</button>
<button id="next_image" type="button" class="plyr__control">
  <svg role="presentation"><use xlink:href="#plyr-fast-forward"></use></svg>
  <span class="plyr__tooltip" role="tooltip">Next image</span>
</button>
`

const standardTemplate = `

  <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
    <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-pause"></use></svg>
    <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-play"></use></svg>
    <span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>
    <span class="label--not-pressed plyr__tooltip" role="tooltip">Play</span>
  </button>

  <div class="plyr__progress">
    <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
    <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
    <span role="tooltip" class="plyr__tooltip">00:00</span>
  </div>
  <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
  <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>

`;

const muteTemplate = `
<button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
  <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-muted"></use></svg>
  <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-volume"></use></svg>
  <span class="label--pressed plyr__tooltip" role="tooltip">Unmute</span>
  <span class="label--not-pressed plyr__tooltip" role="tooltip">Mute</span>
</button>
`

const volumeTemplate = `
<div class="plyr__volume">
  <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
</div>
`;


const captionTemplate = `

  <button id="image_captions" type="button" class="plyr__control">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="5" height="14" viewBox="0 0 5 14" class="icon--not-pressed exit_captions" role="presentation">
      <path d="M5 10.5v1q0 0.203-0.148 0.352t-0.352 0.148h-4q-0.203 0-0.352-0.148t-0.148-0.352v-1q0-0.203 0.148-0.352t0.352-0.148h0.5v-3h-0.5q-0.203 0-0.352-0.148t-0.148-0.352v-1q0-0.203 0.148-0.352t0.352-0.148h3q0.203 0 0.352 0.148t0.148 0.352v4.5h0.5q0.203 0 0.352 0.148t0.148 0.352zM4 1.5v1.5q0 0.203-0.148 0.352t-0.352 0.148h-2q-0.203 0-0.352-0.148t-0.148-0.352v-1.5q0-0.203 0.148-0.352t0.352-0.148h2q0.203 0 0.352 0.148t0.148 0.352z"></path>
    </svg>
    <span class="label--not-pressed plyr__tooltip caption_tooltip" role="tooltip">Show captions</span>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12" height="14" viewBox="0 0 12 14" class="icon--not-pressed enter_captions" role="presentation">
      <path d="M8 10.75v-1.25q0-0.109-0.070-0.18t-0.18-0.070h-0.75v-4q0-0.109-0.070-0.18t-0.18-0.070h-2.5q-0.109 0-0.18 0.070t-0.070 0.18v1.25q0 0.109 0.070 0.18t0.18 0.070h0.75v2.5h-0.75q-0.109 0-0.18 0.070t-0.070 0.18v1.25q0 0.109 0.070 0.18t0.18 0.070h3.5q0.109 0 0.18-0.070t0.070-0.18zM7 3.75v-1.25q0-0.109-0.070-0.18t-0.18-0.070h-1.5q-0.109 0-0.18 0.070t-0.070 0.18v1.25q0 0.109 0.070 0.18t0.18 0.070h1.5q0.109 0 0.18-0.070t0.070-0.18zM12 7q0 1.633-0.805 3.012t-2.184 2.184-3.012 0.805-3.012-0.805-2.184-2.184-0.805-3.012 0.805-3.012 2.184-2.184 3.012-0.805 3.012 0.805 2.184 2.184 0.805 3.012z"></path>
    </svg>
  </button>
`;

const fullscreenTemplate = `
  <button id="fullscreen" type="button" class="plyr__control">
    <svg class="icon--not-pressed exit_fullscreen" role="presentation"><use xlink:href="#plyr-enter-fullscreen"></use></svg>
    <span class="label--not-pressed plyr__tooltip fullscreen_tooltip" role="tooltip">Enter fullscreen</span>
    <svg class="icon--not-pressed enter_fullscreen" role="presentation"><use xlink:href="#plyr-exit-fullscreen"></use></svg>
  </button>
`;
