import isUndefined from "lodash/isUndefined";
import filter from "lodash/filter";
import { returnTrialDaysRemaining, returnPlanFromUserDoc } from "@/utils/plans";
import * as Sentry from "@sentry/browser";
import { approvedBrowsers } from "@/defaults";

export default {
  set_user: function(state, user) {
    if (user == null || isUndefined(user)) {
      state.user = {};
      state.authorized = false;
    } else {
      // We have an authenticated user.
      state.user = user;
      state.authorized = true;
      Sentry.configureScope(scope => {
        scope.setUser({
          email: state.user.email,
          id: state.user.uid
        });
      });
    }
    state.auth_initialized = true;
  },
  set_shows: function(state, shows) {
    console.log("set_shows", shows);
    state.legacy_shows = shows;
    if (state.legacy_shows.length > 0) {
      state.displayLegacyVideoNav = true;
    }
  },
  set_hosted_shows: function(state, shows) {
    console.log("set_hosted_shows");
    state.hosted_shows = shows;
  },
  set_editor_shows: function(state, shows) {
    console.log("set_editor_shows");
    state.editor_shows = state.editor_shows.concat(shows);
    state.editor_shows_loaded = true;
  },
  set_user_prefs: function(state, user_doc) {
    // Set user doc, add latest_forcasts to each sub.
    // Set trial data.
    state.trialDaysRemaining = returnTrialDaysRemaining(user_doc);
    if (state.trialDaysRemaining > 0) {
      state.validTrialPeriod = true;
    } else {
      state.validTrialPeriod = false;
    }
    // Set user_plan.
    state.user_plan = returnPlanFromUserDoc(user_doc);

    state.imageImportLimit = state.user_plan.image_limit;
    state.videoImportLimit = state.user_plan.video_limit;
    state.videoImportLimitSeconds =
      state.user_plan.video_duration_limit_seconds;

    // See if the user has their own defined limits.

    if (user_doc.imageImportLimit) {
      state.imageImportLimit = user_doc.imageImportLimit;
    }

    if (user_doc.videoImportLimit) {
      state.videoImportLimit = user_doc.videoImportLimit;
    }

    // Check to see if the user is subscribed and has an expired CC.
    if ((user_doc.stripe_summary || {}).stripe_card_expired) {
      state.stripe_card_expired = true;
    }

    // It's loaded!
    state.user_doc = user_doc;
    user_doc.loaded = true;
  },
  set_user_show_index: function(state, user_show_index) {
    var editor_shows = filter(user_show_index, (s, k) => {
      if (k.startsWith("editor_shows") && !s.deleted) {
        return s;
      }
    });
    state.user_show_total = editor_shows.length;

    state.user_show_index_loaded = true;
    state.user_show_index = user_show_index;
  },
  set_initial_email_preference: function(state, preferenceBoolean) {
    state.initialSignupEmailPreference = preferenceBoolean;
  },
  set_component_instance: (state, instance) => {
    state.componentInstance = instance;
  },
  setLegacyShowData: (state, shows) => {
    state.legacyShowData = shows;
    state.legacyShowDataLoaded = true;
  },
  setBowser: (state, _bowser) => {
    state.bowser = _bowser;
    // And check to changes are needed.
    if (
      _bowser.satisfies({
        mobile: {
          safari: ">=9"
        },
        tablet: {
          safari: ">=9"
        }
      })
    ) {
      console.log("Safari changes would go here if needed.");
    }
    console.log(state.bowser.getOSName());
    state.isApprovedBrowser = _bowser.satisfies(approvedBrowsers);
  },
  setServiceUnavailable: (state, isUnavailable) => {
    state.serviceUnavailable = isUnavailable;
  },
  setFluidTimeline: (state, value) => {
    state.fluidTimeline = value;
  }
};
