<template lang="html">
  <div class="info">
    <h1>Data flow for Soundslides based TMAs</h1>
    <ZipToForageImporter :id="$store.state.forageDataShowId" />
    <ForageImporter :id="$store.state.forageDataShowId" />
  </div>
</template>

<script>
import ZipToForageImporter from "@/components/TMA/ZipToForageImporter";
import ForageImporter from "@/components/TMA/ForageImporter";
export default {
  name: "Testing",
  components: {
    ZipToForageImporter,
    ForageImporter
  }
};
</script>

<style lang="css" scoped>
.info {
  max-width: 38rem;
  padding: 2rem;
  margin: auto;
  color: var(--modal-text-color);
}
</style>
