<template lang="html">
  <div class="title">
    <h1 v-show="text !== 'undefined'">{{ text }}</h1>
  </div>
</template>

<script>
export default {
  name: "Title",
  props:{
    text:{
      type: String,
      default: () => ""
    }
  }
}
</script>

<style lang="scss" scoped>
.title{
  position: absolute;
  top: 1%;
  left: 4%;
  font-family: Arial, sans-serif;
  width: 100%;
  height: auto;
  z-index: 700;
  color: white;
  text-shadow: 2px 2px 3px #000;
  // Make lowerthird text unselectable.
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  line-height: 2.5em;

  @media only screen and (max-width: 600px) {
    font-size: 0.6em;
    top: 3%;
    line-height: 3em;
  }
  @media only screen and (min-width: 600px) {
    font-size: 0.6em;
    top: 2%;
    line-height: 2.8em;
  }
  @media only screen and (min-width: 768px) {
    font-size: 0.8em;
  }
  @media only screen and (min-width: 992px) {
    font-size: 1em;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 1.2em;
  }

  -webkit-font-smoothing: antialiased;


  h1{
    position: relative;
    left: 0;
    width: 80%;
  }
}
</style>
