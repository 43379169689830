<template lang="html">
  <div />
</template>

<script>
export default {
  name: "PlyrCSSOverride",
  props: {
    mainColor: {
      type: String,
      default: () => "rgb(293, 70, 0)"
    },
    foregroundColor: {
      type: String,
      default: () => "#999"
    }
  },
  mounted() {
    var root = document.documentElement;
    root.style.setProperty("--main-color", this.mainColor);
    root.style.setProperty("--foreground-color", this.foregroundColor);
  }
};
</script>

<style lang="scss">
// Just a place to put unscoped scss related to plyr.
// Original color is #1aafff.

$plyr-color-main: #1aafff !default;
$plyr-color-button: white !default;

:root {
  --main-color: #1aafff;
  --foreground-color: white;
}

.plyr__controls {
  background: 0 !important;
  //background-color: none !important;
  //background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 1)) !important;
  button {
    color: $plyr-color-button;
    color: var(--foreground-color);
  }
}

.plyr__time {
  color: $plyr-color-button;
  color: var(--foreground-color);
}
.plyr__control:hover {
  background-color: $plyr-color-main !important;
  background-color: var(--main-color) !important;
}
.plyr__control.plyr__tab-focus {
  background-color: $plyr-color-main !important;
  background-color: var(--main-color) !important;
}
.plyr--full-ui input[type="range"] {
  color: $plyr-color-main !important;
  color: var(--main-color) !important;
}

.plyr--audio .plyr__progress {
  // Preload bg color.
  color: $plyr-color-main !important;
  color: var(--main-color) !important;
}

.plyr--video {
  button {
    background-color: $plyr-color-main;
    background-color: var(--main-color);
  }
}

.plyr--video .plyr__control svg {
  filter: none !important;
}
</style>
