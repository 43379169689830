import find from "lodash/find";
import range from "lodash/range";
import isEmpty from "lodash/isEmpty";

export class AutoCropSelection {
  constructor(
    imageWidthPx,
    imageHeightPx,
    targetAspectRatio,
    facesBoundaries,
    labelsBoundaries
  ) {
    // With the AutoCropSelection class, if both faces and labels are blank
    // then we give it some basic data to use.

    if (isEmpty(facesBoundaries) && isEmpty(labelsBoundaries)) {
      console.log("use fake labelsBoundaries");
      labelsBoundaries = {
        x: 0.4,
        y: 0.4,
        width: 0.2,
        height: 0.2
      };
    }

    // Check for huge boundary widths (which would make for very boring movements)
    if (labelsBoundaries.width > 0.9) {
      labelsBoundaries.x = 0.1;
      labelsBoundaries.width = 0.8;
    }
    // Check for huge boundary heights (which would make for very boring movements)
    if (labelsBoundaries.height > 0.9) {
      labelsBoundaries.y = 0.1;
      labelsBoundaries.height = 0.8;
    }

    this.faceCropObj = new AutoCropper(
      imageWidthPx,
      imageHeightPx,
      targetAspectRatio,
      facesBoundaries
    );
    this.labelCropObj = new AutoCropper(
      imageWidthPx,
      imageHeightPx,
      targetAspectRatio,
      labelsBoundaries
    );
  }

  speedPresets = {
    slow: 1.5,
    medium: 4,
    fast: 8
  };

  bestGuessBySpeedPresetAndSeconds(speedPresetString, seconds) {
    var targetPercentage = 100 - seconds * this.speedPresets[speedPresetString];
    return this.bestGuessByMinZoomAreaPercent(targetPercentage);
  }

  bestGuessByMinZoomAreaPercent(percentage_area) {
    // Use label unless it's not available.
    var start = this.labelCropObj.crop;

    var end;
    console.log(this.labelCropObj.minCropAreaPercentage, percentage_area);
    if (this.labelCropObj.minCropAreaPercentage < percentage_area) {
      console.log("using label");
      end = this.labelCropObj.returnCropByMinZoomAreaPercent(percentage_area);
    } else {
      console.log("using faces");
      end = this.faceCropObj.returnCropByMinZoomAreaPercent(percentage_area);

      // If using faces, and the percentage_area desired is large,
      // then it makes more sense to use the faces as start as well.

      if (percentage_area > 70) {
        start = this.faceCropObj.crop;
      }
    }
    return {
      start: start,
      end: end,
      areaDifference: Math.abs(
        start.resulting_area_from_crop - end.resulting_area_from_crop
      )
    };
  }
}

export class AutoCropper {
  constructor(imageWidthPx, imageHeightPx, targetAspectRatio, contentArea) {
    this.imageWidthPx = imageWidthPx;
    this.imageHeightPx = imageHeightPx;
    this.targetAspectRatio = targetAspectRatio;
    this.contentArea = contentArea;
    // Null out the contentArea if it is an empty object.
    if (isEmpty(this.contentArea)) {
      this.contentArea = null;
    }
    this.crop = this.addPositionAndScaleValues(this.returnCrop());
    this.maxCrop = this.addPositionAndScaleValues(
      this.returnMaxCropContainingContent()
    );
    // Add secondary data.
    this.crop = this.addAreaCalculations(this.crop);
    this.maxCrop = this.addAreaCalculations(this.maxCrop);
    this.minCropAreaPercentage = this.maxCrop.resulting_area_from_crop;
  }

  addPositionAndScaleValues(crop) {
    // Adds (position_x, position_y and scale) to the crop object.
    // These are the values used to position and scale the image inside the crop.
    var scale = 100 / crop.width / 100;
    crop.scale = scale;
    // Positions required by something like GSAP and TweenLite.
    crop.position_x = -crop.x * 100 * scale;
    crop.position_y = -crop.y * 100 * scale;

    // Positions required by CSS.
    crop.position_left = crop.position_x;
    var yscale = 100 / crop.height / 100;
    crop.position_top = -crop.y * 100 * yscale;

    // Soundslides Player "centered" coordinates.
    // Images are automatically centered, so there needs to be conversions.

    var centerValues = returnCoordinatesCenterBased(
      this.imageWidthPx,
      this.imageHeightPx,
      this.targetAspectRatio,
      crop.position_x,
      crop.position_y,
      crop.scale
    );

    crop.player_percent_x = centerValues.x;
    crop.player_percent_y = centerValues.y;
    crop.player_percent_scale = centerValues.scale;

    return crop;
  }

  addAreaCalculations(crop) {
    // Add percentage compared to original image.
    crop.resulting_area_from_original =
      (crop.height * 100 * crop.width * 100) / 100;
    crop.resulting_area_from_crop =
      (crop.height * 100 * crop.width * 100) /
      (this.crop.height * 100 * this.crop.width);
    return crop;
  }

  returnCropByMinZoomAreaPercent(percentage_area) {
    // Return a crop that does not exceed percentage_area% of the max crop.
    /*
    This differs from returnCropByZoomPercent in that it takes into consideration the
    amount of area. That's important when the faces or labeled areas are very small.
    This is the more preferred of the two functions.
    */

    var maxZoomPercent = find(range(100, 0, -1), n => {
      var _crop = this.returnCropByZoomPercent(n);
      if (_crop.resulting_area_from_crop >= percentage_area) {
        return true;
      } else {
        return false;
      }
    });

    if (maxZoomPercent) {
      return this.returnCropByZoomPercent(maxZoomPercent);
    } else {
      // Nothing found, return 100 percent.
      return this.returnCropByZoomPercent(0);
    }
  }

  returnCropByZoomPercent(percentage) {
    // Return crop zoom by percentage.
    var _crop = {
      x: this.crop.x - ((this.crop.x - this.maxCrop.x) * percentage) / 100,
      y: this.crop.y - ((this.crop.y - this.maxCrop.y) * percentage) / 100,
      width:
        this.crop.width -
        ((this.crop.width - this.maxCrop.width) * percentage) / 100,
      height:
        this.crop.height -
        ((this.crop.height - this.maxCrop.height) * percentage) / 100
    };
    return this.addAreaCalculations(this.addPositionAndScaleValues(_crop));
  }

  get cropContainsContentArea() {
    if (!this.contentArea) {
      // Obviously required contentArea to be defined.
      return false;
    }
    // Set shapes as tl-br.
    // Main crop.
    var a = {
      x1: this.crop.x,
      y1: this.crop.y,
      x2: this.crop.x + this.crop.width,
      y2: this.crop.y + this.crop.height
    };
    // Content area.
    var b = {
      x1: this.contentArea.x,
      y1: this.contentArea.y,
      x2: this.contentArea.x + this.contentArea.width,
      y2: this.contentArea.y + this.contentArea.height
    };
    return !(b.x1 < a.x1 || b.y1 < a.y1 || b.x2 > a.x2 || b.y2 > a.y2);
  }

  returnMaxCropContainingContent() {
    /*
    First check to see if cropContainsContentArea.
    We can only generate a max crop if there's space for a max crop.
    */
    if (!this.cropContainsContentArea) {
      return this.crop;
    }

    // Take the content crop, and check for exact fit in either direction.
    if (
      this.contentArea.width == this.crop.width ||
      this.contentArea.height == this.crop.height
    ) {
      // Max crop already exists, just return the crop.
      return this.crop;
    }

    // First try the width.

    var imageWidthPx = this.imageWidthPx;
    var imageHeightPx = this.imageHeightPx;
    var targetAspectRatio = this.targetAspectRatio;
    var contentArea = this.contentArea;

    var widthPx = contentArea.width * imageWidthPx;
    var heightPx = widthPx / targetAspectRatio;

    var width = widthPx / imageWidthPx;
    var height = heightPx / imageHeightPx;

    if (this.contentArea.height > height) {
      // height too tall, constrain with height.
      widthPx = contentArea.width * imageWidthPx;
      heightPx = widthPx / targetAspectRatio;
      heightPx = contentArea.height * imageHeightPx;
      widthPx = heightPx * targetAspectRatio;
    }

    width = widthPx / imageWidthPx;
    height = heightPx / imageHeightPx;

    var x = 0;
    var y = 0;

    var contentAdjustedX = x;

    contentAdjustedX =
      this.contentArea.x - (width - this.contentArea.width) / 2;
    if (contentAdjustedX + width > 1) {
      contentAdjustedX = 1 - width;
    }
    if (contentAdjustedX < 0) {
      contentAdjustedX = 0;
    }

    var contentAdjustedY = y;

    contentAdjustedY =
      this.contentArea.y - (height - this.contentArea.height) / 2;
    if (contentAdjustedY + height > 1) {
      contentAdjustedY = 1 - height;
    }
    if (contentAdjustedY < 0) {
      contentAdjustedY = 0;
    }

    x = contentAdjustedX;
    y = contentAdjustedY;

    return {
      x: x,
      y: y,
      width: width,
      height: height
    };
  }

  returnCrop() {
    var width, height, x, y;
    //var maxDimension = max([imageWidthPx, imageHeightPx]);

    width = 100;
    height = 100;
    x = 0;
    y = 0;

    var imageWidthPx = this.imageWidthPx;
    var imageHeightPx = this.imageHeightPx;
    var targetAspectRatio = this.targetAspectRatio;
    var contentArea = this.contentArea;

    var widthPx = imageWidthPx;
    var heightPx = imageWidthPx / targetAspectRatio;

    if (heightPx > imageHeightPx) {
      widthPx = imageHeightPx * targetAspectRatio;
      heightPx = imageHeightPx;
      if (widthPx > imageWidthPx) {
        console.warn(
          "new width exceeds source width now!!!",
          widthPx,
          imageWidthPx
        );
      }
    }

    x = (imageWidthPx - widthPx) / 2;
    y = (imageHeightPx - heightPx) / 2;

    // Convert all values to percentages.

    width = widthPx / imageWidthPx;
    height = heightPx / imageHeightPx;
    x = x / imageWidthPx;
    y = y / imageHeightPx;

    if (contentArea) {
      // Make adjustments for the content area.
      var contentAdjustedX = x;

      contentAdjustedX = contentArea.x - (width - contentArea.width) / 2;
      if (contentAdjustedX + width > 1) {
        contentAdjustedX = 1 - width;
      }
      if (contentAdjustedX < 0) {
        contentAdjustedX = 0;
      }

      var contentAdjustedY = y;

      contentAdjustedY = contentArea.y - (height - contentArea.height) / 2;
      if (contentAdjustedY + height > 1) {
        contentAdjustedY = 1 - height;
      }
      if (contentAdjustedY < 0) {
        contentAdjustedY = 0;
      }

      x = contentAdjustedX;
      y = contentAdjustedY;
    }

    return {
      x: x,
      y: y,
      width: width,
      height: height
    };
  }
}

const returnCoordinatesCenterBased = (
  imageWidth,
  imageHeight,
  aspectRatio,
  _x,
  _y,
  _scale
) => {
  var origin = {
    x: _x,
    y: _y,
    scale: _scale
  };

  var frameWidth = imageHeight * aspectRatio;

  var flip = false;
  if (frameWidth < imageWidth) {
    frameWidth = imageWidth;
    flip = true;
  }

  var scale = (frameWidth / imageWidth) * 100 * origin.scale;

  var pre_origin_x = origin.x;
  pre_origin_x = -((frameWidth - imageWidth) / imageWidth) * 50;

  // Add in origin.
  var x = pre_origin_x * origin.scale + origin.x;

  var pre_origin_y = (imageHeight - frameWidth / aspectRatio) / 2 / 100;

  var y = pre_origin_y + origin.y * ((imageHeight / imageWidth) * aspectRatio);

  if (flip) {
    var frameHeight = frameWidth / aspectRatio;
    pre_origin_y = -((frameHeight - imageHeight) / frameHeight / 2) * 100;
    var imageAspect = imageWidth / imageHeight;
    y = pre_origin_y * origin.scale + origin.y / imageAspect;
  }

  return {
    x: x,
    y: y,
    scale: scale
  };
};
