// Create manifest file functions.

import Handlebars from "handlebars";
import { soundslides_html } from "@/templates/soundslides_html.js";
import map from "lodash/map";

const CREATOR_ID = "001";
const CREATOR_URL = "https://apps.tungite.com/001";
const CREATOR_NAME = "Soundslides Lite - Auto Times";

const returnPublishedData = show_object => {
  var slideArray = show_object.data.slideArray.map(item => {
    delete item.id;
    return item;
  });
  var publishData = {
    slideArray: slideArray,
    audioFile: show_object.data.audio,
    key: show_object.key,
    publishedAt: new Date().getTime(),
    title: show_object.title,
    overlayArray: show_object.data.overlayArray,
    displaySettings: show_object.data.displaySettings,
    formatVersion: 4,
    imageSizes: [],
    imageDirectoryOverride: "assets",
    autoTimePerImage: show_object.data.autoTimePerImage,
    autoTimeEnabled: show_object.data.autoTimeEnabled,
    creatorId: CREATOR_ID,
    creatorUrl: CREATOR_URL,
    creatorName: CREATOR_NAME
  };
  return publishData;
};

const returnHtmlFileAsString = (state, jsonData) => {
  var template_html = Handlebars.compile(soundslides_html);

  var scriptTag = `
  <script>
  var showData = ${JSON.stringify(jsonData)}
  </script>
  `;

  /*
  if (state.includeAudioAsDataUri) {
    // Disabled for now. You can explore this idea later.

    var audioTag = `
    <script>
    var audioUri = "${state.audioBase64}";
    </script>
    `;

    soundslides_with_data_html = template_html({
      data: jsonData,
      scriptTag: scriptTag,
      audioTag: audioTag
    });
  }
  */

  return template_html({
    data: jsonData,
    scriptTag: scriptTag,
    playerVersion: state.playerVersion
  });
};

export const createManifest = (state, rootState, maxImagePx) => {
  // Create the manifest and store in forage instance.
  // Return a promise.

  var promises = [];

  var jsonData = returnPublishedData(rootState.show.currentShow);
  console.log(jsonData);

  promises.push(state.forage.setItem("data.json", JSON.stringify(jsonData)));

  var soundslides_with_data_html = returnHtmlFileAsString(state, jsonData);
  console.log(soundslides_with_data_html);

  promises.push(state.forage.setItem("index.html", soundslides_with_data_html));

  var slideArray = rootState.show.currentShow.data.slideArray;
  var manifest = {
    created: jsonData.publishedAt,
    title: jsonData.title,
    app_instance: jsonData.creatorId,
    app_url: jsonData.creatorUrl,
    app_name: jsonData.creatorName,
    asset_dir: "assets"
  };

  /*
  Export items are here.
  */
  var assetDirName = manifest.asset_dir;
  manifest.exportItems = map(slideArray, s => {
    var foragePath = s.file;
    if (maxImagePx) {
      foragePath = `${maxImagePx}/${s.file}`;
    }
    return {
      foragePath: foragePath,
      outputPath: `${assetDirName}/${s.file}`,
      type: "image/jpeg"
    };
  });

  // Add audio.
  manifest.exportItems.push({
    foragePath: rootState.show.currentShow.data.audio.filename,
    outputPath: `${assetDirName}/audio_hi.mp3`,
    type: "audio/mpeg"
  });
  // Add data.
  manifest.exportItems.push({
    foragePath: "data.json",
    outputPath: `${assetDirName}/data.json`,
    type: "application/json"
  });

  // Add index.
  manifest.exportItems.push({
    foragePath: "index.html",
    outputPath: `index.html`,
    type: "text/html"
  });

  // Add manifest.
  manifest.exportItems.push({
    foragePath: "manifest",
    outputPath: `${assetDirName}/manifest.json`,
    type: "application/json",
    convertToJson: true
  });

  // Add manifest.
  manifest.exportItems.push({
    foragePath: "active-show",
    outputPath: `${assetDirName}/active-show.json`,
    type: "application/json",
    convertToJson: true
  });

  /*
  Import items are here.
  These are directions for unzipping/importing the items back into forage for editing.
  */

  // Add images.
  manifest.importItems = map(slideArray, s => {
    return {
      foragePath: s.file,
      zipPath: `${assetDirName}/${s.file}`,
      type: "image/jpeg"
    };
  });

  // Add audio.
  manifest.importItems.push({
    foragePath: rootState.show.currentShow.data.audio.filename,
    zipPath: `${assetDirName}/audio_hi.mp3`,
    type: "audio/mpeg"
  });

  // Add the active-show object.
  manifest.importItems.push({
    foragePath: "active-show",
    zipPath: `${assetDirName}/active-show.json`,
    type: "application/json",
    convertToObject: true
  });

  // And save the manifest object.
  promises.push(state.forage.setItem("manifest", manifest));

  return Promise.all(promises);
};
