import { ensureArrayHasUniqueIds } from "@/models/utils/uniqueIds";
import { isPublishable } from "@/models/utils/publishingChecks";
import { parseEXIFDate } from "@/models/utils/datetimeUtils";
import { returnAutoMotionSlideArray } from "@/models/utils/autoTimeUtils";
import { returnMigratedOverlays } from "@/models/utils/legacyOverlays";
import { returnNewId } from "@/models/overlays";
import { returnNewShapeOverlay } from "@/models/overlays";
import { returnNewTextOverlay } from "@/models/overlays";
import { returnOverlaysSyncedToSlides } from "@/models/overlays";
import { returnRandomArray } from "@/models/utils/autoTimeUtils";
import { returnRandomKey } from "@/models/utils/randomKey";

import { MltShow } from "@/models/mlt_show";

import assign from "lodash/assign";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import filter from "lodash/filter";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import intersection from "lodash/intersection";
import isUndefined from "lodash/isUndefined";
import last from "lodash/last";
import map from "lodash/map";
import max from "lodash/max";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import round from "lodash/round";
import some from "lodash/some";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import without from "lodash/without";

const TIMING_MODE_ALWAYS_VISIBLE = -1;
const TIMING_MODE_UNLOCKED = 0;
const TIMELINE_PUSH_MINIMUM_SECONDS = 1;
const OVERLAY_MINIMUM_LAYER = 0;
const OCT_16_2019_MILLISECONDS = 1571234789033;

import transform from "lodash/transform";
import isObject from "lodash/isObject";
import isEqual from "lodash/isEqual";
import keys from "lodash/keys";
import flatten from "flat";

function difference(object, base) {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? difference(value, base[key])
          : value;
    }
  });
}

export class Show {
  constructor(_show, _serverUpdateCallback) {
    this.show = _show;
    this.preChangeShowObject = cloneDeep(_show);
    this.serverUpdateCallback = () => {};
    this.activeItem = {};
    this.timelinePushMode = false;
    this.additionalUpdatedKeys = [];
    this.lastCustomOrderedFilenames = [];
    this.faceData = {};
    this.labelData = {};
    if (_serverUpdateCallback) {
      this.serverUpdateCallback = _serverUpdateCallback;
    }
    this.foundMissingDeployedImage = false;
    this.checkForMigrations();
  }

  get data() {
    return this.show.data;
  }

  get slideArray() {
    return this.data.slideArray || [];
  }

  get images() {
    return this.data.images || [];
  }
  get overlayArray() {
    return this.data.overlayArray || [];
  }
  get displaySettings() {
    return this.data.displaySettings || {};
  }

  get groupedSlideIds() {
    return this.show.groupedSlideIds || [];
  }

  get groupedOverlayIds() {
    return this.show.groupedOverlayIds || [];
  }

  get aspectRatio() {
    return this.data.displaySettings.aspectRatio || 3 / 2;
  }

  get safeToRender() {
    // Do a blob check for all images and audio first.
    if (this.show.blobbed) {
      // Blobbed shows are rendered immediately.
      return true;
    }

    if (!this.audioDeployed) {
      return false;
    }
    if (this.show.created_at < OCT_16_2019_MILLISECONDS) {
      // Old show, assume all deployed.
      return true;
    }
    if (!this.allImagesDeployed) {
      return false;
    }
    if (!this.allVideosDeployed) {
      return false;
    }
    return true;
  }

  get audioDeployed() {
    if (!("audio" in this.data)) {
      return false;
    }
    if (!("deployed" in this.data.audio)) {
      // Old show doc that does not have deployed property in audio map.
      // Assume all images & audio are loaded (as we did before Oct. 16th, 2019).
      return true;
    }
    return this.data.audio.deployed == true;
  }

  get undeployedImages() {
    var imagesDeployed = filter(this.show, (s, k) => {
      if (k.startsWith("staticImages1600Deployed_")) {
        return s;
      }
    });
    var imageNames = map(
      filter(this.images, s => {
        return s.type != "video";
      }),
      "filename"
    );
    return reject(imageNames, i => {
      return imagesDeployed.includes(i);
    });
  }

  get allImagesDeployed() {
    var imagesDeployed = filter(this.show, (s, k) => {
      if (k.startsWith("staticImages1600Deployed_")) {
        return s;
      }
    });
    var imageNames = map(
      filter(this.images, s => {
        return s.type != "video";
      }),
      "filename"
    );
    // Are all current images deployed.
    return imageNames.every(item => imagesDeployed.includes(item));
  }

  get imagesMissingFromArrays() {
    var imagesDeployed = filter(this.show, (s, k) => {
      if (k.startsWith("staticImages1600Deployed_")) {
        return s;
      }
    });
    var imageNames = map(
      filter(this.images, s => {
        return s.type != "video";
      }),
      "filename"
    );
    var deletedImageNames = map(
      filter(this.show.staticImagesRemovedByUser, s => {
        return s.type != "video";
      }),
      "filename"
    );
    var removedImageNames = map(
      filter(this.show.staticImagesErrorRemoved, s => {
        return s.type != "video";
      }),
      "filename"
    );
    var removedClearedNames = map(
      filter(this.show.staticImagesErrorRemovedCleared, s => {
        return s.type != "video";
      }),
      "filename"
    );

    var allUsedFilenames = imageNames.concat(deletedImageNames);

    // Remove error'd images from the deployed list.
    imagesDeployed = without(imagesDeployed, ...removedImageNames);
    imagesDeployed = without(imagesDeployed, ...removedClearedNames);

    return filter(imagesDeployed, i => {
      return !allUsedFilenames.includes(i);
    });
  }

  correctAnyMissingDeployedImages() {
    if (this.imagesMissingFromArrays.length > 0) {
      console.warn("-- >>> Found missing deployed images!");
      this.foundMissingDeployedImage = true;
      console.log(this.imagesMissingFromArrays);
      each(this.imagesMissingFromArrays, filename => {
        this.images.push({
          filename: filename,
          name: filename.substring(filename.indexOf("_") + 1),
          source_id: this.show.key
        });
      });
    }
  }

  get undeployedVideos() {
    var videosDeployed = map(
      filter(this.show, (s, k) => {
        if (k.startsWith("staticMp4Deployed_")) {
          return s;
        }
      }),
      "filename"
    );
    var videoNames = map(
      filter(this.images, s => {
        return s.type == "video";
      }),
      "filename"
    );
    return reject(videoNames, i => {
      return videosDeployed.includes(i);
    });
  }

  get undeployedAssets() {
    var undeployed = [];
    if (this.show.created_at < OCT_16_2019_MILLISECONDS) {
      // Old show, assume all deployed.
      return [];
    }
    if (this.show.data.audio.deployed != true) {
      undeployed.push(this.show.data.audio.filename);
    }
    return undeployed
      .concat(this.undeployedImages)
      .concat(this.undeployedVideos);
  }

  get allVideosDeployed() {
    var videosDeployed = map(
      filter(this.show, (s, k) => {
        if (k.startsWith("staticMp4Deployed_")) {
          return s;
        }
      }),
      "filename"
    );
    var videoNames = map(
      filter(this.images, s => {
        return s.type == "video";
      }),
      "filename"
    );
    // Are all current videos deployed.
    return videoNames.every(item => videosDeployed.includes(item));
  }

  get imageMetadataArray() {
    // All metadata, ordered by datetime_original ascending.
    var _show = cloneDeep(this.show);
    return orderBy(
      filter(_show, (s, k) => {
        if (k.startsWith("metadataImages_")) {
          s.datetime_inaccurate_TZ = parseEXIFDate(s.datetime_original);
          return s;
        }
      }),
      [i => i.datetime_inaccurate_TZ.getTime()],
      "asc"
    );
  }

  get updatedKeys() {
    // Computes a 2 level comparison and returns (flattened) keys that have changed.
    // Firestore can take partial flattened key'd updates.
    var base = this.preChangeShowObject;
    var object = this.show;
    var differenceObject = transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? difference(value, base[key])
            : value;
      }
    });

    // Define how changed keys get updated.

    var rootKeysToUpdateAsGroup = [
      "staticImagesRemovedByUser",
      "mlt_video_slideArray",
      "mlt_video_overlay_array",
      "mlt_video_arguments",
      "staticAudioErrorRemoved",
      "staticImagesErrorRemoved",
      "staticMp3Deployed_",
      "groupedSlideIds",
      "groupedOverlayIds"
    ];

    var secondLevelKeysToUpdateAsGroup = ["data.displaySettings"];

    var thirdLevelKeysToUpdateAsGroup = [
      "data.slideArray",
      "data.overlayArray",
      "data.images",
      "data.audio"
    ];

    var flatObjLevel1Keys = keys(
      flatten(cloneDeep(differenceObject), { maxDepth: 1 })
    );
    flatObjLevel1Keys = filter(flatObjLevel1Keys, i => {
      // Include items that are in the root array lists.
      // These items will always be updated as a group.
      return some(rootKeysToUpdateAsGroup, k => i.startsWith(k));
    });

    var flatObjLevel2Keys = keys(
      flatten(cloneDeep(differenceObject), { maxDepth: 2 })
    );
    flatObjLevel2Keys = filter(flatObjLevel2Keys, i => {
      // Filter out items that are in the primary array lists.
      // These items will NOT always be updated as a group.
      var _keys = rootKeysToUpdateAsGroup.concat(
        secondLevelKeysToUpdateAsGroup
      );
      return !some(_keys, k => i.startsWith(k));
    });

    var flatObjLevel3Keys = keys(
      flatten(cloneDeep(differenceObject), { maxDepth: 3 })
    );

    flatObjLevel3Keys = filter(flatObjLevel3Keys, i => {
      // Filter out items that are in the primary array lists.
      // These items will always be updated as a group.
      var _keys = rootKeysToUpdateAsGroup.concat(thirdLevelKeysToUpdateAsGroup);
      return !some(_keys, k => i.startsWith(k));
    });

    return uniq(
      flatObjLevel2Keys
        .concat(flatObjLevel3Keys)
        .concat(flatObjLevel1Keys)
        .concat(this.additionalUpdatedKeys)
        .sort()
    );
  }

  get jsonUpdateNeeded() {
    // Compare show to prechanged show and return if changes warrant an update of the json file.
    // Mostly needed for non-private shows.
    var base = this.preChangeShowObject;
    var object = this.show;
    if (base.privacyState !== object.privacyState) {
      // State changed, we need an update.
      return true;
    }
    if (object.privacyState !== "private") {
      // Show isn't private, we need an update.
      return true;
    }
    return false;
  }

  get requiresRefresh() {
    // Look at the updatedKeys.
    var keysRequiringRefresh = [
      "data.images",
      "data.slideArray",
      "data.autoTimePerImage",
      "data.displaySettings.globalTransitionTime",
      "data.displaySettings.globalTransitionType",
      "data.displaySettings.globalTransitionPreset",
      "data.displaySettings.globalMotionPreset",
      "data.displaySettings.aspectRatio",
      "data.displaySettings.globalBackgroundColor",
      "data.displaySettings.globalSlideBackgroundColor"
    ];

    if (intersection(keysRequiringRefresh, this.updatedKeys).length > 0) {
      console.log("Simple key intersected, returning early requiresRefresh");
      return true;
    }

    // Now check to see if the overlays have changes, and if so, what kind of change.

    // First, check to see if the overlayArray has changed length.
    if (
      this.preChangeShowObject.data.overlayArray.length !=
      this.overlayArray.length
    ) {
      // Big overlay change, this requires a refresh.
      return true;
    }

    // If not a change in length, you'll need to see if the change is minor.
    // There are some keys that do not require a refresh (text, x, y, width, height)
    if (this.updatedKeys.includes("data.overlayArray")) {
      var base = cloneDeep(this.preChangeShowObject.data.overlayArray);
      var object = cloneDeep(this.overlayArray);
      var differenceObject = transform(object, (result, value, key) => {
        if (!isEqual(value, base[key])) {
          result[key] =
            isObject(value) && isObject(base[key])
              ? difference(value, base[key])
              : value;
        }
      });
      var overlayUpdatesNotRequiringRefresh = [
        "text",
        "x",
        "y",
        "width",
        "height"
      ];
      var atLeastOneOverlaysRequiringRefresh = find(differenceObject, o => {
        var changedKeysRequiringRefresh = without(
          keys(o),
          ...overlayUpdatesNotRequiringRefresh
        );
        console.log(
          "overlays: changedKeysRequiringRefresh",
          changedKeysRequiringRefresh
        );
        return changedKeysRequiringRefresh.length > 0;
      });

      if (atLeastOneOverlaysRequiringRefresh) {
        return true;
      }
    }

    // No keysRequiringRefresh or overlays that need a refresh.

    return false;
  }

  get imageErrorsArray() {
    // This returns a list of image errors that need to be removed.
    var imageErrorsFilenameArray = filter(this.show, (s, k) => {
      if (k.startsWith("staticImagesError_")) {
        return s;
      }
    });
    var _imageErrorsArray = filter(this.images || [], i => {
      return imageErrorsFilenameArray.includes(i.filename);
    });
    return _imageErrorsArray;
  }

  get audioErrorsArray() {
    // This returns a list of image errors that need to be removed.
    var _errors = filter(this.show, (s, k) => {
      if (k.startsWith("staticMp3FailedTranscode_")) {
        return s;
      }
    });
    var staticAudioErrorRemoved = this.show.staticAudioErrorRemoved || [];
    return filter(_errors, e => {
      return !staticAudioErrorRemoved.includes(e.filename + ".mp3");
    });
  }

  get hasUnresolvedMediaErrors() {
    return (
      this.imageErrorsArray.length != 0 || this.audioErrorsArray.length != 0
    );
  }

  get requiresRemount() {
    // Look at the updatedKeys.
    var keysRequiringRemount = [
      "data.displaySettings.audioDurationOverride",
      "data.displaySettings.audioDurationOverrideEnabled",
      "data.displaySettings.audioDurationOverrideFadeSeconds",
      "data.displaySettings.aspectRatio",
      "data.audio"
    ];
    return intersection(keysRequiringRemount, this.updatedKeys).length > 0;
  }

  get firestoreUpdateObject() {
    var flatObjLevel1 = flatten(cloneDeep(this.show), { maxDepth: 1 });
    var flatObjLevel2 = flatten(cloneDeep(this.show), { maxDepth: 2 });
    var flatObjLevel3 = flatten(cloneDeep(this.show), { maxDepth: 3 });
    var update = {};
    each(this.updatedKeys, k => {
      console.log(k);
      if (k in flatObjLevel1) {
        update[k] = flatObjLevel1[k];
      } else if (k in flatObjLevel2) {
        update[k] = flatObjLevel2[k];
      } else if (k in flatObjLevel3) {
        update[k] = flatObjLevel3[k];
      } else {
        // Required key not found. Throw an error.
        throw "Model error. Required key not found.";
      }
    });
    return update;
  }

  get link() {
    var show = this.show;
    if (show.privacySelected == "private") {
      return `https://app.soundslides.com/app/editor/${show.key}/preview-only`;
    }
    if (show.privacySelected == "privateLink") {
      return `https://play.soundslides.com/${show.key}/private/${show.privateLinkKey}`;
    }
    if (show.privacySelected == "password") {
      return `https://play.soundslides.com/${show.key}`;
    }
    return `https://play.soundslides.com/${show.key}`;
  }

  get isPublishable() {
    // Return true if the show can be published online. Many features can not!
    return isPublishable(this.show);
  }

  checkForMigrations() {
    //console.log("checkForMigrations");

    if (this.show.format != 4) {
      if (!("autoTimeEnabled" in this.data)) {
        this.data.autoTimeEnabled = (this.data.slideArray || []).length == 0;
      }

      this.show.data.overlayArray = returnMigratedOverlays(this.overlayArray);

      // All shows need displaySettings.
      this.show.data.displaySettings = this.show.data.displaySettings || {};
      // All images must have ids as of 20200804.
      this.show.data.images = ensureArrayHasUniqueIds(this.images);
      this.show.format = 4;
      this.updateTimingArrays();
    }

    this.correctAnyMissingDeployedImages();
  }

  callUpdate() {
    this.serverUpdateCallback(this);
  }

  addAdditionalUpdatedKey(key) {
    this.additionalUpdatedKeys.push(key);
  }

  updateDurationOverride(value) {
    this.data.displaySettings.audioDurationOverrideEnabled = value;
    if (!value) {
      this.data.displaySettings.audioDurationOverride = 0;
    }
    // Note: value is a boolean. The actual duration values are set in updateTimingArrays.
    this.updateTimingArrays();
  }

  updateAutoTimeEnabled(value) {
    this.data.autoTimeEnabled = value;
    this.updateTimingArrays();
  }

  updateAutoTime(time_in_seconds) {
    this.data.autoTimePerImage = time_in_seconds;
    // Also update auto-trim if enabled.
    if (this.data.displaySettings.audioDurationOverride) {
      var auto_trim_seconds = time_in_seconds * this.data.images.length;
      var fade_seconds = 6;
      this.data.displaySettings.audioDurationOverride = auto_trim_seconds;
      this.data.displaySettings.audioDurationOverrideFadeSeconds = fade_seconds;
    }
    // Now process create the new slideArray here.
    this.updateTimingArrays();
  }

  updateGroupedSlideIds(id_array) {
    this.show.groupedSlideIds = id_array;
  }

  updateGroupedOverlayIds(id_array) {
    this.show.groupedOverlayIds = id_array;
  }

  updateTimelinePushMode(value) {
    // Updating timelinePushMode does not change the state of the show's arrays.
    // Rather, it alters the arrays when a subsequent change it made.
    this.timelinePushMode = value;
  }

  getVideoMetdata(filename) {
    return find(
      filter(this.show, (s, k) => {
        if (k.startsWith("staticMp4Deployed_")) {
          return s;
        }
      }),
      { filename: filename }
    );
  }

  updateSlideMovementById(slide_id, movementObj) {
    console.log("updateSlideMovementById", slide_id);
    var modifiedArray = cloneDeep(this.data.slideArray);
    var image = find(modifiedArray, { id: slide_id });
    assign(image, movementObj);
    this.show.data.slideArray = modifiedArray;
  }

  updateSlideTransitionById(slide_id, transitionObj) {
    console.log("updateSlideTransitionById", slide_id);
    var modifiedArray = cloneDeep(this.data.slideArray);
    var image = find(modifiedArray, { id: slide_id });
    assign(image, transitionObj);
    this.show.data.slideArray = modifiedArray;
  }

  updateSlideByIdToSeconds(slide_id, secs, sortAtEnd) {
    console.log("updateSlideByIdToSeconds");
    var modifiedArray = cloneDeep(this.data.slideArray);
    var image = find(modifiedArray, { id: slide_id });
    var imageIndex = findIndex(modifiedArray, { id: slide_id });
    var existingSeconds = image.timing / 1000;
    var diffSeconds = secs - existingSeconds;
    image.timing = Math.round(secs * 1000); // Round to closet millisecond.
    // Note: As of 20200716, I think sortAtEnd is only false when timelinePushMode is enabled.
    sortAtEnd = !this.timelinePushMode;
    // Re-sort the array.
    if (sortAtEnd === true) {
      modifiedArray = sortBy(modifiedArray, "timing");
    }
    this.show.data.slideArray = modifiedArray;
    // Diff checks.
    this.checkForGroupedItems(slide_id, diffSeconds);
    if (image.type == "video" && image.keep_sync) {
      // It's a keep_sync video, call a move on the next slide as well.
      // Take care to factor in the transition time.
      var nextSlideSeconds =
        image.timing / 1000 +
        this.getVideoMetdata(image.file).duration -
        (image.transition_time_in + image.transition_time_out);
      console.log("nextSlideSeconds", nextSlideSeconds);
      var nextSlideId = modifiedArray[imageIndex + 1].id;
      console.log("nextSlideId", nextSlideId);
      this.updateSlideByIdToSeconds(nextSlideId, nextSlideSeconds);
    } else {
      this.checkForPushMode(slide_id); // After grouping???
    }
  }

  checkForGroupedItems(movedId, diffSeconds) {
    /*
    Note: This is only called from the following show methods.
      updateSlideByIdToSeconds()
      updateOverlayByIdToSeconds()
    */
    //console.log(movedId, diffSeconds);

    var diffMilliseconds = Math.round(diffSeconds * 1000);
    // Check images first.
    each(this.show.data.slideArray, slide => {
      if (this.groupedSlideIds.includes(slide.id) && slide.id != movedId) {
        slide.timing = slide.timing + diffMilliseconds;
      }
    });

    // Check overlays next.
    each(this.show.data.overlayArray, overlay => {
      if (
        this.groupedOverlayIds.includes(overlay.id) &&
        overlay.id != movedId
      ) {
        overlay.inpoint = overlay.inpoint + diffSeconds;
      }
    });
  }

  checkForPushMode(movedId) {
    // When an image is moved in push mode, all following images must be
    // a minimum difference away or that image is "pushed" down the timeline.
    /*
    Note: This is only called from the following show methods.
      updateSlideByIdToSeconds()
    */
    if (!this.timelinePushMode) {
      return;
    }

    // Find the index of the movedId. Make sure the next slide is minimum distance in time.
    var slideIndex = findIndex(this.slideArray, { id: movedId });
    if (slideIndex == this.slideArray.length - 1) {
      // Last slide, no "next slide" to push.
      return;
    }

    var minimumDiffMs = TIMELINE_PUSH_MINIMUM_SECONDS * 1000;

    // Find the difference between moved slide and the next slide.
    var timeDiffBetweenMovedAndNextImageMs =
      this.slideArray[slideIndex + 1].timing -
      this.slideArray[slideIndex].timing;

    if (timeDiffBetweenMovedAndNextImageMs >= minimumDiffMs) {
      // Difference is greater than minimum, just return.
      return;
    }

    // Figure out what the pushed slide's new timing should be.
    var newPushedTimingMs = this.slideArray[slideIndex].timing + minimumDiffMs;

    // Check the new pushed timing against the "pushed maximum" for each slide, to prevent bunching.
    var pushedMaximumForThisSlideMs =
      this.data.audio.duration * 1000 -
      minimumDiffMs * (this.slideArray.length - slideIndex);

    if (newPushedTimingMs > pushedMaximumForThisSlideMs) {
      // New timing would be beyond the slide's pushed maximum value, so just return.
      return;
    }

    // Move the slide via updateSlideByIdToSeconds ... as it will handle video slides with keep_sync enabled.
    this.updateSlideByIdToSeconds(
      this.data.slideArray[slideIndex + 1].id,
      newPushedTimingMs / 1000,
      false
    );

    //// Push the actual slide, but do not sort it.
    //this.data.slideArray[slideIndex + 1].timing = newPushedTimingMs;
    //// Recurse until no more images are pushed.
    //this.checkForPushMode(this.data.slideArray[slideIndex + 1].id);
  }

  // Images.

  addImage(image) {
    // Add an image to the show.
    if (isUndefined(image.id)) {
      image.id = returnRandomKey(7);
    }
    this.data.images.push(image);
    this.data.assetsAvailable = false;
    this.updateTimingArrays();
  }

  removeImage(image) {
    // Not to be confused with "deleteSlide" method.
    // This method removes the image from the project and
    // places it in the staticImagesRemovedByUser array.
    var images = without(this.data.images, image);
    this.data.images = images;
    var staticImagesRemovedByUser = this.show.staticImagesRemovedByUser || [];
    staticImagesRemovedByUser.push(image);
    this.show.staticImagesRemovedByUser = staticImagesRemovedByUser;
    this.updateTimingArrays();
  }

  removeAllImages() {
    // This method is nuclear. All evidence of the images is nuked.
    var images = [];
    this.data.images = images;
    var staticImagesRemovedByUser = [];
    this.show.staticImagesRemovedByUser = staticImagesRemovedByUser;
    this.updateTimingArrays();
  }

  restoreImage(image) {
    var images = this.data.images;
    images.push(image);
    this.data.images = images;
    var staticImagesRemovedByUser = without(
      this.show.staticImagesRemovedByUser,
      image
    );
    this.show.staticImagesRemovedByUser = staticImagesRemovedByUser;
    this.updateTimingArrays();
  }

  updateImageOrder(slideSortOrder, images) {
    /*
    Updates the order of the images.
    When slideSortOrder is set to "custom",the images array is passed to this method.
    */

    console.log("updateImageOrder");
    if (
      slideSortOrder == "custom" &&
      !isUndefined(images) &&
      images.length == this.data.images.length
    ) {
      this.data.images = images;
    }

    if (
      slideSortOrder == "custom" &&
      isUndefined(images) &&
      this.data.displaySettings.globalSlideSortOrder != "custom" &&
      this.lastCustomOrderedFilenames.length == this.data.images.length
    ) {
      // Changing from non-custom to custom and we have a valid reverting order.
      this.data.images = orderBy(
        this.data.images,
        [i => this.lastCustomOrderedFilenames.indexOf(i.filename)],
        "asc"
      );
    }

    if (
      slideSortOrder !== "custom" &&
      this.data.displaySettings.globalSlideSortOrder == "custom"
    ) {
      // Changing from "custom" to something else. Save the filename list in case of immediate revert.
      // Must save the last available custom order in case of reverting.
      this.lastCustomOrderedFilenames = map(this.data.images, "filename");
    }

    if (slideSortOrder == "nameAsc") {
      this.data.images = orderBy(
        this.data.images,
        [i => i.name.toLowerCase()],
        "asc"
      );
    }

    if (slideSortOrder == "nameDesc") {
      this.data.images = orderBy(
        this.data.images,
        [i => i.name.toLowerCase()],
        "desc"
      );
    }

    if (slideSortOrder == "timeImportedAsc") {
      this.data.images = orderBy(
        this.data.images,
        [i => i.filename.toLowerCase()],
        "asc"
      );
    }

    if (slideSortOrder == "timeImportedDesc") {
      this.data.images = orderBy(
        this.data.images,
        [i => i.filename.toLowerCase()],
        "desc"
      );
    }

    var filenamesOrdered;
    if (slideSortOrder == "creationDateAsc") {
      filenamesOrdered = map(this.imageMetadataArray, "filename");
      this.data.images = orderBy(
        this.data.images,
        [i => filenamesOrdered.indexOf(i.filename)],
        "asc"
      );
    }

    if (slideSortOrder == "creationDateDesc") {
      filenamesOrdered = map(this.imageMetadataArray, "filename");
      this.data.images = orderBy(
        this.data.images,
        [i => filenamesOrdered.indexOf(i.filename)],
        "desc"
      );
    }

    this.displaySettings.globalSlideSortOrder = slideSortOrder;
    this.updateTimingArrays();
    this.callUpdate();
  }

  // Videos.

  /*
  Note: Yes, all these videos are treated as "images".
  */

  addVideo(video) {
    // Add a video to the show.
    if (isUndefined(video.id)) {
      video.id = returnRandomKey(7);
    }
    this.data.images.push(video);
    this.data.assetsAvailable = false;
    this.updateTimingArrays();
  }

  removeVideo(video) {
    // Remember, videos are treated as a different type of image.
    var images = without(this.data.images, video);
    this.data.images = images;
    var staticImagesRemovedByUser = this.show.staticImagesRemovedByUser || [];
    staticImagesRemovedByUser.push(video);
    this.show.staticImagesRemovedByUser = staticImagesRemovedByUser;
    this.updateTimingArrays();
  }

  restoreVideo(video) {
    // Remember, videos are treated as a different type of image.
    var images = this.data.images;
    images.push(video);
    this.data.images = images;
    var staticImagesRemovedByUser = without(
      this.show.staticImagesRemovedByUser,
      video
    );
    this.show.staticImagesRemovedByUser = staticImagesRemovedByUser;
    this.updateTimingArrays();
  }

  // Update motion.

  updateGlobalMotion(speed, type, preset) {
    this.show.data.displaySettings.globalMotionSpeed = speed;
    this.show.data.displaySettings.globalMotionType = type;
    this.show.data.displaySettings.globalMotionPreset = preset;
    // Check for random order array. Random requires such an order be set.
    if (
      type == "random" &&
      !this.show.data.displaySettings.globalMotionRandomOrder
    ) {
      var randomOrder = returnRandomArray(["in", "out"], 10);
      this.show.data.displaySettings.globalMotionRandomOrder = randomOrder;
    }
    this.updateTimingArrays();
  }

  updateGlobalTransitions(time, type, preset) {
    this.show.data.displaySettings.globalTransitionTime = time;
    this.show.data.displaySettings.globalTransitionType = type;
    this.show.data.displaySettings.globalTransitionPreset = preset;
    this.updateTimingArrays();
  }

  // Update audio.
  // Keep in mind that deployment info is added by the server (thus a full show update).
  addNewAudio(new_audio) {
    //var new_audio = {
    //  source_id: "12345",
    //  filename: "111111111111_new_audio.mp3",
    //  name: audio.name,
    //  deployed: false
    //};
    this.show.data.audio = new_audio;
  }

  updateAudioWithExistingTrack(newTrack) {
    //console.log(newTrack);
    // Audio object would not exist during initial import when using a track from the music library.
    // Initialize it if not existing.
    if (isUndefined(this.show.data.audio)) {
      this.show.data.audio = {};
    }
    this.show.data.audio.deployed = true;
    this.show.data.audio.duration = newTrack.duration;
    (this.show.data.audio.filename = newTrack.filename),
      (this.show.data.audio.name = newTrack.title),
      (this.show.data.audio.source_id = newTrack.source_id);
    this.show.data.audio.waveformData = true;
    this.show["staticMp3Deployed_" + newTrack.fieldKey] = {
      duration: newTrack.duration,
      filename: newTrack.filename,
      key: "audio/" + newTrack.source_id + "/" + newTrack.filename,
      source_id: newTrack.source_id
    };
    this.updateTimingArrays();
  }

  removeErrorAudio(audioFilename, previouslyValidAudio) {
    console.log("removeErrorAudio");
    var staticAudioErrorRemoved = this.show.staticAudioErrorRemoved || [];

    if (staticAudioErrorRemoved.includes(audioFilename)) {
      // Audio has already been removed. Return.
      console.log("Audio has already been removed. Return.");
      return;
    }

    if (previouslyValidAudio && previouslyValidAudio.deployed) {
      // Revert previous audio.
      this.show.data.audio = previouslyValidAudio;
      this.show.data.assetsAvailable = true;
      this.show.data.audio.error = false;
    } else {
      // Error with this audio.
      this.show.data.audio.error = true;
      this.show.data.assetsAvailable = false;
    }

    this.show.staticAudioErrorRemoved = staticAudioErrorRemoved;
    this.show.staticAudioErrorRemoved.push(audioFilename);
  }

  removeErrorImage(imageObject) {
    var images = reject(this.show.data.images, {
      filename: imageObject.filename
    });
    this.show.data.images = images;
    if (isUndefined(this.show.staticImagesErrorRemoved)) {
      this.show.staticImagesErrorRemoved = [];
    }
    this.show.staticImagesErrorRemoved.push(imageObject);
  }

  clearErrorImageLog() {
    if (isUndefined(this.show.staticImagesErrorRemovedCleared)) {
      this.show.staticImagesErrorRemovedCleared = [];
    }
    if (isUndefined(this.show.staticImagesErrorRemoved)) {
      this.show.staticImagesErrorRemoved = [];
    }
    this.show.staticImagesErrorRemovedCleared = this.show.staticImagesErrorRemovedCleared.concat(
      this.show.staticImagesErrorRemoved
    );
    this.show.staticImagesErrorRemoved = [];
  }

  writeBlobsToSlideArray(localImages) {
    console.log("writeBlobsToSlideArray");
    this.show.data.images = map(this.show.data.images, s => {
      s.blob = find(localImages, { filename: s.filename }).blob;
      console.log(s.blob);
      return s;
    });
    this.show.data.slideArray = map(this.show.data.slideArray, s => {
      s.blob = find(localImages, { filename: s.name }).blob;
      console.log(s.blob);
      return s;
    });
  }

  writeBlobsToAudio(localAudioFiles) {
    this.show.data.audio.blob = find(localAudioFiles, {
      filename: this.show.data.audio.filename
    }).blob;
  }

  updateTimingArrays() {
    console.log("updateTimingArrays");
    // Updates the slideArray in place after some (what?) changes.
    if (!this.safeToRender) {
      console.warn("No timing array update, not safe to render!!!!");
      return;
    }
    if (this.data.autoTimeEnabled) {
      // returnAutoMotionSlideArray also handles properly spacing video slides and
      // makes video slide's keep_sync = true;
      var slideArray = returnAutoMotionSlideArray(this.show, this.aspectRatio);
      slideArray = ensureArrayHasUniqueIds(slideArray);
      // Immediately update the slideArray on the state's currentShow.
      this.data.slideArray = slideArray; // Requires a refresh.

      // Check for audioDurationOverrideEnabled.
      var auto_trim_seconds = 0;
      if (this.data.displaySettings.audioDurationOverrideEnabled) {
        // Make sure to recomputed length and check for changes.
        var image_time_in_seconds = this.data.autoTimePerImage;
        each(this.data.images, i => {
          if (i.type == "video") {
            var videoMetadata = this.returnVideoMetadata(i.filename);
            auto_trim_seconds += videoMetadata.duration;
          } else {
            auto_trim_seconds += image_time_in_seconds;
          }
        });

        this.data.displaySettings.audioDurationOverride = auto_trim_seconds;

        var fade_seconds = 6;
        this.data.displaySettings.audioDurationOverrideFadeSeconds = fade_seconds;
      }
    }

    // Anytime that updateTimingArrays is called, you must look for linked overlays.
    this.data.overlayArray = returnOverlaysSyncedToSlides(
      this.data.overlayArray,
      this.data
    );
  }

  returnVideoMetadata(filename) {
    // Add duration to any "images" that are video files.
    return find(
      filter(this.show, (s, k) => {
        if (k.startsWith("staticMp4Deployed_")) {
          return s;
        }
      }),
      { filename: filename }
    );
  }

  // Overlays

  addTextOverlay(newOverlayDetails) {
    // newOverlayDetails includes x, y, inpoint and slide filename.
    // Note: All overlays now start attached to an image.
    if (this.overlayArray.length > 0) {
      var maxOverlayLayer = max(map(this.overlayArray, "layer"));
      newOverlayDetails.layer = maxOverlayLayer + 1;
    } else {
      newOverlayDetails.layer = OVERLAY_MINIMUM_LAYER;
    }
    var overlay = returnNewTextOverlay(newOverlayDetails);
    this.show.data.overlayArray.push(overlay);
    this.updateTimingArrays();
    this.callUpdate();
    return overlay;
  }

  addShapeOverlay(newOverlayDetails) {
    // newOverlayDetails includes x, y, inpoint and slide filename.
    // Note: All overlays now start attached to an image.
    if (this.overlayArray.length > 0) {
      var maxOverlayLayer = max(map(this.overlayArray, "layer"));
      newOverlayDetails.layer = maxOverlayLayer + 1;
    } else {
      newOverlayDetails.layer = OVERLAY_MINIMUM_LAYER;
    }
    var overlay = returnNewShapeOverlay(newOverlayDetails);
    overlay.type = newOverlayDetails.type; // Change shape to rect or circle.
    this.show.data.overlayArray.push(overlay);
    this.updateTimingArrays();
    this.callUpdate();
    return overlay;
  }

  updateOverlayByIdToSeconds(updateObj) {
    // updateObj includes: overlay_id, sortAtEnd and inpoint.
    var overlay_id = updateObj.overlay_id;
    var sortAtEnd = updateObj.sortAtEnd;
    var modifiedArray = cloneDeep(this.overlayArray);
    var overlay = find(modifiedArray, { id: overlay_id });
    var diffSeconds = 0;
    var existingSeconds = overlay.inpoint;

    if ("inpoint" in updateObj) {
      overlay.inpoint = round(updateObj.inpoint, 2);
      diffSeconds = overlay.inpoint - existingSeconds;
    }

    if ("duration" in updateObj) {
      overlay.duration = round(updateObj.duration, 2);
    }

    // Any update via this function "unlocks" the timing mode
    // of this overlay.
    overlay.timing_mode = TIMING_MODE_UNLOCKED;
    delete overlay.timing_mode_slide_filename;

    // Re-sort the array.
    if (sortAtEnd === true) {
      modifiedArray = sortBy(modifiedArray, "inpoint");
    }

    this.data.overlayArray = modifiedArray;
    this.checkForGroupedItems(overlay_id, diffSeconds);
    this.updateTimingArrays();
    this.callUpdate();
  }

  updateOverlayByIdToLayer(updateObj) {
    var overlay_id = updateObj.overlay_id;
    var new_layer = updateObj.new_layer;
    var modifiedArray = cloneDeep(this.overlayArray);

    var moving_overlay = find(modifiedArray, {
      id: overlay_id
    });
    var old_layer = moving_overlay.layer;

    var swapping_overlay = find(modifiedArray, { layer: new_layer });

    // Do the swap.
    moving_overlay.layer = new_layer;
    swapping_overlay.layer = old_layer;

    this.data.overlayArray = modifiedArray;
    this.updateTimingArrays();
    this.callUpdate();
  }

  updateOverlayById(updateObj) {
    // TODO: Need to add a check for change from TIMING_MODE_UNLOCKED to another mode
    // to ensure that there is an attached "timing_mode_slide_filename".

    var overlay_id = updateObj.id;
    var modifiedArray = cloneDeep(this.overlayArray);

    var overlayIndex = findIndex(modifiedArray, {
      id: overlay_id
    });

    if (updateObj.timing_mode !== TIMING_MODE_UNLOCKED) {
      // Make sure there's a timing_mode_slide_filename;
      if (isUndefined(updateObj.timing_mode_slide_filename)) {
        // Find the closest image to the previous inpoint.
        var closestSlideIndex = findIndex(this.slideArray, s => {
          return s.timing > updateObj.inpoint * 1000;
        });
        if (closestSlideIndex > 0) {
          updateObj.timing_mode_slide_filename = this.slideArray[
            closestSlideIndex - 1
          ].file;
        } else {
          updateObj.timing_mode_slide_filename = last(this.slideArray).file;
        }

        // If the timing_mode = TIMING_MODE_ALWAYS_VISIBLE
        if (updateObj.timing_mode == TIMING_MODE_ALWAYS_VISIBLE) {
          updateObj.timing_mode_slide_filename = this.slideArray[0].file;
        }
      }
    }
    modifiedArray[overlayIndex] = updateObj;
    this.data.overlayArray = modifiedArray;
    this.updateTimingArrays();
    this.callUpdate();
  }

  duplicateOverlayById(overlayId) {
    // Get new id, and automatically goes to highest available layer.

    var overlay = find(this.overlayArray, { id: overlayId });

    var defaultInitialLayer = OVERLAY_MINIMUM_LAYER;
    var maxOverlayLayer =
      max(map(this.overlayArray, "layer")) || defaultInitialLayer;

    var newOverlay = cloneDeep(overlay);
    newOverlay.id = returnNewId();
    newOverlay.layer = maxOverlayLayer + 1;

    this.data.overlayArray.push(newOverlay);
    this.callUpdate();

    return newOverlay;
  }

  removeOverlayById(overlayId) {
    var overlays = cloneDeep(this.overlayArray);
    overlays = overlays.filter(o => {
      return o.id != overlayId;
    });
    this.data.overlayArray = overlays;
    this.callUpdate();
  }

  publishShow() {
    // Change privacy state, and call publishing callback.
    this.show.privacyState = "world";
    this.show.privacySelected = "world";
    delete this.show.published; // Ensure this is not being used.
    this.callUpdate();
  }

  publishPrivateLinkShow() {
    // Change privacy state, and call publishing callback.
    this.show.privacyState = "privateLink";
    this.show.privacySelected = "privateLink";
    if (!("privateLinkKey" in this.show)) {
      // Generate new privateLinkKey.
      this.show.privateLinkKey = returnRandomKey();
    }
    delete this.show.published; // Ensure this is not being used.
    this.callUpdate();
  }

  publishPasswordShow(password) {
    // Change privacy state, and call publishing callback.
    this.show.privacyState = "password";
    this.show.privacySelected = "password";
    this.show.password = password;
    if (!("passwordFileKey" in this.show)) {
      // Generate new passwordFileKey.
      this.show.passwordFileKey = returnRandomKey();
    }
    delete this.show.published; // Ensure this is not being used.
    this.callUpdate();
  }

  unpublishShow() {
    // Change privacy state, and call publishing callback.
    this.show.privacyState = "private";
    this.show.privacySelected = "private";
    delete this.show.published; // Ensure this is not being used.
    this.callUpdate();
  }

  updateTitle(newTitle) {
    this.show.title = newTitle;
    this.callUpdate();
  }

  updateColors(colorObj) {
    this.show.data.displaySettings.globalBackgroundColor =
      colorObj.globalBackgroundColor;
    this.show.data.displaySettings.globalSlideBackgroundColor =
      colorObj.globalSlideBackgroundColor;
    this.callUpdate();
  }

  updateAssetsAvailable(value) {
    console.warn("updateAssetsAvailable", value);
    this.show.data.assetsAvailable = value;
    this.updateTimingArrays();
    this.callUpdate();
  }

  updateDescription(newDescription) {
    this.show.description = newDescription;
    this.callUpdate();
  }

  updateAspectRatio(newAspectRatio) {
    console.log(newAspectRatio);
    this.show.data.displaySettings.aspectRatio = newAspectRatio;
    console.log(this.aspectRatio);
    this.callUpdate();
  }

  /*
  Computer Vision data.
  */

  addFaceDataByFilename(filename, data) {
    this.faceData[filename] = data;
  }

  addLabelDataByFilename(filename, data) {
    this.labelData[filename] = data;
  }

  /*
  Export methods, many call MltShow class.
  */

  queueForExport(presetName, sampleSeconds) {
    console.log(presetName);
    this.updateTimingArrays(); // Always update slideArray before export (in case legacy show)
    this.show.mlt_video = true;
    this.show.mlt_video_arguments = ["threads=4", "real_time=-4"];
    this.show.mlt_video_is_sample = false;
    this.show.mlt_video_useMltSlideArray = true; // Always true after 20200728.
    this.show.mlt_video_preset = presetName;
    this.show.mlt_video_queued = true;
    this.show.mlt_video_queued_time = Date.now();

    var mltShow = new MltShow(this);
    this.show.mlt_video_slideArray = mltShow.slideArray;
    this.show.mlt_video_overlay_array = mltShow.overlayArray;

    // Handle sampleSeconds.
    if (
      sampleSeconds &&
      isFinite(sampleSeconds) &&
      mltShow.duration > sampleSeconds
    ) {
      var fps = 25;
      this.show.mlt_video_arguments.push("in=0");
      this.show.mlt_video_arguments.push(`out=${sampleSeconds * fps}`);
      this.show.mlt_video_is_sample = true;
    }
  }
}
