import axios from "axios";
import { areUploadCredentialsValid } from "@/utils/uploadCredentials";
import filter from "lodash/filter";
import find from "lodash/find";
import debounce from "lodash/debounce";
import { returnRandomKey } from "@/utils/randomKey";
import map from "lodash/map";

import random from "lodash/random";

import { returnResponsiveEmbedCode } from "@/utils/embedCode";

import * as Sentry from "@sentry/browser";

import { Show } from "@/models/showModel";

const returnActiveItem = (show, clickedItemHistory) => {
  var groupedSlideIds = show.groupedSlideIds || [];
  var groupedOverlayIds = show.groupedOverlayIds || [];
  var _clickedItemsOrdered = filter(clickedItemHistory, i => {
    return groupedSlideIds.includes(i.id) || groupedOverlayIds.includes(i.id);
  });
  console.log("_clickedItemsOrdered", _clickedItemsOrdered);
  if (_clickedItemsOrdered) {
    return _clickedItemsOrdered[0];
  }

  return {};
};

const timingArraysUpdate = (dispatch, state) => {
  var showModelObject = new Show(state.currentShow);
  showModelObject.addAdditionalUpdatedKey("data.slideArray");
  showModelObject.addAdditionalUpdatedKey("data.overlayArray");
  dispatch("updateCurrentDataFromModel", showModelObject);
};

const debouncedTimingArraysUpdate = debounce(timingArraysUpdate, 100);

export default {
  namespaced: true,
  state: {
    currentShowKey: null,
    currentShow: null,
    currentShowDataBound: false,
    lastValidAudio: null,
    publishingInProgress: false,
    deployedVideoFiles: [],
    deployedAudioFiles: [],
    imageFileErrors: [],
    audioFileErrors: [],
    imageMetadataOriginalDateOrder: [],
    slideIndex: 0,
    currentTime: 0,
    pendingSave: false,
    activeItem: {},
    clickedItemHistory: [],
    editableOverlays: true, // False for initial rc.
    timelineFocusLayout: false,
    refreshId: 999,
    remountId: 999,
    lastCustomOrderedFilenames: [],
    safeToRender: false
  },
  getters: {
    readOnlyShowObject(state) {
      var _show = new Show(state.currentShow);
      return _show;
    },
    isCurrentShowTimelineEdited(state) {
      if (!state.currentShowDataBound) {
        return false;
      }
      return !state.currentShow.data.autoTimeEnabled;
    },
    showHasAudio(state) {
      if (!state.currentShowDataBound) {
        return false;
      }
      // Some shows do have displaySettings.
      var displaySettings =
        (state.currentShow.data || {}).displaySettings || {};
      return displaySettings.noAudio != true;
    },
    isShowPrivate(state) {
      if (!state.currentShowDataBound) {
        return "private";
      }
      return state.currentShow.privacyState !== "world";
    },
    responsiveEmbedCode(state) {
      return returnResponsiveEmbedCode(state.currentShow);
    },
    minimalMediaDeployed(state) {
      return state.safeToRender;
    },
    displayInitialImporter(state) {
      if (!state.currentShowDataBound) {
        return true;
      }
      if (state.currentShow.data.slideArray.length > 0) {
        return false;
      }
      return state.currentShow.data.assetsAvailable != true;
    },
    selectedItems(state) {
      var _selectedOverlays = map(state.groupedOverlayIds, o => {
        return {
          type: "overlay",
          id: o
        };
      });
      var _selectedSlides = map(state.groupedSlideIds, s => {
        return {
          type: "slide",
          id: s
        };
      });
      return _selectedSlides.concat(_selectedOverlays);
    }
  },
  mutations: {
    setCurrentShow(state, show_object) {
      console.log("setCurrentShow");
      state.currentShowKey = show_object.key;
      var _show = new Show(show_object);
      state.currentShow = _show.show;
      state.safeToRender = _show.safeToRender;
      state.currentShowDataBound = true;
      // Image file errors.
      var imageFileErrors = filter(state.currentShow, (s, k) => {
        if (k.startsWith("staticImagesError_")) {
          return s;
        }
      });
      state.imageFileErrors = imageFileErrors;
      // Audio file errors.
      var audioFileErrors = filter(state.currentShow, (s, k) => {
        if (k.startsWith("staticMp3FailedTranscode_")) {
          return s;
        }
      });
      state.audioFileErrors = audioFileErrors;
      // If audio file is deployed, make sure you have a copy in case you need to rollback.
      var currentAudio = state.currentShow.data.audio || {};
      if (currentAudio.deployed) {
        state.lastValidAudio = currentAudio;
      }
      var deployedVideoFiles = filter(state.currentShow, (s, k) => {
        if (k.startsWith("staticMp4Deployed_")) {
          return s;
        }
      });
      state.deployedVideoFiles = deployedVideoFiles;
      // See if a non-image deployment error occurred.
      if (_show.foundMissingDeployedImage) {
        Sentry.captureMessage(
          "Found and corrected a missing deployed image issue."
        );
      }
    },
    setCurrentShowCleared(state) {
      console.log("setCurrentShowCleared");
      state.currentShowKey = null;
      state.currentShow = null;
      state.currentShowDataBound = false;
      state.deployedVideoFiles = [];
      state.lastValidAudio = null;
      state.imageFileErrors = [];
      state.audioFileErrors = [];
    },
    setSlideIndex(state, slideIndex) {
      state.slideIndex = slideIndex;
    },
    setPlayerAudioRef(state, audioRef) {
      state.audioRef = audioRef;
    },
    setClickedTimelineItem(state, clickedItem) {
      console.log("clickedItem", clickedItem);
      // Either { type: "overlay", id: overlay.id } or { type: "slide", id: slide.id }
      /*
      This method is called onDrag from the timeline. It should not call the main model,
      or be saved on the server.
      */
      state.clickedItemHistory.unshift(clickedItem);
      var maximumItemHistory = 20;
      if (state.clickedItemHistory.length > maximumItemHistory) {
        state.clickedItemHistory = state.clickedItemHistory.slice(
          0,
          maximumItemHistory
        );
      }
    },
    setActiveItemFromEditableOverlay(state, overlayId) {
      if (!overlayId) {
        // overlayId is null when clicking outside of an actively edited overlay.
        state.activeItem = returnActiveItem(
          state.currentShow,
          state.clickedItemHistory
        );
      } else {
        state.activeItem = {
          type: "overlay",
          id: overlayId
        };
      }
    },
    saveCompleted(state) {
      console.log("saveCompleted");
      state.pendingSave = false;
    },
    setEditableOverlays(state, value) {
      state.editableOverlays = value;
    },
    settimelineFocusLayout(state, value) {
      state.timelineFocusLayout = value;
    }
  },
  actions: {
    setCurrentShow({ commit, dispatch }, show_object) {
      commit("setCurrentShow", show_object);
      dispatch("checkForCompletedAudio");
      dispatch("checkForMediaErrors");
    },
    setCurrentTime({ state }, currentTime) {
      state.currentTime = currentTime;
    },
    checkForMediaErrors({ state, dispatch }) {
      // Check to see if there are any image errors. If so, remove the item from the array.
      // This is b/c only the browser client can update the data object.
      var showModelObject = new Show(state.currentShow);
      if (!showModelObject.hasUnresolvedMediaErrors) {
        return;
      }
      // Check for image errors and remove these first.
      if (showModelObject.imageErrorsArray.length > 0) {
        dispatch("removeErrorImage", showModelObject.imageErrorsArray[0]);
        return;
      }

      if (showModelObject.imageErrorsArray.length > 0) {
        dispatch("removeErrorImage", showModelObject.imageErrorsArray[0]);
        return;
      }
      if (showModelObject.audioErrorsArray.length > 0) {
        var errorFilename =
          showModelObject.audioErrorsArray[0].filename + ".mp3";
        dispatch("removeErrorAudio", errorFilename);
      }
    },
    removeErrorAudio({ state, dispatch }, audioFilename) {
      console.log("removeErrorAudio");

      var actualFilename = state.currentShow.data.audio.name;
      var message = `The audio file named ${actualFilename} failed to import.`;
      var showModelObject = new Show(state.currentShow);

      if (state.lastValidAudio) {
        message =
          message +
          " Your audio has been restored to the previously imported file.";
        // Remove and restore.
        showModelObject.removeErrorAudio(audioFilename, state.lastValidAudio);
      } else {
        // Remove.
        showModelObject.removeErrorAudio(audioFilename);
      }
      if (showModelObject.updatedKeys.length !== 0) {
        dispatch("updateCurrentDataFromModel", showModelObject);
      }
      dispatch(
        "toastMessage",
        {
          title: "Audio import error",
          variant: "danger",
          message: message,
          noAutoHide: true
        },
        { root: true }
      );
    },
    removeErrorImage({ state, dispatch }, image) {
      console.log("removeErrorImage");
      var showModelObject = new Show(state.currentShow);
      showModelObject.removeErrorImage(image);
      dispatch("updateCurrentDataFromModel", showModelObject);

      dispatch(
        "toastMessage",
        {
          title: "Image import error",
          variant: "danger",
          message: `The image named ${image.name} failed to import.`
        },
        { root: true }
      );
    },
    removeImage({ state, dispatch }, image) {
      console.log("removeImage");

      var showModelObject = new Show(state.currentShow);
      showModelObject.removeImage(image);
      dispatch("updateCurrentDataFromModel", showModelObject);
      dispatch(
        "toastMessage",
        {
          title: "Slide removed",
          variant: "success",
          message: `${image.name} removed. Removed slides are listed at the bottom of the page.`
        },
        { root: true }
      );
    },
    restoreImage({ state, dispatch }, image) {
      console.log("restoreImage");
      var showModelObject = new Show(state.currentShow);
      showModelObject.restoreImage(image);
      dispatch("updateCurrentDataFromModel", showModelObject);
      dispatch(
        "toastMessage",
        {
          title: "Slide restored",
          variant: "success",
          message: `${image.name} restored.`
        },
        { root: true }
      );
    },
    clearErrorImageLog({ state, dispatch }) {
      // Clear the error log for images.
      console.log("clearErrorImageLog");
      var showModelObject = new Show(state.currentShow);
      showModelObject.clearErrorImageLog();
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    removeAllImages({ state, dispatch }) {
      console.log("removeAllImages");
      var showModelObject = new Show(state.currentShow);
      showModelObject.removeAllImages();
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    checkForCompletedAudio({ state, dispatch }) {
      // Check to see if audio object needs populating/updating.
      // This is b/c only the browser client can update the data object.
      // And the audio details now come from Firestore admin python client in lambda.
      if (!("audio" in state.currentShow.data)) {
        // No audio data at all. It's not ready.
        return false;
      }
      if (!state.currentShow.data.audio.deployed) {
        console.log("We're checking for audio deployment.");
        var deployedAudioFiles = filter(state.currentShow, (s, k) => {
          if (k.startsWith("staticMp3Deployed_")) {
            return s;
          }
        });
        var deployedFile = find(deployedAudioFiles, {
          filename: state.currentShow.data.audio.filename
        });
        console.log("deployedFile", deployedFile);
        if (deployedFile) {
          // File deployed, log it to state.currentShow.data.audio.
          dispatch("updateAudio", deployedFile);
        }
      }
    },
    unloadShow({ state }) {
      // Unload the current show.
      console.log("unloadShow");
      state.currentShowKey = null;
      state.currentShow = null;
      state.currentShowDataBound = false;
    },
    addNewImage({ state, dispatch }, newImage) {
      console.log("addNewImage");
      var showModelObject = new Show(state.currentShow);
      showModelObject.addImage(newImage);
      if (newImage.width && newImage.height) {
        // If the new image has metadata values, go ahead and add that to the show.
        showModelObject.show["metadataImages_" + returnRandomKey(8)] = {
          width: newImage.width,
          height: newImage.height,
          filename: newImage.filename
        };
      }
      return dispatch("updateCurrentDataFromModel", showModelObject);
    },
    addNewVideo({ state, dispatch }, newVideo) {
      console.log("addNewVideo");
      var showModelObject = new Show(state.currentShow);
      showModelObject.addVideo(newVideo);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    addNewAudio({ state, dispatch }, newAudio) {
      console.log("addNewAudio");
      var showModelObject = new Show(state.currentShow);
      showModelObject.addNewAudio(newAudio);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updateAudio({ state, dispatch }, deployedFile) {
      console.log("updateAudio");
      console.log(state, dispatch, deployedFile);
      throw "updateAudio should not be called!";
    },
    updateAudioWithExistingTrack({ state, dispatch }, track) {
      console.log("updateAudioWithExistingTrack", track);

      var showModelObject = new Show(state.currentShow);
      showModelObject.updateAudioWithExistingTrack(track);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updateGroupedSlideIds({ state, dispatch }, id_array) {
      var showModelObject = new Show(state.currentShow);
      showModelObject.updateGroupedSlideIds(id_array);
      state.activeItem = returnActiveItem(
        showModelObject.show,
        state.clickedItemHistory
      );
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updateGroupedOverlayIds({ state, dispatch }, id_array) {
      var showModelObject = new Show(state.currentShow);
      showModelObject.updateGroupedOverlayIds(id_array);
      state.activeItem = returnActiveItem(
        showModelObject.show,
        state.clickedItemHistory
      );
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updateAutoTime({ state, dispatch }, time_in_seconds) {
      console.log("updateAutoTime");
      if (time_in_seconds == state.currentShow.data.autoTimePerImage) {
        console.log("No updateAutoTime needed.");
        return;
      }
      var showModelObject = new Show(state.currentShow);
      showModelObject.updateAutoTime(time_in_seconds);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updateTitle({ state, dispatch }, title) {
      console.log("updateTitle");
      var showModelObject = new Show(state.currentShow);
      showModelObject.updateTitle(title);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updateColors({ state, dispatch }, colorObj) {
      console.log("updateColors");
      var showModelObject = new Show(state.currentShow);
      showModelObject.updateColors(colorObj);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updatePrivacy({ state, dispatch }, privacySelected) {
      console.log("updatePrivacy");
      var showModelObject = new Show(state.currentShow);
      if (privacySelected == "world") {
        showModelObject.publishShow();
      }
      if (privacySelected == "private") {
        showModelObject.unpublishShow();
      }
      if (privacySelected == "privateLink") {
        showModelObject.publishPrivateLinkShow();
      }
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updatePrivacyPassword({ state, dispatch }, password) {
      console.log("updatePrivacyPassword");
      var showModelObject = new Show(state.currentShow);
      showModelObject.publishPasswordShow(password);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updateAspectRatio({ state, dispatch }, aspectRatio) {
      console.log("updateAspectRatio");
      var showModelObject = new Show(state.currentShow);
      showModelObject.updateAspectRatio(aspectRatio);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updatePushMode({ state, dispatch }, pushModeValue) {
      console.log("updatePushMode");
      var showModelObject = new Show(state.currentShow);
      showModelObject.updateTimelinePushMode(pushModeValue);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updateGlobalTransitions({ state, dispatch }, obj) {
      var showModelObject = new Show(state.currentShow);
      showModelObject.updateGlobalTransitions(
        obj.globalTransitionTime,
        obj.globalTransitionType,
        obj.globalTransitionPreset
      );

      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updateAudioOverrideEnabled({ state, dispatch }, enabled) {
      // check to see that value changed.
      // "enabled" is a boolean.
      if (
        enabled ===
        state.currentShow.data.displaySettings.audioDurationOverrideEnabled
      ) {
        return;
      }
      var showModelObject = new Show(state.currentShow);
      showModelObject.updateDurationOverride(enabled);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updateGlobalMotion({ state, dispatch }, obj) {
      console.log("updateGlobalMotion", obj);
      var showModelObject = new Show(state.currentShow);
      showModelObject.updateGlobalMotion(
        obj.globalMotionSpeed,
        obj.globalMotionType,
        obj.globalMotionPreset
      );
      console.log(showModelObject.slideArray);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },

    updateSlideMovementById({ state, dispatch }, image_obj) {
      console.log("updateSlideMovementById", image_obj);
      var showModelObject = new Show(state.currentShow);
      showModelObject.updateSlideMovementById(image_obj.id, image_obj.movement);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },

    updateSlideTransitionById({ state, dispatch }, image_obj) {
      console.log("updateSlideTransitionById", image_obj);
      var showModelObject = new Show(state.currentShow);
      showModelObject.updateSlideTransitionById(image_obj.id, {
        transition: image_obj.transition,
        transition_duration: image_obj.transition_duration
      });
      dispatch("updateCurrentDataFromModel", showModelObject);
    },

    /*
    These two methods are special. They're the ONLY updates that are
    currently debounced, as the updates are trigger by mousemoves.
    */
    updateSlideByIdToSeconds({ state, dispatch }, image_obj) {
      console.log("updateSlideByIdToSeconds", image_obj);
      var showModelObject = new Show(state.currentShow);
      showModelObject.updateSlideByIdToSeconds(image_obj.id, image_obj.seconds);
      state.currentShow = showModelObject.show;
      debouncedTimingArraysUpdate(dispatch, state);
    },

    updateOverlayByIdToSeconds({ state, dispatch }, obj) {
      console.log("updateOverlayByIdToSeconds");
      var showModelObject = new Show(state.currentShow);
      showModelObject.updateOverlayByIdToSeconds(obj);
      debouncedTimingArraysUpdate(dispatch, state);
    },

    /*
    End of special updates.
    */

    updateImageOrder({ state, dispatch }, obj) {
      console.log("updateImageOrder", obj);
      var showModelObject = new Show(state.currentShow);
      showModelObject.lastCustomOrderedFilenames =
        state.lastCustomOrderedFilenames; // Update from persistent state.
      showModelObject.updateImageOrder(obj.sortOrderSelected, obj.images);
      state.lastCustomOrderedFilenames =
        showModelObject.lastCustomOrderedFilenames; // Save to persistent state.
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updateCredentialsIfNeeded({ dispatch, state }) {
      var show_obj = state.currentShow;
      console.log("updateCredentialsIfNeeded", show_obj.key);
      if (areUploadCredentialsValid(show_obj)) {
        return true;
      }
      dispatch("updateCredentials");
    },
    updateCredentials({ state }) {
      // Called when new show is created.
      var key = state.currentShowKey;
      console.log("updateCredentials", key);
      var policyUpdateEndpoint =
        "https://upload.soundslides.com/editor/policy/update/" + key + "/";
      axios
        .get(policyUpdateEndpoint)
        .then(function(e) {
          console.log(e);
          console.log("updateCredentials, success");
        })
        .catch(function(e) {
          console.log(e);
          console.log("updateCredentials, error");
          Sentry.configureScope(function(scope) {
            scope.setExtra(
              "error_message",
              "Credentials updater could not be reached."
            );
          });
          Sentry.captureException(e);
        });
    },

    updateCurrentDataFromModel({ state, dispatch }, showModel) {
      console.log("updateCurrentDataFromModel");
      var show_key = state.currentShowKey;
      console.log(show_key);
      // Check to see if their are any updated keys, if not, warn!
      if (showModel.updatedKeys.length === 0) {
        console.warn(
          "No update needed. This is a rare case. Double-check this is intended."
        );
      }
      console.log(showModel);
      var showObject = showModel.show;
      if (showModel.requiresRemount) {
        // For remounts, you need to update the currentShow before remount.
        // You'll still use the smaller firestoreUpdateObject to update data store.
        console.log("requiresRemount");
        state.currentShow = showObject;
        dispatch("show/remountPlayer", null, { root: true }); // Refresh now.
      } else if (showModel.requiresRefresh) {
        // No need to refresh if remounting!
        console.log("requiresRefresh");
        state.currentShow.data = showObject.data;
        dispatch("show/refreshPlayerData", null, { root: true }); // Refresh now.
      }
      // Actually save to server and update json as needed.
      var update_object = showModel.firestoreUpdateObject;
      update_object.updated_at = new Date().getTime();

      console.log("Update object: ", update_object);

      // Local show update here!
      state.currentShow = showObject;
      dispatch("offlineShow/saveOfflineShowEdit", null, { root: true });

      if (showModel.jsonUpdateNeeded) {
        // Update on server with update_object, then
        // dispatch the json update with mergedShowObject.
        /*
        console.log("json update is needed!");
        return db
          .collection("editor_shows")
          .doc(show_key)
          .update(update_object)
          .then(function() {
            console.log("updateCurrentDataFromModel", "success");
            return dispatch(
              "showsJson/publishShowByKey",
              state.currentShowKey,
              {
                root: true
              }
            );
          });
          */
      } else {
        // Partial update is ok.
        // Update on server.
        console.log("update server data only!");
        /*
        return db
          .collection("editor_shows")
          .doc(show_key)
          .update(update_object)
          .then(function() {
            console.log("updateCurrentDataFromModel", "success");
          });
          */
      }
    },

    queueForExport({ state, dispatch }, obj) {
      var sampleSeconds = obj.sampleSeconds;
      console.log(obj, sampleSeconds);
      var presetName = obj.presetName;

      var showModelObject = new Show(state.currentShow);
      showModelObject.queueForExport(presetName, sampleSeconds);
      dispatch("updateCurrentDataFromModelForExporting", showModelObject)
        .then(function(result) {
          console.log(result);
          return axios
            .get("https://video-sgm.soundslides.com/api/v1/checkup")
            .then(function(response) {
              console.log(response);
            })
            .catch(function(error) {
              // throw error for logging into Sentry.
              console.log(error);
              throw error;
            });
        })
        .then(() => {
          console.log("Always ends.");
        });
    },

    updateCurrentDataFromModelForExporting({ state }, showModel) {
      console.log("updateCurrentDataFromModelForExporting");
      // No remount calculations, or updated_at added.
      var show_key = state.currentShowKey;
      console.log(show_key);

      // Actually save to server and update json as needed.
      var update_object = showModel.firestoreUpdateObject;
      console.log("Update object: ", update_object);

      // Partial update is ok.
      // Update on server.
      console.log("update server data only!");
      /*
      return db
        .collection("editor_shows")
        .doc(show_key)
        .update(update_object)
        .then(function() {
          console.log("updateCurrentDataFromModel", "success");
        });
      */
    },

    resetCurrentShowToEvenTimeline({ state, dispatch }) {
      console.log("resetCurrentShowToEvenTimeline");
      var showModelObject = new Show(state.currentShow);
      var autoTimeEnabled = true;
      showModelObject.updateAutoTimeEnabled(autoTimeEnabled);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    updateAutoTimeEnabled({ state, dispatch }, value) {
      console.log("updateAutoTimeEnabled", value);
      // Setting autoTimeEnabled to Boolean(value).
      var showModelObject = new Show(state.currentShow);
      var autoTimeEnabled = value;
      showModelObject.updateAutoTimeEnabled(autoTimeEnabled);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    createInitialSlideArrayAndOpenEditor({ state, dispatch }) {
      console.log("createInitialSlideArrayAndOpenEditor");
      // Setting autoTimeEnabled to false will produce a slideArray.
      // Then open the editor in the same window.
      var showModelObject = new Show(state.currentShow);
      var autoTimeEnabled = false;
      showModelObject.updateAutoTimeEnabled(autoTimeEnabled);
      dispatch("updateCurrentDataFromModel", showModelObject).then(function() {
        dispatch("openInEditor");
      });
    },
    openInEditor({ state }) {
      var show_key = state.currentShowKey;
      if (process.env.NODE_ENV === "production") {
        window.location = "/app/editor/" + show_key;
      } else {
        window.location =
          "https://soundslides-stage.firebaseapp.com/app/editor/" + show_key;
      }
    },
    importCurrentShowComplete({ state, dispatch }) {
      var showModelObject = new Show(state.currentShow);
      var assetsAvailable = true;
      showModelObject.updateAssetsAvailable(assetsAvailable);
      dispatch("updateCurrentDataFromModel", showModelObject);
    },
    refreshPlayerData({ state }) {
      console.log("refreshPlayerData -------------------------------->");
      state.refreshId = random(0, 99999999);
    },
    remountPlayer({ state }) {
      console.log("--------------------------------> remountPlayer");
      state.remountId = random(0, 99999999);
    }
  }
};
