import Vue from "vue";
import Router from "vue-router";

import Editor from "./views/Editor.vue";
import Testing from "./views/Testing.vue";
import ReImportZip from "./views/ReImportZip.vue";
import Error from "./views/Error";
Vue.use(Router);

export default new Router({
  //mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "editor",
      meta: { hideNav: true },
      component: Editor
    },
    {
      path: "/import-from-zip",
      name: "import-from-zip",
      meta: { hideNav: true },
      component: ReImportZip
    },
    {
      path: "/testing",
      name: "testing",
      meta: { hideNav: true },
      component: Testing
    },
    {
      path: "/test/error",
      name: "test-error",
      meta: { hideNav: true },
      component: Error
    },
    {
      path: "/privacy",
      name: "privacy",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "about" */ "./views/pages/PrivacyPolicy.vue"
        )
    },
    {
      path: "/terms",
      name: "terms",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/pages/Terms.vue")
    },
    {
      path: "*",
      name: "404",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/pages/404.vue")
    }
  ]
});
