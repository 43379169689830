<template lang="html">
  <div :ref="'frame' + overlay.id" class="frame" :style="frameStyle">
    <div
      v-if="displayWidth > 0 && displayHeight > 0"
      :key="'text_edit_' + overlay.id + displayWidth + displayHeight"
      class="title-overlay"
      :style="fontStyleComputed"
    >
      <div class="line-container" :style="positionComputed">
        <div :style="nonEditableStyle">
          <button :style="buttonStyle" class="button" @click.prevent="btnClick">
            <span :style="spanStyle">{{ buttonText }}</span>
            <v-icon
              v-if="overlay.icon"
              :name="overlayName"
              :style="faSpanStyle"
              class="icon-margin"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isUndefined from "lodash/isUndefined";
import WebFont from "webfontloader";
import tinycolor from "tinycolor2";
export default {
  name: "ButtonNonEditable",
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true
    },
    isPaused: {
      type: Boolean,
      default: () => true
    }
  },
  data: function() {
    return {
      width: null,
      height: null,
      x: 0,
      y: 0,
      initialX: 0,
      initialY: 0
    };
  },
  computed: {
    overlayName() {
      // Get the name via the action.
      if (this.overlay.action == "playToggle") {
        return this.isPaused ? "play" : "pause";
      }
      var overlayNames = {
        play: "play",
        pause: "pause",
        link: "link",
        "link-new-window": "external-link-alt",
        "goto-and-play": "play",
        "goto-and-pause": "pause"
      };
      return overlayNames[this.overlay.action];
    },
    buttonText() {
      var toggleActions = ["playToggle"];
      if (!toggleActions.includes(this.overlay.action)) {
        return this.overlay.text;
      }

      if (this.isPaused) {
        return this.overlay.text_secondary;
      } else {
        return this.overlay.text;
      }
    },
    overlay() {
      return this.item;
    },
    nonEditableStyle() {
      return {
        position: "absolute",
        top: "0px",
        left: "0px",
        width: 40 + "px",
        height: 40 + "px"
      };
    },
    frameStyle() {
      var _frameStyle = {
        backgroundColor: `rgba(0, 0, 0, ${this.overlay.background_alpha})`,
        "pointer-events": "none"
      };
      return _frameStyle;
    },
    textContainerStyle() {
      var _style = {
        height: "100%"
      };
      if (this.overlay.text_background) {
        _style.background = this.overlay.text_background_color_rgba;
        if (!isUndefined(this.overlay.text_background_radius_em)) {
          _style.borderRadius = this.overlay.text_background_radius_em + "em";
        }
        if (!isUndefined(this.overlay.text_background_border_em)) {
          _style.boxShadow = `0px 0px 0px ${
            this.overlay.text_background_border_em
          }em ${this.overlay.text_background_border_rgba} inset`;
        }
      }
      return _style;
    },
    spanStyle() {
      var _style = {
        "text-shadow": `0.03em 0.03em ${this.overlay.text_shadow_color_rgba}`
      };
      return _style;
    },
    faSpanStyle() {
      var _style = {
        filter: `drop-shadow(0.05em 0.05em ${
          this.overlay.text_shadow_color_rgba
        })`
      };
      return _style;
    },
    buttonStyle() {
      var _style = {
        position: "relative",
        color: this.overlay.text_color_rgba,
        "font-family": this.overlay.font_family,
        "font-size": this.overlay.font_size_em + "em",
        "text-align": this.overlay.text_align,
        "text-shadow": `0.02em 0.02em ${this.overlay.text_shadow_color_rgba},
          0 0 0.25em ${this.overlay.text_shadow_color_rgba},
          0 0 0.5em ${this.overlay.text_shadow_color_rgba},
          0 0 0.75em ${this.overlay.text_shadow_color_rgba},
          0 0 1em ${this.overlay.text_shadow_color_rgba}`
      };
      _style.background = this.overlay.text_background_color_rgba;
      var rgba = this.overlay.text_background_color_rgba;
      var rgba_lighter;
      rgba_lighter = tinycolor(rgba)
        .lighten(5)
        .toRgbString();

      _style.background = `linear-gradient(to bottom, ${rgba_lighter} 10%, ${rgba} 50%, ${rgba} 100%)`;
      console.log(_style);
      return _style;
    },
    fontStyleComputed() {
      // This should exist on every overlay, as it enables
      // us to share css with the mlt-based overlays.
      var displayWidth = this.$parent.computed_width;
      var displayHeight = this.$parent.computed_height;
      if (displayHeight > displayWidth) {
        // Vertical (likely mobile).
        console.log("vertical", displayHeight, displayWidth);
      }
      if (displayWidth > 1920) {
        // Practical size maximum, also the max in MLT for now.
        displayWidth = 1920;
      }
      // Removing 'practical' size minimums, as I'm not exactly sure why they are there.
      //if (displayWidth < 480) {
      //  // Practical size minimum, MLT minimum is 720 FYI.
      //  displayWidth = 480;
      //}
      var defaultSize = displayWidth * 0.018 + "px";
      return {
        fontSize: defaultSize
      };
    },
    positionComputed() {
      return {
        left: this.initialX + "%",
        top: this.initialY + "%"
      };
    },
    displayWidth() {
      if (this.$parent.computed_width) {
        console.log(this.$parent.computed_width);
        return this.$parent.computed_width;
      }
      return 100;
    },
    displayHeight() {
      if (this.$parent.computed_height) {
        if (this.$parent.controlsAutoHide) {
          return this.$parent.computed_height;
        } else {
          return this.$parent.computed_height - this.$parent.plyr_height;
        }
      }
      return 100;
    },
    initialWidth() {
      return Math.max((this.displayWidth * this.overlay.width) / 100, 50);
    },
    initialHeight() {
      return Math.max((this.displayHeight * this.overlay.height) / 100, 50);
    },
    fontFamily() {
      return this.overlay.font_family;
    }
  },
  watch: {
    fontFamily(value) {
      WebFont.load({
        google: {
          families: [value]
        }
      });
    }
  },
  mounted() {
    WebFont.load({
      google: {
        families: [this.overlay.font_family]
      }
    });
    this.initialX = this.overlay.x || 20;
    this.initialY = this.overlay.y || 20;
  },
  methods: {
    btnClick($evt) {
      $evt.stopPropagation();
      console.log("Button clicked", this.overlay.action);
      var emitSimpleActions = {
        play: "playAudio",
        pause: "pauseAudio",
        playToggle: "playToggle"
      };
      if (this.overlay.action in emitSimpleActions) {
        this.$emit(emitSimpleActions[this.overlay.action]);
        return;
      }
      // More complex actions go here.
      if (this.overlay.action == "link") {
        this.$emit("pauseForLink");
        window.location.href = this.item.link;
        return;
      }
      if (this.overlay.action == "link-new-window") {
        this.$emit("pauseForLink");
        window.open(this.item.link);
        return;
      }
      if (this.overlay.action == "goto-and-play") {
        this.$emit("gotoAndPlay", this.goto_sec);
        return;
      }
      if (this.overlay.action == "goto-and-pause") {
        this.$emit("gotoAndPause", this.goto_sec);
        return;
      }
      //alert("Action emitter not found! " + this.overlay.action);
    }
  }
};
</script>

<style lang="scss" scoped>
.fa-icon {
  position: relative;
  width: auto;
  height: 0.68em; /* or any other relative font sizes */
  top: 0.03em;
  /* You would have to include the following two lines to make this work in Safari */
  max-width: 100%;
  max-height: 100%;
}
.icon-margin {
  margin-left: 0.4em;
  margin-right: 0.64em;
}
.frame {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  //background-color: rgba(0, 0, 255, 0.1) !important;
  pointer-events: none !important;
}

.draggable-override-border {
  border: 1px solid rgba(0, 0, 0, 0) !important;
}
.draggable-pointer-on {
  pointer-events: auto;
}
.button {
  border: none;
  padding: 0.3em 0.86em 0.42em 0.8em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 0.28em;
  transition-duration: 0.26s;
  pointer-events: auto !important;
  outline: none;
  white-space: nowrap;
  filter: brightness(100%);

  span {
    text-shadow: 0.04em 0.04em rgba(0, 0, 0, 0.4);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
}
.button:hover {
  cursor: pointer;
  filter: brightness(104%);
  //top: -1px;
  box-shadow: 0 0.1em 0.1em 0 rgba(0, 0, 0, 0.2),
    0 0.8em 0.8em 0 rgba(0, 0, 0, 0.1);
}

.title-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: "Lora", cursive;
  color: white;
  width: 100%;
  height: 100%;
  z-index: 20000;
  pointer-events: none;

  //background-color: pink;
  // Make lowerthird text unselectable.
  //-webkit-user-select: none; /* Safari */
  //-moz-user-select: none; /* Firefox */
  //-ms-user-select: none; /* IE10+/Edge */
  //user-select: none; /* Standard */

  [contenteditable] {
    outline: 0px solid transparent;
  }

  h1 {
    margin-top: 0px;
    margin-bottom: 0px;
    height: auto;
    width: auto;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    color: white;
    background-color: pink;
    //overflow: hidden;
    line-height: 120%;
    font-weight: normal;
    -webkit-transform: translateZ(0);
    //white-space: pre-wrap;
  }

  .line-container {
    position: absolute;
    margin: 0;
    //top: 0;
    //left: 0;

    display: inline-block;
    // background-color: rgba(0, 0, 255, 0.7);
    width: inherit;
    height: auto;
    pointer-events: none;
  }
  .drag-handle {
    position: absolute;
    right: 0px;
    bottom: -27px;
    width: 14px;
    height: 14px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 3px;
  }
  .drag-handle-bottom {
    position: absolute;
    right: -20px;
    bottom: 0px;
  }
  .drag-handle-top {
    position: absolute;
    left: -20px;
    top: 0px;
  }
}
</style>
