export default {
  namespaced: true,
  state: {
    slideHeight: 80,
    overlaysRowHeight: 24,
    timelineWidth: 10000, // Max width is 32767
    timelineHeight: 180,
    timelineOverviewHeight: 60,
    timelineTotalHeight: 100,
    timelineVerticalPadding: 34 // Read-only, you have to eyeball this yourself.
  },
  getters: {},
  mutations: {
    setPropValue(state, obj) {
      state[obj.propName] = obj.value;
    }
  },
  actions: {
    setAllTimelineValuesFromAvailableHeight({ state, rootState }, newHeight) {
      // Some kind of auto-layout goes here.
      newHeight = newHeight - state.timelineVerticalPadding;

      var overlayPercent = 0.2;

      // Start with the overlay height, as this is the most variable.
      var numberOverlays = (
        (rootState.show.currentShow.data || {}).overlayArray || []
      ).length;

      if (numberOverlays > 5) {
        overlayPercent = 0.5;
      }

      state.overlaysRowHeight = (newHeight * overlayPercent) / numberOverlays;

      var minOverlaysRowHeight = 18;
      if (state.overlaysRowHeight < minOverlaysRowHeight) {
        state.overlaysRowHeight = minOverlaysRowHeight;
      }

      var maxOverlayRowHeight = 40;
      if (state.overlaysRowHeight > maxOverlayRowHeight) {
        state.overlaysRowHeight = maxOverlayRowHeight;
      }

      var overlayTotalHeight = state.overlaysRowHeight * numberOverlays;
      var remainingHeight = newHeight - overlayTotalHeight;

      // Now do the slide height, as this can look crazy if too large or small.
      var minSlideHeight = 40;
      var maxSlideHeight = 80;

      state.slideHeight = remainingHeight * 0.35;
      if (state.slideHeight < minSlideHeight) {
        state.slideHeight = minSlideHeight;
      }
      if (state.slideHeight > maxSlideHeight) {
        state.slideHeight = maxSlideHeight;
      }

      remainingHeight = remainingHeight - state.slideHeight;
      console.log(remainingHeight);

      // Now deal with the timeline canvases (No minimums, just max values).
      state.timelineHeight = remainingHeight * 0.7;
      state.timelineOverviewHeight = remainingHeight * 0.3;

      var timelineHeightMax = 200;
      if (state.timelineHeight > timelineHeightMax) {
        state.timelineHeight = timelineHeightMax;
        state.timelineOverviewHeight = timelineHeightMax * 0.35;
      }
      console.log("--> ", state.timelineOverviewHeight);
    },
    setPropValue({ commit }, obj) {
      commit("setPropValue", obj);
    }
  }
};
