import Vue from "vue";
import Vuex from "vuex";

import "firebase/firestore";
import "firebase/auth";

import { global_coupon } from "@/defaults";

// import editor from "@/store/modules/editor";
// import showCreation from "@/store/modules/showCreation";
// import shows from "@/store/modules/shows";
// import showsJson from "@/store/modules/showsJson";
import show from "@/store/modules/show";
import offlineList from "@/store/modules/offlineList";
import offlineShow from "@/store/modules/offlineShow";
import slides from "@/store/modules/slides";
import overlays from "@/store/modules/overlays/overlays";
// import userFavorites from "@/store/modules/userFavorites";
// import groups from "@/store/modules/groups";
// import billing from "@/store/modules/billing";
import waveform from "@/store/modules/waveform";
import timelineLayout from "@/store/modules/timelineLayout";
// import showList from "@/store/modules/showList";

Vue.use(Vuex);

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

var acceptedAudioFormats = ".mp3,.m4a,.wav,.aif,.aiff,.ogg,.aac";
var acceptedImageFormats = ".jpg,.jpeg,.png";
var acceptedVideoFormats = ".mp4,.mov";

export default new Vuex.Store({
  modules: {
    //billing: billing,
    //editor: editor,
    //groups: groups,
    overlays: overlays,
    show: show,
    offlineList: offlineList,
    offlineShow: offlineShow,
    //showCreation: showCreation,
    //showList: showList,
    //shows: shows,
    //showsJson: showsJson,
    slides: slides,
    timelineLayout: timelineLayout,
    //userFavorites: userFavorites,
    waveform: waveform
  },
  state: {
    acceptedAudioFormats: acceptedAudioFormats,
    acceptedImageFormats: acceptedImageFormats,
    acceptedVideoFormats: acceptedVideoFormats,
    forageDatabaseId: "001",
    forageDataShowId: "active-show",
    auth_initialized: false,
    authorized: false,
    bowser: null,
    componentInstance: null,
    editor_shows: [],
    editor_shows_loaded: false,
    fluidTimeline: false,
    global_coupon: global_coupon,
    hosted_shows: [],
    initial_signup: false,
    initialSignupEmailPreference: true,
    imageImportLimit: 40, // Updated when plan loads.
    videoImportLimit: 2, // Updated when plan loads.
    videoImportLimitSeconds: 10, // Updated when plan loads.
    videoEndpoint: "https://cdn3.soundslides.com/video",
    imageEndpoint: "https://cdn3.soundslides.com/images",
    imageSizes: [400, 800, 1600], // Being generated by Lambda.
    is_admin: false,
    isApprovedBrowser: true,
    jsonEndpoint: "https://cdn3.soundslides.com/json",
    legacyShowData: [],
    legacyShowDataLoaded: false,
    logging_in: false,
    login_error_message: "",
    login_error: false,
    signup_error_message: "",
    signup_error: false,
    reset_error_message: "",
    reset_error: false,
    reset_sent: false,
    showCreationInProgress: false,
    legacy_shows: [],
    stripe_data: {},
    stripe_data_charges: {},
    stripe_data_draft_invoices: [],
    stripe_data_due_invoices: [],
    stripe_card_expired: false,
    user_doc: {
      beta_tester: true
    },
    user_show_index: {},
    user_show_index_loaded: false,
    user_show_total: 0,
    user_plan: null,
    user: {},
    trialDaysRemaining: null,
    video_output_options: null,
    toastMessage: null,
    publishingInProgress: false,
    displayLegacyVideoNav: false,
    serviceUnavailable: false
  },
  getters: getters,
  mutations: mutations,
  actions: actions
});
