<template lang="html">
  <div class="full">
    <div :style="topStyle" class="top-full exact top-background">
      <div v-if="show" :style="{ width: '50%' }" class="exact top-left">
        <div class="small-controls">
          <input
            v-model="title"
            type="text"
            placeholder="Title"
            class="title-input"
          />
        </div>
        <div class="small-controls transition-controls">
          <GlobalTransitionsDropdown
            :class="{ active: transitionPreset != 'straight-cut' }"
          />
          <button
            :class="{ active: aspectRatio == aspectRatios.fourByThree }"
            @click="
              $store.dispatch(
                'show/updateAspectRatio',
                aspectRatios.fourByThree
              )
            "
          >
            4:3
          </button>
          <button
            :class="{ active: aspectRatio == aspectRatios.threeByTwo }"
            @click="
              $store.dispatch('show/updateAspectRatio', aspectRatios.threeByTwo)
            "
          >
            3:2
          </button>
          <button
            :class="{ active: aspectRatio == aspectRatios.sixteenByNine }"
            @click="
              $store.dispatch(
                'show/updateAspectRatio',
                aspectRatios.sixteenByNine
              )
            "
          >
            16:9
          </button>
        </div>
      </div>
      <div
        :style="{
          left: '50%',
          width: '40%',
          marginLeft: '5%',
          marginTop: '44px'
        }"
        class="exact"
      >
        <AutoTimeSlider v-if="playerMounted" />
      </div>
    </div>
    <div class="middle main-background">
      <div :style="mainLeftStyle" class="exact main-left">
        <div
          v-if="showReady"
          :style="playerAreaStyle"
          class="player-area black"
        >
          <div :style="embedContainerStyle" class="embed-container">
            <SoundslidesPlayer
              :key="'player_' + $store.state.show.remountId"
              ref="editPlayer"
              :editing_initial_slide_data="show.data.slideArray"
              :editing_initial_overlay_data="show.data.overlayArray"
              :editing_initial_settings_data="show.data.displaySettings"
              :editing_audio_source_url="audioUrl"
              :editing_audio_duration="show.data.audio.duration"
              :editable_overlays="$store.state.show.editableOverlays"
              :force_preload="true"
              :fillscreen="false"
              :blob-urls="blobUrls"
              :always_show_controls="timelineFocusLayout"
              :capture-and-emit-click="captureAndEmitClick"
              @onSlideChange="slideChange"
              @onTimeUpdate="timeUpdate"
              @onMounted="onPlayerMounted"
              @clickCaptured="clickCaptured"
              @mouseDownCaptured="mouseDownCaptured"
            />
          </div>
        </div>
      </div>
      <div
        :key="'grid_' + $store.state.show.remountId"
        :style="mainRightStyle"
        class="exact main-right"
      >
        <div
          v-if="playerMounted && grid.length == 0"
          class="need-images-overlay"
        >
          <div class="content">
            <h1>We need some images.</h1>
            <h3>You can add up to 20 jpeg images.</h3>
            <button @click="selectImages">Select .jpgs</button>
          </div>
        </div>
        <draggable
          v-if="imagesBlobbed"
          v-model="grid"
          class="grid"
          :style="gridStyle"
          draggable=".draggable"
          @update="updateImageOrder"
        >
          <div
            v-for="(image, idx) in grid"
            :key="image.filename"
            :style="gridImageStyle"
            class="grid-image draggable actual-image"
          >
            <img :src="returnImageSrc(image)" class="image" />
            <div class="remove-image" @click="removeImage(image)">
              <v-icon name="times-circle" class="icon" />
            </div>
            <div
              v-show="$store.state.show.slideIndex == idx"
              class="active-slide"
            />
          </div>
          <div
            v-for="placeholderItem in gridPlaceholders"
            :key="placeholderItem"
            :style="gridImageStyle"
            class="grid-image placeholder-image"
            :is-disabled="true"
            @click="selectImages"
          >
            <span class="grid-upload-note">
              Add {{ maxImages - grid.length }}
              {{ "image" | pluralize(maxImages - grid.length) }} ...
            </span>
          </div>
        </draggable>
      </div>
    </div>

    <div v-if="debug" class="debug">
      <ObjectPrint :data="show" title="show" />
      <ObjectPrint :data="blobUrls" title="blobUrls" />
      <ObjectPrint :data="showImages" title="showImages" />
      {{ $store.state.show.remountId }}
      {{ showReady }}
    </div>

    <div :style="bottomStyle" class="exact bottom-full bottom-background">
      <div class="button-bar">
        <button type="button" name="button" @click="exportFullSize">
          Export Full-Size
        </button>
        <button type="button" name="button" @click="exportWebSize">
          Export Web-Size
        </button>
        <span v-if="false">
          <button
            v-if="false"
            type="button"
            name="button"
            @click="$store.dispatch('offlineShow/exportResizedToZip', 1600)"
          >
            Export resized (1600px)
          </button>
          <ExportDropdown
            :selected="exportSelected"
            @changed="value => (exportSelected = value)"
          />
        </span>
        <button type="button" name="button" @click="selectAudio">
          Add .mp3
        </button>
        <button type="button" name="button" @click="selectImages">
          Add .jpgs
        </button>
        <button type="button" name="button" @click="clearImages">
          Clear
        </button>
        <button type="button" name="button" @click="clearAndImport">
          Import zip
        </button>
        <input
          v-show="false"
          ref="imageSelect"
          type="file"
          multiple
          :accept="acceptedFormats"
          class="input-file"
          @change="imagesFileInput($event.target.name, $event.target.files)"
        />
        <!--
        <input
          v-show="false"
          ref="zipSelect"
          type="file"
          :accept="'.zip'"
          class="input-file"
          @change="zipFileInput($event.target.name, $event.target.files)"
        />
      -->
        <input
          v-show="false"
          ref="audioSelect"
          type="file"
          :accept="acceptedAudioFormats"
          class="input-file"
          @change="audioFileInput($event.target.name, $event.target.files)"
        />
        <ForageImporter
          v-show="false"
          :id="forageId"
          ref="forageImporter"
          @onLoaded="forageLoad"
          @onNullLoaded="createEmptyShow"
        />
      </div>
      <div class="info">
        <span class="info-span">
          Tungite Labs <span class="muted">/</span> #001
          <span class="muted">/</span> ©
          <abbr
            :title="
              `Commit: ${process_env.VUE_APP_GIT_REV}, last built ${process_env.VUE_APP_BUILD_DATE}.`
            "
          >
            Jan. 2021
          </abbr>
        </span>
        <span class="small-controls">
          <button v-show="theme == 'dark'" @click="theme = 'default'">
            Default
          </button>
          <button v-show="theme == 'default'" @click="theme = 'dark'">
            Dark mode
          </button>
          <!--
          <button @click="theme = 'light'">Light</button>
          <button @click="theme = 'fun'">Fun</button>
          -->
        </span>
      </div>
    </div>

    <!-- Info modal cover -->
    <div
      v-if="$store.state.offlineShow.displayFirstRunInfo"
      class="modal-progress"
      @click="e => e.stopPropagation()"
    >
      <div class="modal-inner">
        <div class="modal-content">
          <FirstRunUserDialog />
        </div>
      </div>
    </div>

    <!-- Export modal cover -->

    <ModalProgress
      v-if="$store.state.offlineShow.exportInProgress"
      :text="$store.state.offlineShow.exportProgressText"
      :percent="$store.state.offlineShow.exportProgress"
    />

    <!-- Import modal cover -->

    <ModalProgress
      v-if="importInProgress"
      :text="importProgressText"
      :percent="importProgress"
    />

    <div v-if="devEnv" class="debug-right">
      <button @click="debug = !debug">Debug</button>
    </div>
  </div>
</template>

<script>
const ASPECTS = {
  sixteenByNine: 1.777777778, // 16:9
  fourByThree: 1.33333, // 4:3
  threeByTwo: 1.5, // 3:2
  square: 1, // 1:1
  nineBySixteen: 0.5625, // 9:16 Vertical video.
  fourByFive: 0.8 // 4:5 Vertical image.
};

import { getBlobDimensions } from "@/models/utils/blobUtils";
import { getBlobDuration } from "@/models/utils/blobUtils";
import { returnRandomKey } from "@/utils/randomKey";
import AutoTimeSlider from "@/components/AutoTimeSlider";
import cloneDeep from "lodash/cloneDeep";
import ComputedPositionsMixin from "@/views/ComputedPositionsMixin";
import draggable from "vuedraggable";
import each from "lodash/each";
import ExportDropdown from "@/components/ExportDropdown";
import FirstRunUserDialog from "@/components/FirstRunUserDialog";
import GlobalTransitionsDropdown from "@/components/GlobalTransitionsDropdown";
import localForage from "localforage";
import ModalProgress from "@/components/ModalProgress";
import range from "lodash/range";
import ResizeWatchMixin from "@/views/ResizeWatchMixin";
import SoundslidesPlayer from "@/components/SoundslidesPlayer/SoundslidesPlayer";
import WaveformData from "waveform-data";
import ForageImporter from "@/components/TMA/ForageImporter";

export default {
  name: "Layout001",
  components: {
    AutoTimeSlider,
    draggable,
    ExportDropdown,
    FirstRunUserDialog,
    ForageImporter,
    GlobalTransitionsDropdown,
    ModalProgress,
    SoundslidesPlayer
  },
  mixins: [ResizeWatchMixin, ComputedPositionsMixin],

  data() {
    return {
      acceptedAudioFormats: ".mp3",
      acceptedFormats: ".jpg,.jpeg,",
      addingNewAudio: false,
      aspectRatios: ASPECTS,
      audioFile: null,
      audios: [],
      audiosBlobbed: false,
      blobUrls: {},
      bottomHeight: 60,
      currentTime: 0,
      debug: false,
      exportSelected: "1600",
      grid: [],
      imageFileList: [],
      imagesBlobbed: false,
      importInProgress: false,
      importProgress: 0,
      importProgressText: "importing ...",
      maxImages: 20,
      metadata: [],
      playerMounted: false,
      progress: 50,
      showTitle: "Untitled show",
      theme: "default",
      timelineFocusLayout: false,
      topHeight: 98,
      ioAppPath: "/io"
    };
  },
  computed: {
    forageId() {
      return this.$store.state.forageDataShowId;
    },
    show() {
      return this.$store.state.show.currentShow;
    },
    title: {
      get() {
        return this.show.title;
      },
      set(value) {
        this.$store.dispatch("show/updateTitle", value);
      }
    },
    showImages() {
      return ((this.show || {}).data || {}).images || [];
    },
    refreshId() {
      return this.$store.state.show.refreshId;
    },
    aspectRatio() {
      var aspectRatio =
        (this.show.data.displaySettings || {}).aspectRatio || 66.63;
      return aspectRatio;
    },
    audioUrl() {
      if (this.show.data.audio.filename in this.blobUrls) {
        console.log("audioUrl", this.blobUrls[this.show.data.audio.filename]);
        return this.blobUrls[this.show.data.audio.filename];
      }
      var source_id = this.show.data.audio.source_id;
      var filename = this.show.data.audio.filename;
      return `https://cdn3.soundslides.com/audio/${source_id}/${filename}`;
    },
    captureAndEmitClick() {
      if (this.$store.state.overlays.overlay_tool_value == "move") {
        return false;
      }
      return true;
    },
    showReady() {
      return (
        this.importInProgress == false &&
        this.imagesBlobbed &&
        this.audiosBlobbed
      );
    },
    embedContainerStyle() {
      var _style = {};
      var padding = 100 / this.aspectRatio;
      _style["padding-bottom"] = padding + "%";
      return _style;
    },
    gridPlaceholders() {
      return range(0, this.maxImages - this.grid.length);
    },
    devEnv() {
      // Debug activated by "?debug"
      return process.env.NODE_ENV === "development";
    },
    audioDurationOverrideEnabled() {
      var settings =
        (this.$store.state.show.currentShow.data || {}).displaySettings || {};
      return settings.audioDurationOverrideEnabled || false;
    },
    transitionPreset() {
      var displaySettings =
        this.$store.state.show.currentShow.data.displaySettings || {};
      return displaySettings.globalTransitionPreset || "cf-medium";
    },
    process_env() {
      return process.env;
    }
  },
  watch: {
    showImages(value) {
      console.log("showImages", value);
      if (value.length > 0) {
        this.grid = value;
      }
    },
    refreshId() {
      this.refreshPlayerData();
    },
    theme(value) {
      document.documentElement.setAttribute("data-theme", value); // sets the data-theme attribute
      this.forage.setItem("theme", value);
    }
  },
  beforeDestroy() {
    // Make sure to revoke to prevent memory leaks.
    this.revokeBlobUrls();
  },
  mounted() {
    console.log("LocalShowMixin");

    this.$store.dispatch("offlineShow/loadFirstRunBypassCheck");

    this.$refs.forageImporter.loadShow();

    // Load in the theme.
    this.forage = localForage.createInstance({
      name: this.$store.state.forageDatabaseId
    });
    this.forage.getItem("theme").then(value => {
      console.log("theme", value);
      this.theme = value || "default";
      document.documentElement.setAttribute("data-theme", this.theme); // sets the data-theme attribute
    });
  },
  methods: {
    createEmptyShow() {
      // forage could not find a show.
      console.log("createEmptyShow");
      this.$store.dispatch("offlineShow/makeInitialEdit").then(() => {
        this.$refs.forageImporter.loadShow();
      });
    },
    forageLoad(obj) {
      console.log("forageLoad", obj);
      var show = obj.show;
      this.blobUrls = obj.blobUrls;
      show.blobbed = true; // This bypasses the "safeToRender" check in the Show Model.
      this.$store.commit("show/setCurrentShow", show, { root: true });
      this.imagesBlobbed = true;
      this.audiosBlobbed = true;
      this.$store.dispatch("offlineShow/cleanUpForageMedia");
    },
    publish() {
      if (this.exportSelected == "full-size") {
        this.exportManifestAndPublish();
      } else {
        this.exportResizedManifestAndPublish();
      }
    },
    exportFullSize() {
      this.$store.dispatch("offlineShow/exportToManifest").then(() => {
        this.gotoExportApp();
      });
    },
    exportWebSize() {
      var maxImagePx = 1600;
      var _v = this;
      this.$store
        .dispatch("offlineShow/resizeImagesToForage", maxImagePx)
        .then(values => {
          console.log("values", values);
          return this.$store.dispatch(
            "offlineShow/exportToManifest",
            maxImagePx
          );
        })
        .then(() => {
          _v.gotoExportApp();
        });
    },
    gotoExportApp() {
      window.location.href = `${this.ioAppPath}/#/app/001`;
    },
    exportResizedManifestAndPublish() {
      var maxImagePx = parseInt(this.exportSelected, 10);
      var _v = this;
      this.$store
        .dispatch("offlineShow/resizeImagesToForage", maxImagePx)
        .then(values => {
          console.log("values", values);
          return this.$store.dispatch(
            "offlineShow/exportToManifest",
            maxImagePx
          );
        })
        .then(() => {
          _v.gotoExportApp();
        });
    },
    exportManifestAndPublish() {
      var _v = this;
      this.$store.dispatch("offlineShow/exportToManifest").then(() => {
        _v.gotoExportApp();
      });
    },
    exportZip() {
      if (this.exportSelected == "full-size") {
        this.exportManifestAndZip();
      } else {
        this.exportResizedManifestAndZip();
      }
    },
    exportManifestAndZip() {
      this.$store
        .dispatch("offlineShow/exportToManifest")
        .then(() => {
          this.gotoExportApp();
          //return this.$store.dispatch("offlineShow/exportToZipFromManifest");
        })
        .then(() => {
          console.log("exported manifest and zipped!");
        });
    },
    exportResizedManifestAndZip() {
      var maxImagePx = parseInt(this.exportSelected, 10);
      this.$store
        .dispatch("offlineShow/resizeImagesToForage", maxImagePx)
        .then(values => {
          console.log("values", values);
          return this.$store.dispatch(
            "offlineShow/exportToManifest",
            maxImagePx
          );
        })
        .then(() => {
          this.gotoExportApp();
          //return this.$store.dispatch("offlineShow/exportToZipFromManifest");
        })
        .then(() => {
          console.log("exported manifest and zipped!");
        });
    },
    removeImage(image) {
      console.log("removeImage", image);
      this.$store.dispatch("show/removeImage", image);
    },
    selectAudio() {
      this.$refs.audioSelect.click();
    },
    selectImages() {
      this.$refs.imageSelect.click();
    },
    selectZip() {
      this.$refs.zipSelect.click();
    },
    imagesFileInput(e, files) {
      this.imageFileList = files;
      console.log(e, "imagesFileInput");
      var availableImportItems = this.maxImages - this.grid.length;
      if (this.imageFileList.length > availableImportItems) {
        var trimConfirm = confirm(
          `You selected more images than space, would you like to continue with the first ${availableImportItems} images?`
        );
        if (!trimConfirm) {
          return;
        }
      }
      this.importInProgress = true;
      this.importProgress = 0;
      this.importProgressText = "Beginning import ...";
      this.importInProgress = true;
      setTimeout(this.importImageFiles, 500);
    },
    audioFileInput(e, files) {
      this.audioFile = files[0];
      console.log(e, "audioFileInput");
      this.importInProgress = true;
      setTimeout(this.importAudioFile, 500);
    },

    refreshPlayerData() {
      console.log("refreshPlayerData");
      if (!this.$refs.editPlayer) {
        return;
      }
      var _slideArray = cloneDeep(
        this.$store.state.show.currentShow.data.slideArray
      );
      var _overlays = cloneDeep(
        this.$store.state.show.currentShow.data.overlayArray
      );
      var _settings = cloneDeep(
        this.$store.state.show.currentShow.data.displaySettings
      );
      if ("editPlayer" in this.$refs) {
        this.$refs.editPlayer.setSlideData(_slideArray, _overlays, _settings);
      }
    },
    clickCaptured() {},
    mouseDownCaptured() {},

    slideChange(value) {
      this.$store.commit("show/setSlideIndex", value);
    },
    timeUpdate(value) {
      this.currentTime = value;
    },
    onPlayerMounted() {
      this.playerMounted = true;
    },
    returnImageSrc(image) {
      //console.log(this.blobUrls[image.filename]);
      return this.blobUrls[image.filename];
    },
    updateImageOrder() {
      console.log(this.grid);
      console.log("updateImageOrder");
      var update_object = {
        sortOrderSelected: "custom",
        images: this.grid
      };
      this.$store.dispatch("show/updateImageOrder", update_object);
    },
    clearImages() {
      var clearConfirm = confirm("Are you sure you want to remove all images?");
      if (clearConfirm) {
        console.log("clearImages");
        var _v = this;
        this.$store.dispatch("show/removeAllImages").then(() => {
          _v.grid = [];
          _v.revokeImageBlobUrls();
        });
      }
    },
    clearAndImport() {
      window.location.href = `${this.ioAppPath}/#/import`;
      //this.$router.push({ name: "import-from-zip" });
    },
    revokeBlobUrls() {
      console.log("revokeBlobUrls");
      //Called from beforeDestroy.
      this.imagesBlobbed = false;
      this.audiosBlobbed = false;
      each(this.blobUrls, i => {
        URL.revokeObjectURL(i);
        console.log("revoked: ", i);
      });
    },
    revokeImageBlobUrls() {
      // Called when clearing images.
      each(this.blobUrls, (i, k) => {
        if (k.endsWith(".jpg")) {
          URL.revokeObjectURL(i);
          console.log("revoked: ", i, k);
        }
      });
    },
    useAudio(audio) {
      console.log(audio);
      var audioObj = {
        deployed: true,
        duration: audio.duration,
        filename: audio.filename,
        name: audio.name,
        source_id: "local",
        waveformData: true
      };
      this.$store.dispatch("show/addNewAudio", audioObj);
    },
    importAudioFile() {
      console.log("importAudioFile");
      this.addingNewAudio = true;
      var f = this.audioFile;
      var cleanFilename = f.name.replace(/[^a-z0-9.]/gi, "_").toLowerCase();
      var time = new Date().getTime();
      var filename = `${time}_${cleanFilename}`;

      var promises = [];

      // Forage the file.
      var foragePromise = this.forage.setItem(filename, f);
      promises.push(foragePromise);

      // Blob the file.
      var blob = URL.createObjectURL(f);
      this.blobUrls[filename] = blob;
      console.log(this.blobUrls[filename]);

      // Generate the waveform.
      this.getWaveform(blob);

      var audio = {
        filename: filename,
        name: f.name,
        lastModified: f.lastModified,
        size: f.size,
        duration: -1
      };

      var promise = getBlobDuration(blob)
        .then(function(duration) {
          audio.duration = duration;
          return;
        })
        .then(() => {
          this.useAudio(audio);
        });

      promises.push(promise);

      Promise.all(promises).then(values => {
        console.log(values);
        this.importInProgress = false;
        this.imagesBlobbed = true;
        this.audiosBlobbed = true;
      });
    },
    dataURLCallback(dataURL) {
      console.log(dataURL);
    },
    importImageFiles() {
      this.importProgressText = "Importing images ...";
      this.importInProgress = true;
      this.importProgress = 0;
      var imagesAdded = 0;
      var imagesProcessed = 0;
      var spaceAvailable = this.maxImages - this.grid.length;
      var promises = [];
      each(this.imageFileList, f => {
        // new filename is EPOCH_{{ clean filename }}.jpg
        var cleanFilename = f.name.replace(/[^a-z0-9.]/gi, "_").toLowerCase();
        var time = new Date().getTime();
        var filename = `${time}_${cleanFilename}`;
        console.log("filename", filename);
        var safeToAdd = imagesAdded < spaceAvailable;
        if (!safeToAdd) return;
        imagesAdded++;

        // Forage the file.
        var foragePromise = this.forage.setItem(filename, f);
        promises.push(foragePromise);

        // Blob the file.
        var blob = URL.createObjectURL(f);
        this.blobUrls[filename] = blob;
        console.log(this.blobUrls[filename]);

        // Get image dimensions and add image to the show.
        // Remember, it's 'file' not 'filename'
        var image = {
          filename: filename,
          id: returnRandomKey(7),
          source: "local",
          name: f.name,
          lastModified: f.lastModified,
          size: f.size
        };

        var promise = getBlobDimensions(blob)
          .then(function(dimensions) {
            image.width = dimensions.width;
            image.height = dimensions.height;
            return;
          })
          .then(() => {
            // Add image here.
            console.log("add image", image);
            imagesProcessed++;
            this.importProgress = (imagesProcessed / imagesAdded) * 100;
            console.log(this.importProgress);
            return this.$store.dispatch("show/addNewImage", image);
          });

        promises.push(promise);
      });

      Promise.all(promises).then(values => {
        console.log(values);
        this.importInProgress = false;
        this.imagesBlobbed = true;
        this.audiosBlobbed = true;
      });
    },
    getWaveform(blob) {
      var audioContext = new AudioContext();
      var _v = this;
      fetch(blob)
        .then(response => response.arrayBuffer())
        .then(buffer => {
          var options = {
            audio_context: audioContext,
            array_buffer: buffer,
            scale: 128
          };

          return new Promise((resolve, reject) => {
            WaveformData.createFromAudio(options, (err, waveform) => {
              if (err) {
                reject(err);
              } else {
                resolve(waveform);
              }
            });
          });
        })
        .then(waveform => {
          _v.$store.commit("waveform/setWaveformData", waveform);
          console.log(`Waveform has ${waveform.channels} channels`);
          console.log(`Waveform has length ${waveform.length} points`);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.top-background {
  background-image: var(--top-background-image);
}
.bottom-background {
  background-image: var(--bottom-background-image);
}
.main-background {
  background-image: var(--main-background-image);
  background-color: var(--main-background-color);
}
.need-images-overlay {
  color: var(--text-color);
  position: absolute;
  width: 100%;
  .content {
    h1 {
      font-size: 1.9em;
      margin-bottom: -8px;
    }
    h3 {
      font-size: 1.2em;
    }
    position: relative;
    top: 80px;
    margin: auto;
    max-width: 340px;
    width: 60%;
    padding: 0.1em 2em 1.7em 2em;
    background-color: var(--image-alert-background-color);
    transform: rotate(1deg);
    box-shadow: var(--image-alert-shadow);
  }
  .content:hover {
  }

  z-index: 1000;

  button {
    border: 2px solid var(--highlight-color);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  button:hover {
    transform: scale(1.2) rotate(-1deg);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 1);
  }
}
.full {
  //font-family: var(--font-family);
  //font-size: var(--font-size);
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
  min-width: 740px;
  min-height: 620px;
}

.transition-controls {
  margin-left: 12px;
  margin-bottom: 8px;
}

.grid {
  display: grid;
  //grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-auto-rows: 1fr;
}

.top-left {
  margin-top: 10px;
}

.grid::before {
  content: "";
  width: 0;
  padding-bottom: 100%;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.grid > *:first-child {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.grid-image {
  position: relative;
  //width: 10rem;
  //height: 10rem;
  background-color: var(--grid-image-background-color);
  z-index: 100;
  cursor: grab;
  .remove-image {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 24px;
    height: 24px;
    background-color: rgba(1, 1, 1, 0.8);
    box-shadow: 1px 2px 2px -2px rgba(0, 0, 0, 0.65);
    .icon {
      position: absolute;
      top: 4px;
      left: 0px;
      width: 24px;
      color: white;
    }
  }
  &:hover .remove-image {
    visibility: visible;
    cursor: pointer;
  }
  .active-slide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    outline: 4px solid var(--grid-active-border-color);
  }
}

.bottom-full {
  .button-bar {
    position: absolute;
    bottom: 12px;
    left: 18px;
  }
  .info {
    color: var(--text-color);
    position: absolute;
    right: 12px;
    top: 20px;
    font-size: 0.7em;
    .info-span {
      margin-right: 12px;
    }
  }
  select {
    padding: 7.3px 8px;
    border: 2px solid var(--ui-border-color);
  }
}

.debug-right {
  position: absolute;
  top: -3px;
  right: -10px;

  button {
    box-sizing: border-box;
    border: 0px solid var(--highlight-color);
    text-align: center;
    font-size: 10px;
    padding: 4px 8px;
    cursor: pointer;
    color: #999;
    background: none;
    //background-color: var(--main-bg-color);
    margin-right: 10px;
  }
}

.white {
  background-image: repeating-linear-gradient(
      45deg,
      rgb(255, 255, 255) 0px,
      rgb(255, 255, 255) 10px,
      transparent 10px,
      transparent 11px
    ),
    repeating-linear-gradient(
      135deg,
      rgb(255, 255, 255) 0px,
      rgb(255, 255, 255) 10px,
      transparent 10px,
      transparent 11px
    ),
    linear-gradient(90deg, hsl(256, 7%, 84%), hsl(256, 7%, 84%));
}

.placeholder-image {
  background-color: var(--placeholder-image-background-color);
  cursor: inherit;
  .grid-upload-note {
    visibility: hidden;
  }
  &:hover .grid-upload-note {
    position: absolute;
    font-size: 0.7em;
    top: 10px;
    left: 5%;
    width: 90%;
    visibility: visible;
  }
}

.middle {
  width: 100%;
  height: 100%;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--text-color);
  opacity: 0.8; /* Firefox */
}

.title-input {
  background: none;
  border-color: var(--ui-border-color);
  border-style: dotted;
  color: var(--text-color);
  font-size: 0.8em;
  height: 17px;
  margin-bottom: 8px;
  margin-left: 12px;
  margin-right: 10px;
  margin-top: 4px;
  padding: 0.2em;
  width: 240px;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.exact {
  position: absolute;
  display: block;
}

.player-area {
  position: relative;
}

.modal-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.7);
  display: block;

  .modal-inner {
    position: relative;
    width: 100%;
    height: 100%;

    .modal-content {
      color: var(--modal-text-color);
      background-color: var(--modal-background-color);
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 20px;
      padding-top: 0px;
      min-width: 200px;
      transform: translate(-50%, -50%);
    }
  }
}

.top-full {
  top: 0;
  left: 0;
  width: 100%;
  //padding-top: 20px;
}

.button-bar {
  position: absolute;
  bottom: 20px;
}

.main-left {
  margin-top: 20px;
}

.main-right {
  margin-top: 20px;
}

.bottom-full {
  bottom: 0;
  left: 0;
  width: 100%;
}

.embed-container {
  position: relative;
  padding: 0;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  z-index: 1;
}

button {
  box-sizing: border-box;
  border: 2px solid var(--ui-border-color);
  text-align: center;
  padding: 8px 18px;
  cursor: pointer;
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--main-bg-color);
  margin-right: 10px;
  transition: transform 0.2s, box-shadow 0.2s;
  &:hover {
    transform: scale(1.1) rotate(-1deg);
    box-shadow: 1px 1px 2px black;
  }
}

select {
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  color: var(--text-color);
  margin-right: 10px;
  padding: 4px 9px;
  padding-left: 5px;
  font-size: 0.8em;
  background: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  border: 1px solid var(--ui-border-color);
}
select:focus {
  outline: 0;
}

@media only screen and (max-width: 880px) {
  button {
    box-sizing: border-box;
    border: 2px solid var(--ui-border-color);
    text-align: center;
    padding: 8px 8px;
    font-size: 11px;
    cursor: pointer;
    font-family: var(--font-family);
    color: var(--text-color);
    background-color: var(--main-bg-color);
    margin-bottom: 0px;
    margin-right: 10px;
  }
  select {
    font-size: 11px;
  }
}

.small-controls {
  button {
    padding: 4px 9px;
    font-size: 0.8em;
    background: none;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    border: 1px solid var(--ui-border-color);
  }
  .active {
    background-color: var(--active-button-color);
    color: var(--active-button-text-color);
  }
}

abbr {
  text-decoration: none;
}

button:focus {
  outline: 0;
}

.top-bottom-background {
  background-image: var(--retro-background);
}

.pink {
  background-image: repeating-linear-gradient(
      249deg,
      transparent 0px,
      transparent 3px,
      rgb(255, 255, 255) 3px,
      rgb(255, 255, 255) 32px
    ),
    repeating-linear-gradient(
      307deg,
      transparent 0px,
      transparent 3px,
      rgb(255, 255, 255) 3px,
      rgb(255, 255, 255) 32px
    ),
    linear-gradient(
      90deg,
      hsl(191, 85%, 78%),
      hsl(242.429, 85%, 78%),
      hsl(293.857, 85%, 78%),
      hsl(345.286, 85%, 78%),
      hsl(36.714, 85%, 78%),
      hsl(88.143, 85%, 78%),
      hsl(139.571, 85%, 78%)
    );
}

.green {
  background-image: linear-gradient(
      0deg,
      rgba(216, 23, 79, 0.5) 0%,
      rgba(216, 23, 79, 0.5) 25%,
      rgba(194, 20, 91, 0.5) 25%,
      rgba(194, 20, 91, 0.5) 50%,
      rgba(171, 17, 104, 0.5) 50%,
      rgba(171, 17, 104, 0.5) 75%,
      rgba(149, 14, 116, 0.5) 75%,
      rgba(149, 14, 116, 0.5) 100%
    ),
    linear-gradient(
      90deg,
      rgb(42, 63, 66) 0%,
      rgb(42, 63, 66) 20%,
      rgb(76, 48, 65) 20%,
      rgb(76, 48, 65) 40%,
      rgb(111, 34, 64) 40%,
      rgb(111, 34, 64) 60%,
      rgb(145, 19, 62) 60%,
      rgb(145, 19, 62) 80%,
      rgb(179, 4, 61) 80%,
      rgb(179, 4, 61) 100%
    );
}

.blue {
  background-image: linear-gradient(
      97deg,
      rgba(46, 46, 46, 0.46) 0%,
      rgba(46, 46, 46, 0.46) 12.5%,
      rgba(74, 74, 74, 0.46) 12.5%,
      rgba(74, 74, 74, 0.46) 25%,
      rgba(103, 103, 103, 0.46) 25%,
      rgba(103, 103, 103, 0.46) 37.5%,
      rgba(131, 131, 131, 0.46) 37.5%,
      rgba(131, 131, 131, 0.46) 50%,
      rgba(159, 159, 159, 0.46) 50%,
      rgba(159, 159, 159, 0.46) 62.5%,
      rgba(187, 187, 187, 0.46) 62.5%,
      rgba(187, 187, 187, 0.46) 75%,
      rgba(216, 216, 216, 0.46) 75%,
      rgba(216, 216, 216, 0.46) 87.5%,
      rgba(244, 244, 244, 0.46) 87.5%,
      rgba(244, 244, 244, 0.46) 100%
    ),
    linear-gradient(
      82deg,
      rgb(244, 244, 244) 0%,
      rgb(244, 244, 244) 12.5%,
      rgb(224, 224, 224) 12.5%,
      rgb(224, 224, 224) 25%,
      rgb(204, 204, 204) 25%,
      rgb(204, 204, 204) 37.5%,
      rgb(184, 184, 184) 37.5%,
      rgb(184, 184, 184) 50%,
      rgb(164, 164, 164) 50%,
      rgb(164, 164, 164) 62.5%,
      rgb(144, 144, 144) 62.5%,
      rgb(144, 144, 144) 75%,
      rgb(124, 124, 124) 75%,
      rgb(124, 124, 124) 87.5%,
      rgb(104, 104, 104) 87.5%,
      rgb(104, 104, 104) 100%
    );
}

.purple {
  background-color: purple;
}

.muted {
  color: var(--highlight-color);
}

.black {
  background-color: black;
}

.debug {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  color: black;
  font-size: 0.86em;
  width: 50%;
  height: 100%;
  overflow-y: scroll;
  background-color: white;
}
</style>
