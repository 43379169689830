import { differenceInDays } from "date-fns";
import { plans } from "@/defaults";
import find from "lodash/find";
import map from "lodash/map";

const returnTrialExpiration = user_doc => {
  if ("trial_expires_at" in user_doc) {
    return new Date(user_doc.trial_expires_at);
  }
  return new Date();
};

export const returnTrialDaysRemaining = user_doc => {
  return differenceInDays(returnTrialExpiration(user_doc), new Date());
};

export const returnPlanFromUserDoc = user_doc => {
  console.log("returnPlanFromUserDoc");
  // This is now being handled by Zappa.
  var trial_plan = find(plans, { plan_id: "trial" });

  if (!user_doc.subscribed) {
    return trial_plan;
  }

  // If the plan is provided by Stripe, it would have a "plan" value.
  var _plan = find(plans, { plan_id: user_doc.subscribed_plan });

  // If no active plan found, default to a trial plan.
  if (!_plan) {
    _plan = find(plans, { plan_id: "trial" });
  }

  // The following can be replaced by the Zappa assigned "user_doc.subscribed_plan"
  // TODO: Clean this up at some point.

  // If trial plan, let's search user doc for groups.
  if (user_doc.subscribed_source == "groups") {
    console.log("We have a group!");
    var group_plans = map(user_doc.groups, "plan_level");
    // Order the plans.
    var sort_plans_best_first = [
      "premium_annual",
      "plus_annual",
      "standard_annual",
      "basic_annual"
    ];
    group_plans.sort((a, b) => {
      return (
        sort_plans_best_first.indexOf(a) - sort_plans_best_first.indexOf(b)
      );
    });
    console.log("group_plans", group_plans);
    var plan_name = group_plans[0];
    _plan = find(plans, { plan_id: plan_name });
    _plan.stripe_plan = false;
    _plan.group_plan = true;
    _plan.group = find(user_doc.groups, { plan_level: plan_name });
  }

  // Add in expiration data for stripe.
  if (user_doc.subscribed_via_stripe) {
    _plan.expires = user_doc.stripe_result.current_period_end * 1000;
  }

  return _plan;
};
