export const returnRandomKey = stringLength => {
  if (!stringLength) {
    stringLength = 12;
  }
  var s = "bcdfghjklmnpqrstvwxyzBCDFGHJKLMNPQRSTVWXYZ0123456789"; // No vowels, so no readable words.
  var lengthOfId = stringLength;
  var key = Array(lengthOfId)
    .join()
    .split(",")
    .map(function() {
      return s.charAt(Math.floor(Math.random() * s.length));
    })
    .join("");
  return key;
};
