<template lang="html">
  <video
    v-if="item.source_url"
    ref="video"
    :muted="muted"
    :playsinline="muted"
    :width="width"
    :height="height"
    class="video-player"
    preload="auto"
    @loadeddata="loadeddata"
  >
    <source :src="source_video_url" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</template>

<script>
import isFinite from "lodash/isFinite";
export default {
  name: "VideoDisplay",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: () => 0
    },
    slide_array: {
      type: Array,
      default: () => []
    },
    audio_duration: {
      type: Number,
      default: () => 0
    },
    muted: {
      type: Boolean,
      default: () => false
    },
    preload: {
      // Not currently used.
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      audioRef: null,
      standardWidth: 1920,
      maxDisplayWidth: 1280,
      loopingEnabled: true,
      use1080: false
    };
  },
  computed: {
    main_volume() {
      if (this.item.main_volume) {
        return this.item.main_volume;
      }
      return 1;
    },
    slide_volume() {
      if (this.item.slide_volume) {
        return this.item.slide_volume;
      }
      return 1;
    },
    source_video_url() {
      if (this.use1080) {
        return `https://soundslides-media-mp4-1080.s3.amazonaws.com/video/${
          this.item.source_id
        }/${this.item.file}`;
      } else {
        return this.item.source_url;
      }
    },
    width() {
      var displayWidth = this.$parent.computed_width;
      var scale = displayWidth / this.maxDisplayWidth;
      scale = 1;
      if (this.config.source_aspect_ratio < 1) {
        // Vertical.
        return this.standardWidth * this.config.source_aspect_ratio * scale;
      } else {
        return this.standardWidth * scale;
      }
    },
    height() {
      var displayWidth = this.$parent.computed_width;
      var scale = displayWidth / this.maxDisplayWidth;
      scale = 1;
      if (this.config.source_aspect_ratio < 1) {
        // Vertical.
        return this.standardWidth * scale;
      } else {
        return (this.standardWidth / this.config.source_aspect_ratio) * scale;
      }
    },
    config() {
      return this.item;
    }
  },
  mounted() {
    this.$nextTick(function() {
      var _v = this;
      this.$frame = this.$refs.frame;
      //console.log("this.$refs", this.$refs);
      this.$refs.video.oncanplaythrough = () => {
        _v.$emit("image-loaded", _v.index);
        //console.log(
        //  "I think I can play thru the entire " +
        //    "video without ever having to stop to buffer."
        //);
      };
    });
  },
  methods: {
    checkVideoVolume() {
      var fade_duration = 2;
      var video = this.$refs.video;
      if (!video) {
        // Video not available yet.
        return;
      }
      var currentTime = video.currentTime;
      if (currentTime < fade_duration) {
        var in_percentage = currentTime / fade_duration;
        //console.log("in_percentage", in_percentage);
        video.volume = in_percentage * this.slide_volume;
        this.audioRef.volume = 1 - in_percentage * (1 - this.main_volume);
        return;
      }
      if (currentTime >= video.duration - fade_duration) {
        var out_percentage = (video.duration - currentTime) / fade_duration;
        video.volume = out_percentage * this.slide_volume;
        this.audioRef.volume = 1 - out_percentage * (1 - this.main_volume);
        //console.log(
        //  "out_percentage",
        //  out_percentage,
        //  video.volume,
        //  this.audioRef.volume
        //);
        return;
      }
      video.volume = this.slide_volume;
      this.audioRef.volume = this.main_volume;
    },
    loadeddata() {
      //console.log("loadeddata");
    },
    syncTime(player_time, audioRef) {
      //console.log("syncTime, player_time: ", player_time);
      if (!isFinite(player_time)) {
        return;
      }
      var video = this.$refs.video;
      if (!this.audioRef) {
        this.audioRef = audioRef;
      }
      // console.log(video.duration);
      // var transition_time_in = this.item.transition_time_in / 2;
      var transition_time_in = this.item.transition_time_in;
      // Note, we're calculating outside of the time the video is displayed.
      var inpoint = this.item.timing / 1000;

      // Get the next slide's timing point, OR the total duration (if last slide.)
      var outpoint;
      if (this.index < this.slide_array.length - 1) {
        outpoint = this.slide_array[this.index + 1].timing / 1000;
      } else {
        outpoint = this.audio_duration;
      }

      if (
        player_time >= inpoint - transition_time_in &&
        player_time < outpoint + this.item.transition_time_out
      ) {
        // Within the play zone.
        //console.log(
        //  inpoint,
        //  player_time,
        //  outpoint + this.item.transition_time_out,
        //  this.item.duration
        //);

        // Here's where we expect the video's currentTime to be.
        var expectedCurrentTime = player_time - (inpoint - transition_time_in);
        // If presentation is stopped, just advance and pause video.
        if (this.audioRef.paused) {
          video.currentTime = expectedCurrentTime;
          video.pause();
          return;
        }

        var loopNumber = Math.floor(expectedCurrentTime / this.item.duration);
        //console.log("loopNumber", loopNumber);

        if (expectedCurrentTime > this.item.duration) {
          expectedCurrentTime =
            expectedCurrentTime - this.item.duration * loopNumber;
        }

        // Is it playing, if so, check to see that it is close to accurate timing.
        //console.log("expectedCurrentTime", expectedCurrentTime);
        if (!video.paused) {
          var differenceInSeconds = video.currentTime - expectedCurrentTime;
          // console.log("timecheck: ", differenceInSeconds);
          var amountOfDriftAllowedSeconds = 0.2;
          if (Math.abs(differenceInSeconds) > amountOfDriftAllowedSeconds) {
            console.warn(
              "drift adjustment needed!",
              Math.abs(differenceInSeconds)
            );
            video.currentTime = expectedCurrentTime;
          }
        } else {
          // Play.
          //console.log("Start playing", expectedCurrentTime);
          video.currentTime = expectedCurrentTime;
          video.volume = 0;
          video.play();
          //TweenLite.to(this.$frame, 2, {
          //  left: "0px",
          //  backgroundColor: "rgba(0, 0, 0, 0.8)"
          //});
          video.ontimeupdate = this.checkVideoVolume;
        }
      } else {
        // Why pause it exactly?!?!?
        // It will not loop on it's own, and the volume is faded out automatically.
        // But it's smoother if you reset it for the next playback,
        // and the updating player may keep it running if user changes timing while playing.
        video.currentTime = 0;
        video.pause();
        //debugger;
        //console.log("OFF: video.currentTime", video.currentTime);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
video {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 100%;
  max-height: 100%;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>
