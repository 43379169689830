<template lang="html">
  <div>
    <p v-show="false">
      ZipToForageImporter takes a zip and imports it into Forage storage and
      emits "onLoaded" with the blobUrls and show object.
    </p>

    <ModalProgress v-if="loading" :text="'Loading zip file ...'" :percent="0" />

    <p v-show="!loading" class="loading-zip-button">
      <button @click="selectZip">Select Zip File</button>
    </p>

    <input
      v-show="false"
      ref="zipSelect"
      type="file"
      :accept="'.zip'"
      class="input-file"
      @change="zipFileInput($event.target.name, $event.target.files)"
    />

    <div v-if="error" class="error">
      <h1>Incompatible Zip</h1>
      <h4>{{ errorMessage }}</h4>

      <div v-if="errorRedirectUrl">
        <p>You can open this zip in that application.</p>
        <a :href="errorRedirectUrl">
          <button>
            Take me to the application that created this file!
          </button>
        </a>
      </div>
    </div>

    <ObjectPrint :data="showModel" title="showModel" />
    <ObjectPrint :data="blobUrls" title="blobUrls" />
  </div>
</template>

<script>
import JSZip from "jszip";
import { TMAZipShow } from "@/models/TMAZipShow";
import { returnShowFromDataJson } from "@/models/TMAZipShow";
import each from "lodash/each";
import find from "lodash/find";
import localForage from "localforage";
import { returnBlobbedFileObject } from "@/utils/blobFiles";
import ModalProgress from "@/components/ModalProgress";
export default {
  name: "ZipToForageImporter",
  components: {
    ModalProgress
  },
  props: {
    id: {
      type: String,
      default: () => "forageId"
    }
  },
  data() {
    return {
      zipFile: null,
      zipShow: null,
      showModel: {},
      blobUrls: {},
      forage: null,
      blobsLoaded: false,
      loading: false,
      error: false,
      errorMessage: "",
      errorRedirectUrl: null
    };
  },
  mounted() {
    this.forage = localForage.createInstance({
      name: this.$store.state.forageDatabaseId
    });
    this.loading = false;
  },
  methods: {
    zipFileInput(e, files) {
      this.zipFile = files[0];
      console.log(e, "zipFileInput");
      this.loading = true;
      setTimeout(this.processZip, 500);
    },
    selectZip() {
      var clearConfirm = confirm(
        "Are you sure you want to replace your current edit with this file?"
      );
      if (clearConfirm) {
        this.zipFile = null;
        var _v = this;
        setTimeout(function() {
          _v.$refs.zipSelect.click();
        }, 200);
      }
    },
    importMediaToForage() {
      // Takes the show and loads the images and audio into the forage and blobUrls.
      console.log("importMediaToForage");
      // Images.
      each(this.showModel.slideArray, s => {
        console.log(s);
        var imageFile = find(this.zipShow.imageSelectList, { name: s.name });
        this.forage.setItem(s.file, imageFile);
      });
      // Audio.
      var audioFile = this.zipShow.audioSelection;
      var audioFilename = this.showModel.data.audio.filename;
      this.forage.setItem(audioFilename, audioFile);
      return this.blobShow();
    },
    blobShow() {
      // Returns a promise.
      return returnBlobbedFileObject(this.showModel, this.forage).then(
        blobUrls => {
          this.blobUrls = blobUrls;
          this.blobsLoaded = true;
          return;
        }
      );
    },
    callback() {
      console.log("callback, onLoaded");
      this.$emit("onLoaded", {
        blobUrls: this.blobUrls,
        show: this.showModel.show
      });
    },
    saveAsEdit() {
      var offline = {
        show: this.showModel.show
      };
      return this.forage.setItem(this.id, offline).then(v => {
        console.log("saved!");
        console.log(v);
        this.callback();
        return v;
      });
    },
    processZip() {
      var _v = this;
      this.blobsLoaded = false;
      var appId = this.$store.state.forageDatabaseId;
      JSZip.loadAsync(this.zipFile)
        .then(zip => {
          var zipShow = new TMAZipShow(zip, appId);
          return zipShow.parseZip();
        })
        .then(zipShow => {
          console.log(zipShow);
          _v.zipShow = zipShow;
          if (zipShow.error) {
            throw new Error("Parsing error");
          }
          return zipShow.createSelectionFiles();
        })
        .then(zipShow => {
          console.log(zipShow);
          var showModel = returnShowFromDataJson(zipShow.parsedJson);
          console.log(showModel);
          _v.showModel = showModel;
          return _v.importMediaToForage();
        })
        .then(() => {
          return _v.saveAsEdit();
        })
        .then(() => {
          _v.callback();
        })
        .catch(e => {
          console.warn("Error!");
          console.warn(e.message);
          console.log(this.zipShow.errorMessage);
          this.loading = false;
          this.error = true;
          this.errorMessage = this.zipShow.errorMessage;
          this.errorRedirectUrl = this.zipShow.errorRedirectUrl;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  box-sizing: border-box;
  border: 2px solid black;
  text-align: center;
  padding: 8px 18px;
  cursor: pointer;
  font-family: var(--font-family);
  color: white;
  background-color: rgb(169, 43, 246);
  margin-right: 10px;
  transition: transform 0.2s, box-shadow 0.2s;
  &:hover {
    transform: scale(1.1) rotate(-1deg);
    box-shadow: 1px 1px 2px black;
  }
}

button:focus {
  outline: 0;
}

.loading-zip-button {
  button {
    font-size: 1em;
  }
}

a {
  color: white;
}
.error {
  color: black;
  padding: 0em 3em;
  background-color: rgba(252, 184, 8, 1);
  border-top: 4px dotted black;
  border-bottom: 4px dotted black;
  div {
    margin-bottom: 1.8em;
  }
}
</style>
