<template lang="html">
  <div ref="frame" class="frame" :style="frameStyle">
    <div class="stack-overlay" :style="fontStyleComputed">
      <div :class="lineContainerClass + '-container'">
        <div :class="lineContainerClass + '-line'">
          <h1 :style="line1Style">{{ config.line1 }}</h1>
          <h1 :style="line2Style">{{ config.line2 }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebFont from "webfontloader";
export default {
  name: "StackedOverlay",
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  computed: {
    config() {
      return this.item;
    },
    frameStyle() {
      return {
        backgroundColor: `rgba(0, 0, 0, ${this.config.background_alpha})`
      };
    },
    lineContainerClass() {
      if (this.config.stackAlign == "top") {
        return "line1";
      }
      if (this.config.stackAlign == "center") {
        return "line2";
      }
      return "line3";
    },
    line1Style() {
      return {
        "font-family": this.config.line1_font_family,
        "font-size": this.config.line1_font_size_em + "em",
        "text-align": this.config.line1_align,
        "line-height": "1.2em",
        "margin-bottom": "0em",
        "text-shadow": `0.02em 0.02em rgba(0, 0, 0, 1),
          0 0 0.25em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 0.5em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 0.75em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 1em rgba(0, 0, 0, ${this.config.text_background_alpha})`
      };
    },
    line2Style() {
      return {
        "font-family": this.config.line2_font_family,
        "font-size": this.config.line2_font_size_em + "em",
        "text-align": this.config.line2_align,
        "line-height": "1.3em",
        "margin-top": this.config.stackSecondLineMarginTopEm + "em",
        "text-shadow": `0.02em 0.02em rgba(0, 0, 0, 1),
          0 0 0.25em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 0.5em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 0.75em rgba(0, 0, 0, ${this.config.text_background_alpha}),
          0 0 1em rgba(0, 0, 0, ${this.config.text_background_alpha})`
      };
    },
    fontStyleComputed() {
      // This should exist on every overlay, as it enables
      // us to share css with the mlt-based overlays.
      var displayWidth = this.$parent.computed_width;
      if (displayWidth > 1920) {
        // Practical size maximum, also the max in MLT for now.
        displayWidth = 1920;
      }
      if (displayWidth < 640) {
        // Practical size minimum, MLT minimum is 720 FYI.
        displayWidth = 640;
      }
      var defaultSize = displayWidth * 0.018 + "px";
      return {
        fontSize: defaultSize
      };
    }
  },
  mounted() {
    WebFont.load({
      google: {
        families: [this.config.line1_font_family, this.config.line2_font_family]
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.frame {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  //background-color: lightblue;
}
.stack-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: "Lora", cursive;
  color: white;
  width: 100%;
  height: 100%;
  z-index: 2;
  //background-color: pink;
  // Make lowerthird text unselectable.
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  h1,
  h2 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    color: white;
    overflow: visible;
    font-weight: normal !important;
  }
  .line1-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .line1-line {
      position: absolute;
      margin: auto;
      white-space: pre-line;
      padding-left: 10%;
      padding-right: 10%;
      width: 80%;
      top: 8%;
      height: auto;
    }
  }

  .line2-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .line2-line {
      position: absolute;
      margin: auto;
      white-space: pre-line;
      padding-left: 10%;
      padding-right: 10%;
      width: 80%;
      top: 50%;
      height: auto;
      transform: translateY(-50%);
    }
  }

  .line3-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .line3-line {
      position: absolute;
      margin: auto;
      white-space: pre-line;
      padding-left: 10%;
      padding-right: 10%;
      width: 80%;
      bottom: 10%;
      height: auto;
    }
  }
}
</style>
