<template>
  <div v-if="debug" class="mt-5">
    <button size="sm" variant="light" @click="display = !display">
      Debug ({{ title }})
    </button>
    <code v-if="display">
      <pre class="small" v-text="object_string" />
    </code>
  </div>
</template>

<script>
/* eslint-disable vue/require-prop-types */
// @ is an alias to /src

export default {
  name: "ObjectPrint",
  props: ["data", "title"],
  data() {
    return {
      display: false
    };
  },
  computed: {
    object_string() {
      return JSON.stringify(this.data, undefined, 2);
    },
    debug() {
      // Debug activated by "?debug"
      return process.env.NODE_ENV === "development";
    }
  }
};
</script>
