<template lang="html">
  <transition name="caption-fade">
    <div v-show="text !== 'undefined' && text !== ''" class="image_captions">
      <p>{{ caption_text }}</p>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ImageCaption",
  props: {
    text: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      caption_text: ""
    };
  },
  watch: {
    text(value) {
      console.log("value", value);
      // If text is blank, leave the current text in place while transition executes.
      if (value != "") {
        this.caption_text = value;
      }
    }
  },
  mounted() {
    this.caption_text = this.text;
  }
};
</script>

<style lang="scss" scoped>
.image_captions {
  position: absolute;
  left: 0;
  font-family: Arial, sans-serif;
  bottom: 0;
  opacity: 1;
  padding-bottom: 50px;
  width: 100%;
  height: auto;
  z-index: 700;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  p {
    position: relative;
    left: 10%;
    width: 80%;
    margin-top: 16px;
    line-height: 1.4em;
    min-height: 1.4em;
  }
}
.caption-fade-enter-active,
.caption-fade-leave-active {
  transition: opacity 0.4s;
}
.caption-fade-enter, .caption-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
