<template lang="html">
  <div>
    <div v-if="progressValue != 0" class="progress-bar">
      <div :style="{ width: progressValue + '%' }" class="progress" />
    </div>
    <div v-else class="loader" />
  </div>
</template>

<script>
export default {
  name: "Progress",
  props: {
    progressValue: {
      type: Number,
      default: () => 0
    }
  }
};
</script>

<style lang="scss" scoped>
.loader {
  position: relative;
  left: 82px;
  border: 6px solid var(--progress-bar-background-color);
  border-top: 6px solid var(--progress-bar-foreground-color);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: var(--progress-bar-background-color);
  .progress {
    height: 100%;
    background-color: var(--progress-bar-foreground-color);
  }
}
</style>
