import Bowser from "bowser";

export const approvedBrowsers = {
  electron: ">=1",
  chrome: ">=71",
  firefox: ">65",
  safari: ">=12",
  "microsoft edge": ">84"
};

export const AppBowserMixin = {
  data() {
    return {
      isBrowserSupported: true,
      isMobile: false
    };
  },
  mounted() {
    var _bowser = Bowser.getParser(window.navigator.userAgent);

    this.isBrowserSupported = _bowser.satisfies(approvedBrowsers);
    //this.isBrowserSupported = false;
    console.log("isBrowserSupported", this.isBrowserSupported);
    // Is mobile browser.
    this.isMobile = _bowser.getPlatformType() == "mobile";
    //this.isMobile = true;

    if ("bypassBrowserCheck" in this.$route.query) {
      this.isBrowserSupported = true;
    }
  }
};
